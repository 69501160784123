import axios from "axios";
import * as API_LINKS from '../../config/links.jsx'
import {toast} from 'react-toastify'
import moment from "moment";

export const FETCH_PREVENTIVE_MAINTENANCE_LIST = 'FETCH_PREVENTIVE_MAINTENANCE_LIST'
export const RECEIVE_PREVENTIVE_MAINTENANCE_LIST = 'RECEIVE_PREVENTIVE_MAINTENANCE_LIST'
export const FAILED_PREVENTIVE_MAINTENANCE_LIST = 'FAILED_PREVENTIVE_MAINTENANCE_LIST'

export const FETCH_PREVENTIVE_MAINTENANCE_TASK_LIST = 'FETCH_PREVENTIVE_MAINTENANCE_TASK_LIST'
export const RECEIVE_PREVENTIVE_MAINTENANCE_TASK_LIST = 'RECEIVE_PREVENTIVE_MAINTENANCE_TASK_LIST'
export const FAILED_PREVENTIVE_MAINTENANCE_TASK_LIST = 'FAILED_PREVENTIVE_MAINTENANCE_TASK_LIST'

export const FETCH_FLEET_MAINTENANCE_REMINDER= 'FETCH_FLEET_MAINTENANCE_REMINDER'
export const RECEIVE_FLEET_MAINTENANCE_REMINDER= 'RECEIVE_FLEET_MAINTENANCE_REMINDER'
export const FAILED_FLEET_MAINTENANCE_REMINDER= 'FAILED_FLEET_MAINTENANCE_REMINDER'

export function fetchPreventiveMaintenance(){
    return (dispatch, getState) => {
        let auth = getState().authStore.authData.result.token;
        dispatch({type: FETCH_PREVENTIVE_MAINTENANCE_LIST});
        Promise.all([
                        axios({
                            method: 'post',
                            url: API_LINKS.PREVENTIVE_MAINTENANCE_LIST,
                            headers: {
                                Authorization: auth
                            }
                        }),
                        axios({
                            method: 'post',
                            url: API_LINKS.PREVENTIVE_MAINTENANCE_FLEET_LIST,
                            headers: {
                                Authorization: auth
                            }
                        }),
        ])
        .then((responses) => {
            dispatch( {
                type: RECEIVE_PREVENTIVE_MAINTENANCE_LIST,
                payload: {
                    preventiveMaintenances: responses[0].data,
                    preventiveFleet: responses[1].data
                }
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_PREVENTIVE_MAINTENANCE_LIST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_PREVENTIVE_MAINTENANCE_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_PREVENTIVE_MAINTENANCE_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_PREVENTIVE_MAINTENANCE_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_PREVENTIVE_MAINTENANCE_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_PREVENTIVE_MAINTENANCE_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}



export function fetchPreventiveMaintenanceTask(id_preventive_maintenance_setting){
    return (dispatch, getState) => {
        let auth = getState().authStore.authData.result.token;
        dispatch({type: FETCH_PREVENTIVE_MAINTENANCE_TASK_LIST});
        Promise.all([
            axios({
                method: 'post',
                url: API_LINKS.PREVENTIVE_MAINTENANCE_TASK_LIST,
                headers: {
                    Authorization: auth,
                },
                data:{
                    id_preventive_maintenance_setting: id_preventive_maintenance_setting
                }
            }),
            axios({
                method: 'post',
                url: API_LINKS.PREVENTIVE_MAINTENANCE_SUB_TASK_LIST,
                headers: {
                    Authorization: auth,
                },
            })
        ])
        .then((responses) => {
            dispatch( {
                type: RECEIVE_PREVENTIVE_MAINTENANCE_TASK_LIST,
                payload: {
                    pmTask : responses[0].data,
                    pmTaskSubCat: responses[1].data
                }
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_PREVENTIVE_MAINTENANCE_TASK_LIST
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_PREVENTIVE_MAINTENANCE_TASK_LIST
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_PREVENTIVE_MAINTENANCE_TASK_LIST
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_PREVENTIVE_MAINTENANCE_TASK_LIST
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_PREVENTIVE_MAINTENANCE_TASK_LIST
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_PREVENTIVE_MAINTENANCE_TASK_LIST
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}

async function forecastedToScheduleMaintenance(reminderMaintenance){

    let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    let auth = loginCreds.token;
    let nextMaintenanceDays = reminderMaintenance.next_maintenance_days
    let scheduled_datetime = moment(reminderMaintenance.first_date).add(nextMaintenanceDays,"days").format("YYYY-MM-DD HH:mm:ss")
    let scheduled_datetime_timestamp = moment(scheduled_datetime).unix()
    let id_fleet = reminderMaintenance.id_fleet
    let maintenance_number = reminderMaintenance.package_code.replace(/[^A-Z0-9]+/ig, "").toUpperCase() +"-"+ scheduled_datetime_timestamp
    let scheduledMaintenanceResponse = await axios({
        method: 'post',
        url: API_LINKS.SCHEDULED_MAINTENANCE_LIST_URL,
        headers: {
            Authorization: auth
        },
    }) 
    if(!scheduledMaintenanceResponse.data.result)
        return
    let allSmFiltered = await scheduledMaintenanceResponse.data.result.filter(sm => 
            sm.id_fleet === id_fleet &&
            sm.maintenance_number === maintenance_number
    )
    if(allSmFiltered.length > 0)
        return

    let packageMaintenanceTaskResponse = await axios({
        method: 'post',
        url: API_LINKS.PACKAGE_MAINTENANCE_TASK_LIST,
        headers: {
            Authorization: auth
        },
        data:{
            id_package_maintenance: reminderMaintenance.id_package_maintenance,
            isWithDetail:"true"
        }
    })
    const maintenance_title = `${reminderMaintenance.reg_number_with_door} - Predictive ${reminderMaintenance.package_code}`
    let smResponse = await axios({
        method: 'post',
        url: API_LINKS.SCHEDULED_MAINTENANCE_ADD_URL,
        headers: {
            Authorization: auth
        },
        data:{
            maintenance_number,
            maintenance_title,
            odometer_est: reminderMaintenance.odometer,
            odometer_realization: null,
            maintenance_desc: `${reminderMaintenance.package_code} Predictive maintenance for ${reminderMaintenance.reg_number_with_door} `,
            maintenance_est_cost: null,
            progress_status: null,
            id_fleet,
            system_calc_est_cost: null,
            progress_status_remark: null,
            scheduled_datetime,
            sm_type: 4
        }
    })    
    if(smResponse.status === 201){
        let taskArr = []
        packageMaintenanceTaskResponse.data.result.forEach(task => {
            taskArr.push( 
                axios({
                    method: 'post',
                    url: API_LINKS.SM_MECHANIC_TASK_ADD_URL,
                    headers: {
                        Authorization: auth
                    },
                    data: {
                        id_scheduled_maintenance: smResponse.data.result.id,
                        task_name: task.name,
                        desc: task.desc ,
                        id_sub_catagory: task.sub_category[0].id_categorical_sub_maintenance,
                        id_packgae_maintenance: reminderMaintenance.id_package_maintenance
                    }
                })
            )
        })
        Promise.all(taskArr)
    }
}

async function reminderToScheduleMaintenance(reminderMaintenance){
    if(!reminderMaintenance.first_date)
        return
    let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    let auth = loginCreds.token;

    let scheduled_datetime = moment(reminderMaintenance.first_date).format("YYYY-MM-DDTHH:mm:ss.000") + "Z"
    let scheduled_datetime_timestamp = moment(scheduled_datetime).unix()
    let id_fleet = reminderMaintenance.id_fleet
    let maintenance_number = reminderMaintenance.package_code.replace(/[^A-Z0-9]+/ig, "").toUpperCase() +"-"+ scheduled_datetime_timestamp
    let scheduledMaintenanceResponse = await axios({
        method: 'post',
        url: API_LINKS.SCHEDULED_MAINTENANCE_LIST_URL,
        headers: {
            Authorization: auth
        },
    }) 
    if(!scheduledMaintenanceResponse.data.result)
        return
    let allSmFiltered = await scheduledMaintenanceResponse.data.result.filter(sm => 
            sm.id_fleet === id_fleet &&
            sm.maintenance_number === maintenance_number
    )
    if(allSmFiltered.length > 0)
        return

    let packageMaintenanceTaskResponse = await axios({
        method: 'post',
        url: API_LINKS.PACKAGE_MAINTENANCE_TASK_LIST,
        headers: {
            Authorization: auth
        },
        data:{
            id_package_maintenance: reminderMaintenance.id_package_maintenance,
            isWithDetail:"true"
        }
    })
    const maintenance_title = `${reminderMaintenance.reg_number_with_door} - Preventive ${reminderMaintenance.package_code}`
    let smResponse = await axios({
        method: 'post',
        url: API_LINKS.SCHEDULED_MAINTENANCE_ADD_URL,
        headers: {
            Authorization: auth
        },
        data:{
            maintenance_number,
            maintenance_title,
            odometer_est: reminderMaintenance.odometer,
            odometer_realization: null,
            maintenance_desc: `${reminderMaintenance.package_code} preventive maintenance for ${reminderMaintenance.reg_number_with_door} `,
            maintenance_est_cost: null,
            progress_status: null,
            id_fleet,
            system_calc_est_cost: null,
            progress_status_remark: null,
            scheduled_datetime,
            sm_type: 2
        }
    })    
    if(smResponse.status === 201){
        let taskArr = []
        packageMaintenanceTaskResponse.data.result.forEach(task => {
            taskArr.push( 
                axios({
                    method: 'post',
                    url: API_LINKS.SM_MECHANIC_TASK_ADD_URL,
                    headers: {
                        Authorization: auth
                    },
                    data: {
                        id_scheduled_maintenance: smResponse.data.result.id,
                        task_name: task.name,
                        desc: task.desc ,
                        id_sub_catagory: task.sub_category[0].id_categorical_sub_maintenance,
                        id_packgae_maintenance: reminderMaintenance.id_package_maintenance
                    }
                })
            )
        })
        Promise.all(taskArr)
    }
}

export function fetchFleetMaintenanceReminderDashboard(){
    return (dispatch, getState) => {
        let auth = getState().authStore.authData.result.token;
        dispatch({type: FETCH_FLEET_MAINTENANCE_REMINDER});
        Promise.all([
            axios({
                method: 'post',
                url: API_LINKS.FLEET_MAINTENANCE_REMINDER_LIST_URL,
                headers: {
                    Authorization: auth
                }
            }),
            axios({
                method: 'post',
                url: API_LINKS.FLEET_MAINTENANCE_REMINDER_URL,
                headers: {
                    Authorization: auth
                },
            }),
        ])
        .then(async (responses) => {
            let payload = {almost:[], expired:[], almostCount:0, expiredCount:0}
            let packageList = responses[0].data.result
            let reminderList = responses[1].data.result
            if(!packageList || !reminderList)
                return payload
            if(!packageList.length > 0 || !reminderList.maintenance_almost_data)
                return payload
            for(let i=0; i < packageList.length; i++){
                let fleetCountAlmost = 0
                let fleetCountExpired = 0
                let fleetDetailOnAlmost = []
                let fleetDetailOnExp = []
                for(let j=0; j < packageList[i].data.length; j++){
                    let fleetOnCheck = packageList[i].data[j]
                    if(fleetOnCheck.id_status === 2)
                        continue
                    let fleetAlmost = reminderList.maintenance_almost_data.filter(r => 
                        (r.id_fleet === fleetOnCheck.id_fleet) &&
                        (r.id_package_maintenance === fleetOnCheck.id_package_maintenance) 
                    )
                    if(fleetAlmost[0]){
                        await reminderToScheduleMaintenance(fleetOnCheck)
                        fleetDetailOnAlmost.push(fleetOnCheck)
                        fleetCountAlmost += 1
                    }
                    let fleetHalf = reminderList.maintenance_half.filter(r => 
                        (r.id_fleet === fleetOnCheck.id_fleet) &&
                        (r.id_package_maintenance === fleetOnCheck.id_package_maintenance) 
                    )
                    if(fleetHalf[0]){
                        fleetOnCheck.next_maintenance_days = fleetHalf[0].next_maintenance_days
                        fleetOnCheck.first_date = fleetHalf[0].first_date
                        await forecastedToScheduleMaintenance(fleetOnCheck)
                    }
                    let fleetExpired = reminderList.maintenance_exp_data.filter(r => 
                        (r.id_fleet === fleetOnCheck.id_fleet) &&
                        (r.id_package_maintenance === fleetOnCheck.id_package_maintenance) 
                    )
                    if(fleetExpired[0]){
                        fleetDetailOnExp.push(fleetOnCheck)
                        fleetCountExpired += 1
                    }
                }
                payload.almost.push({
                    maintenance_package_code: packageList[i].maintenance_package_code,
                    maintenance_package_name: packageList[i].maintenance_package_name,
                    fleet_count: fleetCountAlmost,
                    data: [...fleetDetailOnAlmost]
                })
                payload.almostCount += fleetCountAlmost
                payload.expired.push({
                    maintenance_package_code: packageList[i].maintenance_package_code,
                    maintenance_package_name: packageList[i].maintenance_package_name,
                    fleet_count: fleetCountExpired,
                    data: [...fleetDetailOnExp]
                })
                payload.expiredCount += fleetCountExpired
            }
            return payload
        })
        .then(payload => {
            dispatch( {
                type: RECEIVE_FLEET_MAINTENANCE_REMINDER,
                payload: payload
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_FLEET_MAINTENANCE_REMINDER
                    })
                    return toast.error("Invalid username or password! Please try again!");
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_FLEET_MAINTENANCE_REMINDER
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_FLEET_MAINTENANCE_REMINDER
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_FLEET_MAINTENANCE_REMINDER
                    })
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                dispatch({
                    type: FAILED_FLEET_MAINTENANCE_REMINDER
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_FLEET_MAINTENANCE_REMINDER
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}