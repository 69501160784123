import React, { Component } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  FormGroup,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert,
} from "reactstrap";
import { fetchCompanyList } from "../../../redux/actions/aUser-CompanyList";
import { fetchSettingList } from "../../../redux/actions/aUser-SettingList";
import { connect } from "react-redux";
import { BarLoader } from "react-spinners";
import { fetchSavedPlacesTable } from "../../../redux/actions/transporter/aTransporter-SavedPlacesTable";
import { fetchMainCustomerList } from "../../../redux/actions/transporter/aTransporter-MainCustomerList";
import SavedPlacesDatatable from "./SavedPlaces-Datatable";
import PublicFacilityDatatable from "../PublicFacility/PublicFacilityDatatable";
import * as API_LINKS from "../../../redux/config/links";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import isValidCoordinates from "is-valid-coordinates";
import { compose, lifecycle } from "recompose";
import customMarker from "../../../assets/img/protection.png";
import unapprovedSPMarker from "../../../assets/img/protection-unapproved.png";
import gasStationMarker from "../../../assets/img/gas-station.png";
import airportMarker from "../../../assets/img/airport-pin.png";
import dockMarker from "../../../assets/img/dock-pin.png";
import bankMarker from "../../../assets/img/bank.png";
import buildingPin from "../../../assets/img/building-pin.png";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  KmlLayer,
} from "react-google-maps";
import { SearchBox } from "react-google-maps/lib/components/places/SearchBox";
import SummaryWidgets from "./Widgets/SummaryWidgets";
import { SelectField } from "../../LandLord/Widgets/SelectField";
import { Formik } from "formik";
import "react-select/dist/react-select.css";
import { AbilityContext } from "../../../casl/Can";
import SavedPlaceImportCSVButton from "./SavedPlaceImportCSVButton";
import AddEditPublicFacilityForm from "../PublicFacility/Forms/AddEditPublicFacilityForm";
import SavedPlacesAddForm from "./SavedPlacesAddForm";
import ErrorBoundary from "src/components/ErrorBoundary/ErrorBoundary";
// import GeofencingAddform from "./Geofencing/GeofencingAddEditForm";

function generateRandom() {
  return Math.random() * 10000000000000000;
}

const _PlaceCriteria = [
  // {
  //     'label': 'All Places',
  //     'value': 0
  // },
  {
    label: "Main Customers",
    value: 1,
  },
  {
    label: "Public Facilities (Global/Verified)",
    value: 3,
  },
  // {
  //     'label': 'Public Facilities (Not Verified)',
  //     'value': 2
  // },
  // {
  //      'label': 'Approved Places',
  //      'value': 1
  // },
  // {
  //     'label': "Not Approved Places",
  //     'value': 2
  // },
  // {
  //     'label': 'Deactivated Places',
  //     'value': 3
  // },
];

function markerFactory(marker) {
  if (marker.status) {
    if (marker.public_place_type === null && marker.status === 1)
      return customMarker;
    if (marker.public_place_type === null && marker.status === 3)
      return unapprovedSPMarker;
  }
  if (marker.public_place_type === "SPBU") return gasStationMarker;
  if (marker.public_place_type === "Bandara") return airportMarker;
  if (marker.public_place_type === "Pelabuhan") return dockMarker;
  if (marker.public_place_type === "Bank dan ATM") return bankMarker;
  if (marker.public_place_type === "Building Material Store")
    return buildingPin;
}

const MapWithAMarker = compose(
  lifecycle({
    UNSAFE_componentWillMount() {
      const refs = {};
      this.setState({
        onSearchBoxMounted: (ref) => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          const nextMarkerPlaceInfo = [];
          const places = refs.searchBox.getPlaces();
          const bounds = new window.google.maps.LatLngBounds();
          places.forEach((place) => {
            nextMarkerPlaceInfo.push(place);
            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          const nextMarkers = places.map((place) => ({
            position: place.geometry.location,
          }));
          this.setState({
            markerSearch: nextMarkers,
            markerPlaceInfo: nextMarkerPlaceInfo,
          });
        },
        onSearchMarkerClicked: (marker) => {
          this.setState({
            selectedMarkerSearch: marker,
          });
        },
        onCustSelectOpen: (isOpen) => {
          this.setState({
            custSelectOpen: isOpen,
          });
        },
      });
    },
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  // console.log(props.markers)
  return (
    <GoogleMap
      defaultZoom={7}
      defaultCenter={{ lat: -7.6221790314, lng: 110.8043441772 }}
    >
      {props.dataType === 1 && (
        <ErrorBoundary>
        <SearchBox
          ref={props.onSearchBoxMounted}
          controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
          onPlacesChanged={props.onPlacesChanged}
        >
          <input
            type="text"
            placeholder="Search and Add a Place..."
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `350px`,
              height: `40px`,
              marginTop: `10px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
            }}
          />
        </SearchBox>
        </ErrorBoundary>
      )}
      <KmlLayer
        url={props.kmlLink + "&ver=" + generateRandom()}
        options={{ preserveViewport: true }}
      />
      {props.markerSearch &&
        props.markerSearch.map((marker, index) => (
          <Marker
            key={index}
            position={marker.position}
            onClick={props.onSearchMarkerClicked.bind(marker)}
          >
            <InfoWindow>
              <div style={{ minWidth: "350px" }}>
                <div>
                  <b>{props.markerPlaceInfo[index].name}</b>
                </div>
                <div style={{ paddingTop: "5px" }}>
                  {marker.position.lat()}, {marker.position.lng()}
                </div>
                <div style={{ maxWidth: "250px", paddingTop: "5px" }}>
                  {props.markerPlaceInfo[index].formatted_address}
                </div>
                <div style={{ paddingTop: "10px", clear: "both" }}>
                  <Formik
                    onSubmit={(values) => {
                      const loginCreds = JSON.parse(
                        window.atob(localStorage.Automalt)
                      );
                      const auth = loginCreds.token;
                      axios({
                        method: "post",
                        url: API_LINKS.SAVED_PLACES_ADD_URL,
                        headers: {
                          Authorization: auth,
                        },
                        data: {
                          name: props.markerPlaceInfo[index].name,
                          lat: marker.position.lat(),
                          lng: marker.position.lng(),
                          id_customer: values.select_customer.value,
                          address:
                            props.markerPlaceInfo[index].formatted_address,
                        },
                      }).then((response) => {
                        if (response.status === 200) {
                          if (response.data.status === "success") {
                            toast.success("Data saved!");
                            props.refetch();
                          }
                        }
                      });
                    }}
                    render={({
                      values,
                      errors,
                      touched,
                      setFieldValue,
                      handleSubmit,
                      handleBlur,
                      isSubmitting,
                    }) => (
                      <form>
                        <FormGroup row>
                          <Col xs="12" md="8">
                            <SelectField
                              id="select_customer"
                              name="select_customer"
                              label="select_customer"
                              placeholder="Select customer"
                              value={values.select_customer}
                              options={props.mainCustomerSelect}
                              isMulti={false}
                              onChange={(fieldName, selected) => {
                                setFieldValue(fieldName, selected);
                              }}
                              onOpen={() => {
                                props.onCustSelectOpen(true);
                              }}
                              onClose={() => {
                                props.onCustSelectOpen(false);
                              }}
                              touched={touched.select_customer}
                              error={errors.select_customer}
                              onBlur={handleBlur}
                              isClearable={true}
                              backspaceRemovesValue={true}
                              disabled={isSubmitting}
                            />
                          </Col>
                          <Col xs="12" md="4">
                            <Button
                              style={{ marginLeft: "-20px", minHeight: "36px" }}
                              onClick={handleSubmit}
                              size="md"
                              color="primary"
                              disabled={!values.select_customer}
                            >
                              <i className="fa fa-dot-circle-o"></i> Add
                            </Button>
                          </Col>
                        </FormGroup>
                      </form>
                    )}
                  />
                  {props.custSelectOpen && (
                    <div style={{ height: "550px", paddingTop: "5px" }}></div>
                  )}
                </div>
              </div>
            </InfoWindow>

            {props.selectedMarkerSearch &&
              props.selectedMarkerSearch.latLng.lat() ===
                marker.position.lat() &&
              props.selectedMarkerSearch.latLng.lng() ===
                marker.position.lng() && (
                <InfoWindow>
                  <div style={{ minWidth: "350px" }}>
                    <div>
                      <b>{props.markerPlaceInfo[index].name}</b>
                    </div>
                    <div style={{ paddingTop: "5px" }}>
                      {marker.position.lat()}, {marker.position.lng()}
                    </div>
                    <div style={{ maxWidth: "250px", paddingTop: "5px" }}>
                      {props.markerPlaceInfo[index].formatted_address}
                    </div>
                    <div style={{ paddingTop: "10px" }}>
                      <Formik
                        onSubmit={(values) => {
                          const loginCreds = JSON.parse(
                            window.atob(localStorage.Automalt)
                          );
                          const auth = loginCreds.token;
                          axios({
                            method: "post",
                            url: API_LINKS.SAVED_PLACES_ADD_URL,
                            headers: {
                              Authorization: auth,
                            },
                            data: {
                              name: props.markerPlaceInfo[index].name,
                              lat: marker.position.lat(),
                              lng: marker.position.lng(),
                              id_customer: values.select_customer.value,
                              address:
                                props.markerPlaceInfo[index].formatted_address,
                            },
                          }).then((response) => {
                            if (response.status === 200) {
                              if (response.data.status === "success") {
                                toast.success("Data saved!");
                                props.refetch();
                              }
                            }
                          });
                        }}
                        render={({
                          values,
                          errors,
                          touched,
                          setFieldValue,
                          handleSubmit,
                          handleBlur,
                          isSubmitting,
                        }) => (
                          <form>
                            <FormGroup row>
                              <Col xs="12" md="8">
                                <SelectField
                                  id="select_customer"
                                  name="select_customer"
                                  label="select_customer"
                                  placeholder="Select customer"
                                  value={values.select_customer}
                                  options={props.mainCustomerSelect}
                                  isMulti={false}
                                  onChange={(fieldName, selected) => {
                                    setFieldValue(fieldName, selected);
                                  }}
                                  onOpen={() => {
                                    props.onCustSelectOpen(true);
                                  }}
                                  onClose={() => {
                                    props.onCustSelectOpen(false);
                                  }}
                                  touched={touched.select_customer}
                                  error={errors.select_customer}
                                  onBlur={handleBlur}
                                  isClearable={true}
                                  backspaceRemovesValue={true}
                                  disabled={isSubmitting}
                                />
                              </Col>
                              <Col xs="12" md="4">
                                <Button
                                  style={{
                                    marginLeft: "-20px",
                                    minHeight: "36px",
                                  }}
                                  onClick={handleSubmit}
                                  size="md"
                                  color="primary"
                                  disabled={!values.select_customer}
                                >
                                  <i className="fa fa-dot-circle-o"></i> Add
                                </Button>
                              </Col>
                            </FormGroup>
                          </form>
                        )}
                      />
                    </div>
                    {props.custSelectOpen && (
                      <div style={{ height: "550px", paddingTop: "5px" }}></div>
                    )}
                  </div>
                </InfoWindow>
              )}
          </Marker>
        ))}
      {props.markers.map((marker) => {
        const onClick = props.onClick.bind(this, marker);
        return (
          <Marker
            key={marker.id}
            onClick={onClick}
            options={{ icon: markerFactory(marker) }}
            position={{ lat: marker.lat, lng: marker.lng }}
          >
            {props.selectedMarker === marker && (
              <InfoWindow>
                <div>
                  {props.dataType === 1 && (
                    <div>
                      <div>
                        <b>{marker.name}</b>
                      </div>
                      <div style={{ paddingTop: "5px" }}>
                        {marker.customer_name}
                      </div>
                      <div style={{ maxWidth: "250px", paddingTop: "5px" }}>
                        {marker.public_place_address}
                      </div>
                    </div>
                  )}
                  {(props.dataType === 2 || props.dataType === 3) && (
                    <div>
                      <div>
                        <b>{marker.name}</b>
                      </div>
                      <div style={{ paddingTop: "5px" }}>
                        {marker.public_place_type}
                      </div>
                      <div style={{ maxWidth: "250px", paddingTop: "5px" }}>
                        {marker.public_place_address}
                      </div>
                      <div>
                        {marker.review_points !== null &&
                        marker.review_points > 0
                          ? `${marker.review_points} ★`
                          : ""}
                      </div>
                    </div>
                  )}
                </div>
              </InfoWindow>
            )}
          </Marker>
        );
      })}
    </GoogleMap>
  );
});

class SavedPlaces extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdding: false,
      modalAdd: false,
      modalAddGeofencing: false,
      modalContPF: false,
      selectedMarker: false,
      markers: [],
      selectedCrValue: 1,
      selectedCrName: "Main Customers",
      dropdownOpen: false,
      lat: 0,
      lng: 0,
      public_place: 2,
      oneTimeFetch: false,
      isMarked: false,
    };
  }

  UNSAFE_componentWillMount() {
    // this.getLiveMonitoringData()
    this.refetch();
    this.props.fetchMainCustomerList();
    // this.setState({
    //     markers: this.props.savedPlacesTableData
    // })
  }

  componentDidUpdate() {
    // const { selectedCrValue, selectedCrName } = this.state;
    // if (prevProps.savedPlacesTableData !== this.props.savedPlacesTableData) {
    //   this.setLocation(selectedCrValue, selectedCrName);
    // }
    if (this.state.oneTimeFetch && this.state.isMarked) {
      this.props.fetchSavedPlacesTable(this.state.public_place);
      this.setState({ oneTimeFetch: false, isMarked: false });
    }
    if (!this.props.inProgress && !this.state.isMarked) {
      this.setState({
        fullData: this.props.savedPlacesTableData,
        markers: this.props.savedPlacesTableData,
        isMarked: true,
      });
    }
  }

  refetch() {
    this.setState({ oneTimeFetch: true, isMarked: true });
  }

  handleClick = (marker, event) => {
    // console.log({ marker })
    this.setState({ selectedMarker: marker });
  };

  toggleOpenAdd = () => {
    this.setState({
      modalAdd: !this.state.modalAdd,
    });
  };

  toggleCloseAdd = () => {
    this.setState({
      modalAdd: !this.state.modalAdd,
    });
  };

  toggleOpenContributePF = () => {
    this.setState({
      modalContPF: !this.state.modalContPF,
    });
  };

  toggleCloseContributePF = () => {
    this.setState({
      modalContPF: !this.state.modalContPF,
    });
  };

  handleChangeCustomer = (selectedCustomer) => {
    this.setState({ selectedCustomer });
    // console.log(`Option selected:`, selectedType);
  };

  handleAdd = () => {
    this.setState({ isAdding: true });
    var self = this;
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    //Variables for text input
    var _placesName = document.querySelector("#addPlaceName").value;
    var _lat = document.querySelector("#addLatitude").value;
    var _lng = document.querySelector("#addLongitude").value;
    var _idCustomer;

    // eslint-disable-next-line
    if (
      this.state.selectedCustomer == null ||
      this.state.selectedCustomer === undefined ||
      this.state.selectedCustomer === ""
    ) {
      this.setState({ isAdding: false });
      return toast.error("Main Customer should be selected!");
    } else {
      _idCustomer = this.state.selectedCustomer.value;
    }

    if (_placesName === "" || _placesName === null) {
      self.setState({ isAdding: false });
      toast.error("Place name is invalid!");
    } else if (_lat === "" || _lat === null) {
      self.setState({ isAdding: false });
      toast.error("Invalid latitude coordinate!");
    } else if (_lng === "" || _lng === null) {
      self.setState({ isAdding: false });
      toast.error("Invalid longitude coordinate!");
    } else if (isValidCoordinates(_lng, _lat)) {
      self.setState({ isAdding: false });
      toast.error("Invalid map coordinate!");
    } else {
      axios({
        method: "post",
        url: API_LINKS.SAVED_PLACES_ADD_URL, //Please change when it's ready
        headers: {
          Authorization: auth,
        },
        data: {
          name: _placesName,
          lat: _lat,
          lng: _lng,
          id_customer: _idCustomer,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            self.setState({
              isAdding: false,
            });
            if (response.data.status === "success") {
              toast.success("Data saved!");
              self.toggleCloseAdd();
              self.refetch();
            }
          }
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 401) {
              self.setState({
                isAdding: false,
              });
              return toast.error("Invalid credentials! Please try again!");
            } else if (
              error.response.status === 404 ||
              error.response.status === 500
            ) {
              self.setState({
                isAdding: false,
              });
              return toast.error(
                "Server cannot be contacted! Please ask your system administrator!"
              );
            } else if (error.response.status === 400) {
              self.setState({
                isAdding: false,
              });
              return toast.error(error.response.data.message);
            } else {
              self.setState({
                isAdding: false,
              });
              return toast.error(
                "Something went wrong... Please try again later..."
              );
            }
          } else if (error.request) {
            self.setState({
              isAdding: false,
            });
            return toast.error("Request error! Please try again later...");
          } else {
            self.setState({
              isAdding: false,
            });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        });
    }
  };

  checkRoles = () => {
    const { selectedCrValue } = this.state;
    let addButton = null;
    if (selectedCrValue === 1)
      addButton = (
        <>
          <Button
            onClick={this.toggleOpenAdd}
            size="sm"
            color="success"
            disabled={this.props.inProgress}
          >
            <i className="fa fa-plus"></i> Add Saved Places
          </Button>
          &nbsp; &nbsp;{" "}
          <SavedPlaceImportCSVButton refreshData={() => this.refetch()} />
        </>
      );
    if (selectedCrValue === 2 || selectedCrValue === 3)
      addButton = (
        <Button
          onClick={this.toggleOpenContributePF}
          size="sm"
          color="success"
          disabled={this.props.inProgress}
        >
          <i className="fa fa-plus"></i> Contribute Public Facaility
        </Button>
      );
    return (
      <div>
        {this.context.can("view", "add_button@saved_places") && addButton}
      </div>
    );
  };

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  setLocation = (_criteria, _crName) => {
    if (_criteria === 0) {
      this.setState({
        selectedCrName: _crName,
        selectedCrValue: _criteria,
        markers: this.props.savedPlacesTableData,
        fullData: this.props.savedPlacesTableData,
      });
    }
    if (_criteria === 1) {
      //   this.props.fetchSavedPlacesTable(2);
      let tempSavedPlacesData = [...this.props.savedPlacesTableData];
      let mainCustomers = tempSavedPlacesData.filter(
        (d) => d.public_place === null
      );
      this.setState({
        selectedCrName: _crName,
        selectedCrValue: _criteria,
        markers: mainCustomers,
        fullData: mainCustomers,
        public_place: 2,
        oneTimeFetch: true,
        isMarked: true,
      });
    }
    if (_criteria === 2) {
      let tempSavedPlacesData = [...this.props.savedPlacesTableData];
      let publicFacilities = tempSavedPlacesData.filter(
        (d) => (d.public_place !== null && d.status === 3) || d.status === 2
      );
      this.setState({
        selectedCrName: _crName,
        selectedCrValue: _criteria,
        markers: publicFacilities,
        fullData: publicFacilities,
      });
    }
    if (_criteria === 3) {
      //   this.props.fetchSavedPlacesTable(1);
      let tempSavedPlacesData = [...this.props.savedPlacesTableData];
      let publicFacilities = tempSavedPlacesData.filter(
        (d) => d.public_place !== null && d.status === 1
      );
      this.setState({
        selectedCrName: _crName,
        selectedCrValue: _criteria,
        markers: publicFacilities,
        fullData: publicFacilities,
        public_place: 1,
        oneTimeFetch: true,
        isMarked: true,
      });
    }
    //    if(_criteria === 1) {
    //         this.setState({
    //             selectedCrName: _crName,
    //             selectedCrValue: _criteria,
    //             markers: this.props.savedPlaceApproved
    //         })
    //     }
    //     if(_criteria === 2) {
    //         this.setState({
    //             selectedCrName: _crName,
    //             selectedCrValue: _criteria,
    //             markers: this.props.savedPlaceDeleted
    //         })
    //     }
    //     if(_criteria === 3) {
    //         this.setState({
    //             selectedCrName: _crName,
    //             selectedCrValue: _criteria,
    //             markers: this.props.savedPlaceNotApproved
    //         })
    //     }
  };

  render() {
    const { selectedCrValue, selectedCrName, dropdownOpen, markers, fullData } =
      this.state;
    return (
      <div className="animated fadeIn">
        <Row>
          <Col md={12}>
            <Alert color="warning">
              <strong>New Update!</strong>
              <hr />
              You can import your current saved place and main customer on our
              platform by uploading your CSV. This feature will available in the
              specific time only! One thing that you must know is please verify
              your data first! <br />
              Import CSV feature only available on Mon-Fri (7-9PM) and 24 hours
              on weekend
            </Alert>
          </Col>
        </Row>
        <this.Summaries dataType={selectedCrValue} data={markers} />
        <Card>
          <CardHeader>
            {/* <Col md="6">
                            Customers Maps
                        </Col> */}

            {this.props.inProgress ? (
              <center>
                <BarLoader color={"#123abc"} loading={this.state.isLoading} />{" "}
                <br /> Loading.... Please wait...
              </center>
            ) : this.props.successSavedPlaceFetch ? (
              <Col md="12" className="pull-right">
                <ButtonDropdown
                  className="float-right"
                  isOpen={dropdownOpen}
                  toggle={() => {
                    this.toggle();
                  }}
                >
                  <DropdownToggle caret className="btn btn-light text-muted">
                    <i className="icon fa fa-cube"></i>&nbsp;
                    <strong>
                      {selectedCrValue === 0 ? "All Place" : selectedCrName}
                    </strong>
                    &nbsp;
                  </DropdownToggle>

                  <DropdownMenu right="true">
                    {_PlaceCriteria.map((_place) => (
                      <DropdownItem
                        onClick={() =>
                          this.setLocation(_place.value, _place.label)
                        }
                      >
                        {_place.label}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </ButtonDropdown>
              </Col>
            ) : (
              <center>
                Error fetching your data... <br />
                <br />
                <Button
                  className="btn btn-primary"
                  color="primary"
                  size="md"
                  disabled={this.props.inProgress}
                  onClick={() => this.refetch()}
                >
                  <i className="fa fa-refresh"></i>
                  Try again
                </Button>
              </center>
            )}
          </CardHeader>
          <CardBody>
            <div>
              {this.props.inProgress ? (
                <center>
                  <BarLoader color={"#123abc"} loading={this.state.isLoading} />{" "}
                  <br /> Loading.... Please wait...
                </center>
              ) : this.props.successSavedPlaceFetch ? (
                <div>
                  <MapWithAMarker
                    dataType={selectedCrValue}
                    selectedMarker={this.state.selectedMarker}
                    markers={this.state.markers}
                    onClick={this.handleClick}
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCmIATqobqbCEU90nYtwd-PzCcj8EVOlDg&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    kmlLink={!!this.props.statKML ? this.props.statKML : ""}
                    mainCustomerSelect={this.props.mainCustomerSelect}
                    refetch={this.refetch.bind(this)}
                  />
                </div>
              ) : (
                <center>
                  Error fetching your data... <br />
                  <br />
                  <Button
                    className="btn btn-primary"
                    color="primary"
                    size="md"
                    disabled={this.props.inProgress}
                    onClick={() => this.refetch()}
                  >
                    <i className="fa fa-refresh"></i>
                    Try again
                  </Button>
                </center>
              )}
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardHeader></CardHeader>
          <CardBody>
            {this.props.successSavedPlaceFetch ? (
              <div>
                <Row className="pull-right">{this.checkRoles()}</Row> <br />
                <br />
                <br />
                <br />{" "}
              </div>
            ) : (
              true
            )}
            <this.SavedPlacesDatatableFactory
              dataType={selectedCrValue}
              data={markers}
              fullData={fullData}
            />
          </CardBody>

          {/* Modal for Adding Place */}
          <Modal
            isOpen={this.state.modalAdd}
            toggle={this.toggleOpenAdd}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleOpenAdd}>Add Customers</ModalHeader>
            <ModalBody>
              <SavedPlacesAddForm
                toggleCloseAdd={this.toggleCloseAdd.bind(this)}
                refetch={this.refetch.bind(this)}
              />
            </ModalBody>
          </Modal>
          <Modal
            isOpen={this.state.modalContPF}
            toggle={this.toggleOpenContributePF}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleOpenContributePF}>
              Contribute Public Facility
            </ModalHeader>
            <ModalBody>
              <AddEditPublicFacilityForm
                isAdd={true}
                initialValues={{
                  name: "",
                  address: "",
                  owner: null,
                  id_type: null,
                  lat: "",
                  lng: "",
                  desc: "",
                }}
                addFormClose={this.toggleCloseContributePF}
                refreshTable={this.refreshTableData}
              />
            </ModalBody>
          </Modal>
        </Card>
      </div>
    );
  }

  refreshTableData = () => {
    //do nothing
  };
  refreshTableSavedPlace = () => {
    this.refetch();
  };

  Summaries = (props) => {
    let totalWording = "";
    let approvedWording = "";
    let notApprovedWording = "";
    let deactivatedWording = "";

    let total = props.data.length;
    let approvedTotal = 0;
    let notApprovedTotal = 0;
    let deactivatedTotal = 0;

    if (props.dataType === 1) {
      totalWording = "Total Customer(s)";
      approvedWording = "Approved Customer(s)";
      notApprovedWording = "Not Approved Customer(s)";
      deactivatedWording = "Deactivated Customer(s)";
      approvedTotal = props.data.filter((d) => d.status === 1).length;
      notApprovedTotal = props.data.filter((d) => d.status === 3).length;
      deactivatedTotal = props.data.filter((d) => d.status === 2).length;
    }

    if (props.dataType === 2 || props.dataType === 3) {
      totalWording = "Total Public Facilities";
      approvedWording = "Approved Public Facilities";
      notApprovedWording = "Not Approved Public Facilities";
      approvedTotal = props.data.filter((d) => d.status === 1).length;
      notApprovedTotal = props.data.filter((d) => d.status !== 3).length;
    }

    return (
      <Row>
        <Col md={3} xs={12}>
          <SummaryWidgets
            header={total}
            mainText={totalWording}
            icon={"fa fa-asterisk"}
            color={"primary"}
          />
        </Col>
        {props.dataType === 1 ? (
          <Col md={3} xs={12}>
            <SummaryWidgets
              header={approvedTotal}
              mainText={approvedWording}
              icon={"fa fa-check"}
              color={"success"}
            />
          </Col>
        ) : null}
        {props.dataType === 1 ? (
          <Col md={3} xs={12}>
            <SummaryWidgets
              header={notApprovedTotal}
              mainText={notApprovedWording}
              icon={"fa fa-ban"}
              color={"warning"}
            />
          </Col>
        ) : null}
        {props.dataType === 1 ? (
          <Col md={3} xs={12}>
            <SummaryWidgets
              header={deactivatedTotal}
              mainText={deactivatedWording}
              icon={"fa fa-trash"}
              color={"danger"}
            />
          </Col>
        ) : null}
      </Row>
    );
  };

  SavedPlacesDatatableFactory = (props) => {
    if (props.dataType === 1)
      return (
        <SavedPlacesDatatable
          data={props.data}
          onResultFilterChange={this.onResultFilterChange}
          refreshTable={this.refreshTableSavedPlace}
          onCustomerNameClicked={this.handleClick}
          fullData={props.fullData}
        />
      );
    if (props.dataType === 2 || props.dataType === 3)
      return (
        <PublicFacilityDatatable
          data={props.data}
          onViewTableClick={this.onViewPFTableClick}
          onResultFilterChange={this.onResultFilterChange}
          onCustomerNameClicked={this.handleClick}
          fullData={props.fullData}
        />
      );
  };

  onResultFilterChange = (filteredData) => {
    this.setState({
      markers: filteredData,
    });
  };

  onViewPFTableClick = (selectedData) => {
    this.setState({
      selectedMarker: selectedData,
    });
  };
}

const mapStateToProps = (state) => {
  return {
    savedPlacesTableData: state.savedPlacesTableStore.savedPlacesTableData,
    savedPlaceApproved: state.savedPlacesTableStore.savedPlaceApproved,
    savedPlaceNotApproved: state.savedPlacesTableStore.savedPlaceNotApproved,
    savedPlaceDeleted: state.savedPlacesTableStore.savedPlaceDeleted,
    totalPlace: state.savedPlacesTableStore.totalPlace,
    approvedPlace: state.savedPlacesTableStore.approvedPlace,
    notApprovedPlace: state.savedPlacesTableStore.notApprovedPlace,
    deactivatedPlace: state.savedPlacesTableStore.deactivatedPlace,
    inProgress: state.savedPlacesTableStore.inProgress,
    successSavedPlaceFetch: state.savedPlacesTableStore.success,
    kml: state.companyStore.companyKML,
    statKML: state.settingStore.kmlSet,

    //Customers
    mainCustomerSelect: state.mainCustomerStore.mainCustomerSelect,
    inProgressMainCustomer: state.mainCustomerStore.inProgress,
    successMainCustomer: state.mainCustomerStore.success,
  };
};

SavedPlaces.contextType = AbilityContext;
export default connect(mapStateToProps, {
  fetchSavedPlacesTable,
  fetchMainCustomerList,
  fetchCompanyList,
  fetchSettingList,
})(SavedPlaces);
