import React, { useCallback, useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Label, FormGroup, Col } from "reactstrap";
import GeofencingMap from "./GeofencingMap";
import * as API_LINKS from "../../../../redux/config/links";

const GeofencingView = ({ id }) => {
  const [isFetching, setIsFetching] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const onGetData = useCallback(() => {
    if (id) {
      const loginCreds = JSON.parse(window.atob(localStorage.Automalt));
      const auth = loginCreds.token;
      setIsLoading(true);
      axios({
        method: "post",
        url: API_LINKS.SAVED_PLACES_LIST_URL, //Please change when it's ready
        headers: {
          Authorization: auth,
        },
        data: {
          id,
        },
      })
        .then((res) => {
          if (res.data.result && res.data.result.length > 0) {
            const [response] = res.data.result;
            const dt = {
              name_id: response.name_id,
              address: response.address,
              company_name: response.company_name,
              company_phone: response.company_phone,
              created_on: response.created_on,
              customer_email: response.customer_email,
              customer_handphone: response.customer_handphone,
              customer_name: response.customer_name,
              date_approve: response.date_approve,
              email_user_approve: response.email_user_approve,
              id: response.id,
              id_company: response.id_company,
              id_customer: response.id_customer,
              id_type: response.id_type,
              id_user_approve: response.id_user_approve,
              lat: response.lat,
              lng: response.lng,
              modified_on: response.modified_on,
              name: response.name,
              public_place: response.public_place,
              review_points: response.review_points,
              review_url: response.review_url,
              reviews: response.reviews,
              status: response.status,
              type_desc: response.type_desc,
              type_name: response.type_name,
              verify_status: response.verify_status,
              radius: response.geoJson?.features?.[0]?.geometry?.radius,
              savedPolygons: response?.savedPolygons,
              type_map: response.type_map,
            };
            setData({ ...dt });
          }
          setIsLoading(false); // Pastikan ini dipanggil
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [id]);

  useEffect(() => {
    if (isFetching) {
      setIsFetching(false);
      onGetData();
    }
  }, [onGetData, isFetching]);

  // Render Circle Data
  const renderCircleData = () => (
    <>
      <FormGroup row>
        <Col md="3">
          <Label>Radius: </Label>
        </Col>
        <Col xs="12" md="9">
          <Label>{data.radius || "-"}</Label>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="3">
          <Label>Latitude: </Label>
        </Col>
        <Col xs="12" md="9">
          <Label>{data.lat || "-"}</Label>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="3">
          <Label>Longitude: </Label>
        </Col>
        <Col xs="12" md="9">
          <Label>{data.lng || "-"}</Label>
        </Col>
      </FormGroup>
    </>
  );

  // Render Polygon Data
  const renderPolygonData = () => (
    <FormGroup row>
      <Col md="3">
        <Label>Saved Polygons: </Label>
      </Col>
      <Col xs="12" md="9">
        {data.savedPolygons?.length > 0 ? (
          <ul>
            {data.savedPolygons.map((polygon, index) => (
              <li key={index}>
                Lat: {polygon.lat}, Lon: {polygon.lon}
              </li>
            ))}
          </ul>
        ) : (
          <Label>-</Label>
        )}
      </Col>
    </FormGroup>
  );

  return (
    <>
      <div className="container">
        {isLoading ? (
          "Loading ..."
        ) : (
          <>
            {data !== null && (
              <>
                <FormGroup row>
                  <Col xs="12" md="12">
                    <GeofencingMap
                      setFieldValue={()=>{}}
                      values={{
                        type_map:data.type_map,
                        lat: data.lat,
                        lng: data.lng,
                        radius: data.radius,
                        savedPolygons: data.savedPolygons,
                      }}
                      setSavedPolygons={()=>{}}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label>Places Name: </Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Label>{data.name || "-"}</Label>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label>Type Map: </Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Label>{data.type_map === 1 ? "Circle" : "Polygon"}</Label>
                  </Col>
                </FormGroup>
                {data.type_map === 1 ? renderCircleData() : renderPolygonData()}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // KML
    kml: state.companyStore.companyKML,
    statKML: state.settingStore.kmlSet,
  };
};

export default connect(mapStateToProps, {})(GeofencingView);
