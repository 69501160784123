import { useState } from "react";
import AtomTableV2 from "src/components/atoms/TableV2/TableV2";
import { IProps } from "./TableV2.type";

const TableV2: React.FC<IProps> = ({
  name,
  data,
  renderExpendRow,
  columns,
  option,
}) => {
  const [page, setPage] = useState({ of: 1, size: 20 });
  const [sort, setSort] = useState<{ by: string; order: "asc" | "desc" }[]>([
    ...(!!option?.defaultSort ? [option?.defaultSort] : []),
  ]);
  const [filter, setFilter] = useState<any>({});
  const [keyword, setKeyword] = useState<string>("");

  const filteredList = (item: any, field: string, search = "") => {
    if (search !== "") {
      let found = false;
      for (let i = 0; i < item[field].toString().length; i++) {
        if (found === false) {
          found =
            search.toLowerCase() ===
            item[field]
              .toString()
              .toLowerCase()
              .substring(i, search.length + i);
        } else {
          i = item[field].length;
        }
      }
      return found;
    }

    return true;
  };

  const onPagination = (arr: any[]) => {
    return arr.filter((_, index) => {
      const startIndex = page.size * page.of - page.size;
      const endIndex = page.size * page.of;
      return startIndex <= index && endIndex > index;
    });
  };

  const onFilterByField = (arr: any[]) => {
    let filtered = [...arr];
    if (keyword === "") {
      for (const f in filter) {
        filtered = [
          ...filtered.filter((item) => filteredList(item, f, filter[f])),
        ];
      }
    } else {
      filtered = [];
      for (const a in arr) {
        for (const c in columns) {
          if (
            !!columns[c].field &&
            !!arr[a][columns[c].field] &&
            filteredList(arr[a], columns[c].field, keyword)
          ) {
            filtered = [...filtered, arr[a]];
          }
        }
      }
    }

    return filtered;
  };

  return (
    <AtomTableV2
      name={name}
      onPage={(value) => setPage({ ...page, ...value })}
      onPagePrevious={() => setPage({ ...page, of: page.of - 1 })}
      onPageNext={() => setPage({ ...page, of: page.of + 1 })}
      totalData={onFilterByField(data).length}
      page={page}
      sort={sort}
      onSort={(s) => {
        setSort([...s]);
      }}
      data={data}
      dataFiltered={onPagination(
        onFilterByField(
          data.sort((a, b) => {
            for (const { by, order } of sort) {
              const comparison = a[by] > b[by] ? 1 : a[by] < b[by] ? -1 : 0;
              if (comparison !== 0) {
                return order === "asc" ? comparison : -comparison;
              }
            }
            return 0;
          })
        )
      )}
      filter={filter}
      onFilter={(f) => {
        setFilter({ ...f });
        setKeyword("");
      }}
      renderExpendRow={renderExpendRow}
      columns={columns}
      search={keyword}
      onSearch={(s) => {
        setKeyword(s);
        setFilter({});
      }}
    />
  );
};

export default TableV2;
