import React, { Component } from "react";
import {
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Badge,
} from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
// import Select from 'react-select';
import "react-select/dist/react-select.css";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { connect } from "react-redux";
import { BarLoader } from "react-spinners";
import { Link } from "react-router-dom";
import { fetchDeliveryOrdersTable } from "../../../redux/actions/transporter/aTransporter-DeliveryOrdersTable";
import { fetchSecurityTable } from "../../../redux/actions/transporter/aTransporter-SecurityTable";
import { fetchDeliveryStatusList } from "../../../redux/actions/transporter/aTransporter-DeliveryStatusList";
import { fetchDeleteItem } from "../../../redux/actions/transporter/aTransporter-DeleteItem";
import * as API_LINKS from "../../../redux/config/links";
import DateTime from "react-datetime";
import moment from "moment";
import PropTypes from "prop-types";
import DeliveryOrdersDetail from "./DeliveryOrdersDetail";
import DOCheckSheetBinding from "./DOCheckSheetBinding";
import * as Yup from "yup";
import { Formik } from "formik";
import { SelectField } from "../../LandLord/Widgets/SelectField";
import { AbilityContext } from "../../../casl/Can";
import DOCopyForm from "./DOCopyForm";
import FleetsList from "./DeliveryOrders-FleetsList.jsx";
import DriversList from "./DeliveryOrders-DriversList.jsx";
import DriverAssistantsList from "./DeliveryOrders-DriverAssistantsList.jsx";
import BindWithSubcategory from "./BindWithSubcategory";
import UnbindSubcategory from "./UnbindSubcategory";
import TableV2 from "src/components/molecules/TableV2/TableV2";

const propTypes = {
  day: PropTypes.number,
};

const defaultProps = {
  day: 7,
};

function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

const validationBindSchema = Yup.object().shape({
  status_delivery: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.number(),
    })
    .nullable()
    .required("Location is required"),
  notes: Yup.string().notRequired(),
});

class DeliveryOrdersDatatable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalAdd: false,
      modalDoCopy: false,
      securityList: [],
      selectedDO: 0,
      selectedNumber: "",
      selectedSecurity: {},
      initialValueCOpyDO: {},
      initialApprovalSchema: {
        status_delivery: {},
        notes: "",
      },
      fleets: [],
      drivers: [],
      driverAssistants: [],
      currentDOData: {
        assigned_do_date: "",
        end_assigned_do_date: "",
        driver_name: "",
        driver_assistant_name: "",
      },
      modalBindCategory: null,
      modalDeleteCategory: null,
    };
    this.getDriverAssistantsList();
    this.getDriversList();
    this.getFleetsList();
  }

  UNSAFE_componentWillMount = () => {
    this.props.fetchDeliveryStatusList();
    this.props.fetchDeliveryOrdersTable(
      this.props.day,
      this.props.by_assign_date,
      this.props.flag
    );
    this.props.fetchSecurityTable();
  };

  refreshDOTable = () => {
    this.props.fetchDeliveryOrdersTable(
      this.props.day,
      this.props.by_assign_date,
      this.props.flag
    );
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      newProps.day !== this.props.day ||
      newProps.by_assign_date !== this.props.by_assign_date ||
      newProps.flag !== this.props.flag
    ) {
      /* do stuff */ this.props.fetchDeliveryOrdersTable(
        newProps.day,
        newProps.by_assign_date,
        newProps.flag
      );
    }
  }

  loadSecurityDatatoSelect = () => {
    this.setState({
      securityList: [],
    });
    var list = [];
    if (this.props.securityTableData.result.length !== 0) {
      var SecurityDatas = this.props.securityTableData.result;

      SecurityDatas.forEach((p, index) => {
        var securityData = {
          label: SecurityDatas[index].name,
          value: SecurityDatas[index].id,
        };
        list.push(securityData);
      });
    }
    this.setState({ securityList: list });
  };

  toggleOpenBind = (_id, _doNumber) => {
    // this.loadSecurityDatatoSelect()
    this.setState({
      selectedId: _id,
      selectedDONumber: _doNumber,
      modalBind: !this.state.modalBind,
    });
  };

  toggleOpenEditDO = (_id, _doNumber, _row) => {
    if (_row)
      this.setState({
        selectedDO: _id,
        selectedNumber: _doNumber,
        currStartDODate: _row.assigned_do_date,
        currEndDODate: _row.end_assigned_do_date,
        currentDOData: _row,
        editedEndDate: moment(_row.end_assigned_do_date).format(
          "MM-DD-YYYY HH:mm:ss"
        ),
        editedStartDate: moment(_row.assigned_do_date).format(
          "MM-DD-YYYY HH:mm:ss"
        ),
        iseditEndDoDate: false,
        modalEditDO: !this.state.modalEditDO,
      });
  };

  toggleOpenDelete = (_id, _doNumber) => {
    this.setState({
      selectedDO: _id,
      selectedNumber: _doNumber,
      modalDelete: !this.state.modalDelete,
    });
  };

  toggleOpenCopyDO = (_id, do_number, do_start, do_end) => {
    let getStartEndDifference = moment.duration(
      moment(do_end).diff(moment(do_start))
    );
    let getDifferenceHour = getStartEndDifference.asHours();
    this.setState({
      modalDoCopy: !this.state.modalDoCopy,
      selectedDO: do_number,
      initialValueCOpyDO: {
        id_delivery_order: _id,
        difference: getDifferenceHour,
      },
    });
  };

  toggleCloseCopyDO = () => {
    this.setState({
      modalDoCopy: !this.state.modalDoCopy,
      initialValueCOpyDO: {},
    });
  };

  toggleOpenSendPath = (_id, _doNumber) => {
    // this.loadSecurityDatatoSelect()
    this.setState({
      selectedId: _id,
      selectedDONumber: _doNumber,
      modalSendPath: !this.state.modalSendPath,
    });
  };

  toggleCloseSendPath = () => {
    this.setState({
      selectedDO: 0,
      selectedNumber: "",
      modalSendPath: !this.state.modalSendPath,
    });
  };

  toggleOpenCheckSheetBinding = (_id, _doNumber) => {
    // this.loadSecurityDatatoSelect()
    this.setState({
      id_do: _id,
      selectedDONumber: _doNumber,
      modalCheckSheetBinding: !this.state.modalCheckSheetBinding,
    });
  };

  toggleCloseCheckSheetBinding = () => {
    this.setState({
      selectedDO: 0,
      selectedNumber: "",
      modalCheckSheetBinding: !this.state.modalCheckSheetBinding,
    });
  };

  toggleCloseEditDO = () => {
    this.setState({
      selectedDO: 0,
      selectedNumber: "",
      currEndDODate: "",
      modalEditDO: !this.state.modalEditDO,
    });
  };

  toggleCloseDelete = () => {
    this.setState({
      selectedDO: 0,
      selectedNumber: "",
      iseditEndDoDate: false,
      modalDelete: !this.state.modalDelete,
    });
  };

  toggleCloseBind = () => {
    this.setState({
      selectedId: 0,
      selectedDONumber: null,
      modalBind: !this.state.modalBind,
    });
  };

  ActionFormatter(cell, row) {
    if (row.id_security === null) {
      //eslint-disable-next-line
      return (
        <React.Fragment>
          {this.context.can(
            "view",
            "action_table_content_1@delivery_orders_datatable"
          ) && (
            <div>
              {/* <ButtonGroup> */}
              <Link
                to={{
                  pathname: "/transporter/work-order/" + row.id,
                  state: {
                    id: row.id,
                    do_number: row.do_number,
                    do_desc: row.do_desc,
                    fleet_plate: row.fleet_plate,
                    driver_name: row.driver_name,
                    driver_photo_link: row.driver_photo_link,
                    driver_assistant_name: row.driver_assistant_name,
                    driver_assistant_photo_link: row.driver_assist_photo_link,
                    other_fee: row.other_fee,
                    toll_fee: row.toll_fee,
                    weight_fee: row.weight_fee,
                    total_est_carbon: row.total_est_carbon,
                    total_est_delivery_cost: row.total_est_delivery_cost,
                    do_assigned_date: row.assigned_do_date,
                    lock_audit: row.lock_audit,
                    delivery_order_family_type: row.delivery_order_family_type,
                  },
                }}
              >
                <Button className="btn btn-warning" size="sm">
                  <i className="fa fa-eye"></i> Work Order
                </Button>
              </Link>
              <br />
              <br />
              {/* <Link to={'/transporter/report/delivery/'+row.id}><Button className="btn btn-primary" color="primary" size="sm"><i className="fa fa-file"></i> Report</Button></Link> */}
              {/* <Link 
                                to={{
                                    pathname: '/transporter/report/delivery/'+row.id,
                                    state: {
                                        id: row.id,
                                        fleet_plate: row.fleet_plate,
                                        driver_name: row.driver_name,
                                        driver_assistant_name: row.driver_assistant_name,
                                        do_number: row.do_number,
                                        do_desc: row.do_desc,
                                        do_assigned_date: row.assigned_do_date
                                    }
                                }}> 
                                <Button className="btn btn-primary" color="primary" size="sm"><i className="fa fa-file"></i> Report</Button>
                            </Link><br/><br/> */}
              <Link
                to={{
                  pathname: "/transporter/delivery/audit/" + row.id,
                  state: {
                    id: row.id,
                    fleet_plate: row.fleet_plate,
                    driver_name: row.driver_name,
                    driver_photo_link: row.driver_photo_link,
                    driver_assistant_name: row.driver_assistant_name,
                    driver_assistant_photo_link: row.driver_assist_photo_link,
                    do_number: row.do_number,
                    do_desc: row.do_desc,
                    do_assigned_date: row.assigned_do_date,
                    other_fee: row.other_fee,
                    toll_fee: row.toll_fee,
                    weight_fee: row.weight_fee,
                    total_est_carbon: row.total_est_carbon,
                    total_est_delivery_cost: row.total_est_delivery_cost,
                    lock_audit: row.lock_audit,
                    delivery_order_family_type: row.delivery_order_family_type,
                  },
                }}
              >
                <Button
                  className="btn btn-secondary"
                  color="secondary"
                  size="sm"
                >
                  <i className="fa fa-binoculars"></i> Review
                </Button>
                <br />
              </Link>
              <br />
              {/* </ButtonGroup><br/> */}
              {/* <ButtonGroup> */}
              {/* <Button className="btn btn-success" color="success" size="sm" onClick={() => this.toggleOpenBind(row.id)}><i className="fa fa-shield"></i> Security Approval</Button><br/><br/> */}
              <Button
                className="btn btn-primary"
                color="primary"
                size="sm"
                onClick={() => this.toggleOpenSendPath(row.id, row.do_number)}
              >
                <i className="fa fa-cloud-upload"></i> Send Path
              </Button>
              <br />
              <br />
              <ButtonGroup>
                {row.check_sheet_approval === 1 && (
                  <Button
                    className="btn btn-primary"
                    color="success"
                    size="sm"
                    disabled={true}
                    style={{ opacity: "1" }}
                  >
                    <i className="fa fa-check-square-o"></i> Check Sheet
                    Approved
                  </Button>
                )}
                {row.check_sheet_approval === 1 && null}
                {row.check_sheet_approval !== 1 && !row.check_sheet_filled && (
                  <Button
                    className="btn btn-primary"
                    color="success"
                    size="sm"
                    onClick={() =>
                      this.toggleOpenCheckSheetBinding(row.id, row.do_number)
                    }
                  >
                    Add Check Sheet
                  </Button>
                )}
                {row.check_sheet_approval !== 1 && row.check_sheet_filled && (
                  <Button
                    className="btn btn-primary"
                    color="warning"
                    size="sm"
                    onClick={() =>
                      this.toggleOpenCheckSheetBinding(row.id, row.do_number)
                    }
                  >
                    Edit Check Sheet
                  </Button>
                )}
                {row.check_sheet_filled && (
                  <Link
                    to={{
                      pathname:
                        "/transporter/do-check-sheet/approval/" + row.id,
                      state: {
                        id_do: row.id,
                        do_number: row.do_number,
                      },
                    }}
                  >
                    <Button
                      className="btn btn-primary"
                      color="primary"
                      size="sm"
                    >
                      <i className="fa fa-list-alt"></i> Detail
                    </Button>
                  </Link>
                )}
              </ButtonGroup>
              <br />
              <br />
              <Button
                className="btn btn-primary"
                color="primary"
                size="sm"
                onClick={() =>
                  this.toggleOpenCopyDO(
                    row.id,
                    row.do_number,
                    row.assigned_do_date,
                    row.end_assigned_do_date
                  )
                }
              >
                <i className="fa fa-files-o"></i> Copy DO
              </Button>
              {/* </ButtonGroup> */}
            </div>
          )}

          {this.context.can(
            "view",
            "action_table_content_2@delivery_orders_datatable"
          ) && (
            <div>
              {/* <ButtonGroup> */}
              <Link
                to={{
                  pathname: "/transporter/work-order/" + row.id,
                  state: {
                    id: row.id,
                    do_number: row.do_number,
                    do_desc: row.do_desc,
                    fleet_plate: row.fleet_plate,
                    driver_name: row.driver_name,
                    driver_photo_link: row.driver_photo_link,
                    driver_assistant_name: row.driver_assistant_name,
                    driver_assistant_photo_link: row.driver_assist_photo_link,
                    other_fee: row.other_fee,
                    toll_fee: row.toll_fee,
                    weight_fee: row.weight_fee,
                    total_est_carbon: row.total_est_carbon,
                    total_est_delivery_cost: row.total_est_delivery_cost,
                    do_assigned_date: row.assigned_do_date,
                    lock_audit: row.lock_audit,
                    delivery_order_family_type: row.delivery_order_family_type,
                  },
                }}
              >
                <Button className="btn btn-warning" size="sm">
                  <i className="fa fa-eye"></i> Work Order
                </Button>
              </Link>
              <br />
              <br />
              {/* <Link to={'/transporter/report/delivery/'+row.id}><Button className="btn btn-primary" color="primary" size="sm"><i className="fa fa-file"></i> Report</Button></Link> */}
              {/* <Link 
                                to={{
                                    pathname: '/transporter/report/delivery/'+row.id,
                                    state: {
                                        id: row.id,
                                        fleet_plate: row.fleet_plate,
                                        driver_name: row.driver_name,
                                        driver_assistant_name: row.driver_assistant_name,
                                        do_number: row.do_number,
                                        do_desc: row.do_desc,
                                        do_assigned_date: row.assigned_do_date
                                    }
                                }}> 
                                <Button className="btn btn-primary" color="primary" size="sm"><i className="fa fa-file"></i> Report</Button><br/><br/>
                            </Link> */}
              <Link
                to={{
                  pathname: "/transporter/delivery/audit/" + row.id,
                  state: {
                    id: row.id,
                    fleet_plate: row.fleet_plate,
                    driver_name: row.driver_name,
                    driver_photo_link: row.driver_photo_link,
                    driver_assistant_name: row.driver_assistant_name,
                    driver_assistant_photo_link: row.driver_assist_photo_link,
                    do_number: row.do_number,
                    do_desc: row.do_desc,
                    do_assigned_date: row.assigned_do_date,
                    other_fee: row.other_fee,
                    toll_fee: row.toll_fee,
                    weight_fee: row.weight_fee,
                    total_est_carbon: row.total_est_carbon,
                    total_est_delivery_cost: row.total_est_delivery_cost,
                    lock_audit: row.lock_audit,
                    delivery_order_family_type: row.delivery_order_family_type,
                  },
                }}
              >
                <Button
                  className="btn btn-secondary"
                  color="secondary"
                  size="sm"
                >
                  <i className="fa fa-binoculars"></i> Review
                </Button>
                <br />
                <br />
              </Link>
              <Button
                className="btn btn-primary"
                color="primary"
                size="sm"
                onClick={() => this.toggleOpenSendPath(row.id, row.do_number)}
              >
                <i className="fa fa-cloud-upload"></i> Send Path
              </Button>
              <br />
              <br />
              <ButtonGroup>
                {row.check_sheet_approval === 1 && (
                  <Button
                    className="btn btn-primary"
                    color="success"
                    size="sm"
                    disabled={true}
                    style={{ opacity: "1" }}
                  >
                    <i className="fa fa-check-square-o"></i> Check Sheet
                    Approved
                  </Button>
                )}
                {row.check_sheet_filled && (
                  <Link
                    to={{
                      pathname:
                        "/transporter/do-check-sheet/approval/" + row.id,
                      state: {
                        id_do: row.id,
                        do_number: row.do_number,
                      },
                    }}
                  >
                    <Button
                      className="btn btn-primary"
                      color="primary"
                      size="sm"
                    >
                      <i className="fa fa-list-alt"></i>
                      {row.check_sheet_approval === 1 && " Detail"}
                      {row.check_sheet_approval !== 1 && " Check Sheet Detail"}
                    </Button>
                  </Link>
                )}
              </ButtonGroup>
              {/* </ButtonGroup> */}
              <br />
            </div>
          )}

          {this.context.can(
            "view",
            "action_table_content_3@delivery_orders_datatable"
          ) && (
            <div>
              <ButtonGroup>
                <Link
                  to={{
                    pathname: "/transporter/work-order/" + row.id,
                    state: {
                      id: row.id,
                      do_number: row.do_number,
                      do_desc: row.do_desc,
                      fleet_plate: row.fleet_plate,
                      driver_name: row.driver_name,
                      driver_photo_link: row.driver_photo_link,
                      driver_assistant_name: row.driver_assistant_name,
                      driver_assistant_photo_link: row.driver_assist_photo_link,
                      other_fee: row.other_fee,
                      toll_fee: row.toll_fee,
                      weight_fee: row.weight_fee,
                      total_est_carbon: row.total_est_carbon,
                      total_est_delivery_cost: row.total_est_delivery_cost,
                      do_assigned_date: row.assigned_do_date,
                      lock_audit: row.lock_audit,
                      delivery_order_family_type:
                        row.delivery_order_family_type,
                    },
                  }}
                >
                  <Button className="btn btn-warning" size="sm">
                    <i className="fa fa-eye"></i> Work Order
                  </Button>
                </Link>
                <br />
                <br />
                {/* <Link to={'/transporter/report/delivery/'+row.id}><Button className="btn btn-primary" color="primary" size="sm"><i className="fa fa-file"></i> Report</Button></Link> */}
                {/* <Link 
                                to={{
                                    pathname: '/transporter/report/delivery/'+row.id,
                                    state: {
                                        id: row.id,
                                        fleet_plate: row.fleet_plate,
                                        driver_name: row.driver_name,
                                        driver_assistant_name: row.driver_assistant_name,
                                        do_number: row.do_number,
                                        do_desc: row.do_desc,
                                        do_assigned_date: row.do_assigned_date
                                    }
                                }}> 
                            </Link> */}
              </ButtonGroup>
              <br />
              <br />
              <ButtonGroup>
                {row.check_sheet_approval === 1 && (
                  <Button
                    className="btn btn-primary"
                    color="success"
                    size="sm"
                    disabled={true}
                    style={{ opacity: "1" }}
                  >
                    <i className="fa fa-check-square-o"></i> Check Sheet
                    Approved
                  </Button>
                )}
                {row.check_sheet_filled && (
                  <Link
                    to={{
                      pathname:
                        "/transporter/do-check-sheet/approval/" + row.id,
                      state: {
                        id_do: row.id,
                        do_number: row.do_number,
                      },
                    }}
                  >
                    <Button
                      className="btn btn-primary"
                      color="primary"
                      size="sm"
                    >
                      <i className="fa fa-list-alt"></i>
                      {row.check_sheet_approval === 1 && " Detail"}
                      {row.check_sheet_approval !== 1 && " Check Sheet Detail"}
                    </Button>
                  </Link>
                )}
              </ButtonGroup>
              <ButtonGroup>
                {/* <Button className="btn btn-success" color="success" size="sm" onClick={() => this.toggleOpenBind(row.id, row.do_number)}><i className="fa fa-check"></i> Approve</Button> */}
              </ButtonGroup>
            </div>
          )}

          {this.context.can(
            "view",
            "action_table_content_4@delivery_orders_datatable"
          ) && (
            <div>
              {/* <ButtonGroup> */}
              <Link
                to={{
                  pathname: "/transporter/work-order/" + row.id,
                  state: {
                    id: row.id,
                    do_number: row.do_number,
                    do_desc: row.do_desc,
                    fleet_plate: row.fleet_plate,
                    driver_name: row.driver_name,
                    driver_photo_link: row.driver_photo_link,
                    driver_assistant_name: row.driver_assistant_name,
                    driver_assistant_photo_link: row.driver_assist_photo_link,
                    other_fee: row.other_fee,
                    toll_fee: row.toll_fee,
                    weight_fee: row.weight_fee,
                    total_est_carbon: row.total_est_carbon,
                    total_est_delivery_cost: row.total_est_delivery_cost,
                    do_assigned_date: row.assigned_do_date,
                    lock_audit: row.lock_audit,
                    delivery_order_family_type: row.delivery_order_family_type,
                    avoid_toll: row.avoid_toll,
                  },
                }}
              >
                <Button className="btn btn-warning" size="sm">
                  <i className="fa fa-eye"></i> Work Order
                </Button>
              </Link>
              <br />
              <br />
              {/* <Link to={'/transporter/report/delivery/'+row.id}><Button className="btn btn-primary" color="primary" size="sm"><i className="fa fa-file"></i> Report</Button></Link> */}
              {/* <Link 
                                to={{
                                    pathname: '/transporter/report/delivery/'+row.id,
                                    state: {
                                        id: row.id,
                                        fleet_plate: row.fleet_plate,
                                        driver_name: row.driver_name,
                                        driver_assistant_name: row.driver_assistant_name,
                                        do_number: row.do_number,
                                        do_desc: row.do_desc,
                                        do_assigned_date: row.assigned_do_date
                                    }
                                }}> 
                                <Button className="btn btn-primary" color="primary" size="sm"><i className="fa fa-file"></i> Report</Button>
                            </Link> */}
              {/* </ButtonGroup><br/> */}
              <Link
                to={{
                  pathname: "/transporter/delivery/audit/" + row.id,
                  state: {
                    id: row.id,
                    fleet_plate: row.fleet_plate,
                    driver_name: row.driver_name,
                    driver_photo_link: row.driver_photo_link,
                    driver_assistant_name: row.driver_assistant_name,
                    driver_assistant_photo_link: row.driver_assist_photo_link,
                    do_number: row.do_number,
                    do_desc: row.do_desc,
                    do_assigned_date: row.assigned_do_date,
                    other_fee: row.other_fee,
                    toll_fee: row.toll_fee,
                    weight_fee: row.weight_fee,
                    total_est_carbon: row.total_est_carbon,
                    total_est_delivery_cost: row.total_est_delivery_cost,
                    lock_audit: row.lock_audit,
                    delivery_order_family_type: row.delivery_order_family_type,
                    driver_done_time: row.driver_done_time,
                  },
                }}
              >
                <Button
                  className="btn btn-secondary"
                  color="secondary"
                  size="sm"
                >
                  <i className="fa fa-binoculars"></i> Review
                </Button>
              </Link>
              <br />
              <br />
              {/* <ButtonGroup> */}
              {/* <Button className="btn btn-success" color="success" size="sm" onClick={() => this.toggleOpenBind(row.id)}><i className="fa fa-shield"></i> Security Approval</Button><br/><br/> */}
              <Button
                className="btn btn-primary"
                color="primary"
                size="sm"
                onClick={() => this.toggleOpenSendPath(row.id, row.do_number)}
              >
                <i className="fa fa-cloud-upload"></i> Send Path
              </Button>
              <br />
              <br />
              <ButtonGroup>
                {row.check_sheet_approval === 1 && (
                  <Button
                    className="btn btn-primary"
                    color="success"
                    size="sm"
                    disabled={true}
                    style={{ opacity: "1" }}
                  >
                    <i className="fa fa-check-square-o"></i> Check Sheet
                    Approved
                  </Button>
                )}
                {row.check_sheet_approval === 1 && null}
                {row.check_sheet_approval !== 1 && !row.check_sheet_filled && (
                  <Button
                    className="btn btn-primary"
                    color="success"
                    size="sm"
                    onClick={() =>
                      this.toggleOpenCheckSheetBinding(row.id, row.do_number)
                    }
                  >
                    Add Check Sheet
                  </Button>
                )}
                {row.check_sheet_approval !== 1 && row.check_sheet_filled && (
                  <Button
                    className="btn btn-primary"
                    color="warning"
                    size="sm"
                    onClick={() =>
                      this.toggleOpenCheckSheetBinding(row.id, row.do_number)
                    }
                  >
                    Edit Check Sheet
                  </Button>
                )}
                {row.check_sheet_filled && (
                  <Link
                    to={{
                      pathname:
                        "/transporter/do-check-sheet/approval/" + row.id,
                      state: {
                        id_do: row.id,
                        do_number: row.do_number,
                      },
                    }}
                  >
                    <Button
                      className="btn btn-primary"
                      color="primary"
                      size="sm"
                    >
                      <i className="fa fa-list-alt"></i> Detail
                    </Button>
                  </Link>
                )}
              </ButtonGroup>
              <br />
              <br />
              {/* </ButtonGroup>
                        <ButtonGroup> */}
              {row.draft_value === 0 && (
                <React.Fragment>
                  <Button
                    className="btn btn-warning"
                    color="warning"
                    size="sm"
                    onClick={() =>
                      this.toggleOpenEditDO(row.id, row.do_number, row)
                    }
                  >
                    <i className="fa fa-edit"></i>{" "}
                    {row.draft_value === 0 ? "Edit DO" : "Edit End DO Date"}
                  </Button>
                  <br />
                  <br />
                </React.Fragment>
              )}
              <Button
                className="btn btn-primary"
                color="primary"
                size="sm"
                onClick={() =>
                  this.toggleOpenCopyDO(
                    row.id,
                    row.do_number,
                    row.assigned_do_date,
                    row.end_assigned_do_date
                  )
                }
              >
                <i className="fa fa-files-o"></i> Copy DO
              </Button>
              <br />
              <br />
              <Button
                className="btn btn-danger"
                color="danger"
                size="sm"
                onClick={() => this.toggleOpenDelete(row.id, row.do_number)}
              >
                <i className="fa fa-trash"></i> Delete
              </Button>
              {/* </ButtonGroup> */}
            </div>
          )}

          {this.context.can(
            "view",
            "action_table_content_5@delivery_orders_datatable"
          ) && (
            <div>
              {/* <ButtonGroup> */}
              <Link
                to={{
                  pathname: "/transporter/work-order/" + row.id,
                  state: {
                    id: row.id,
                    do_number: row.do_number,
                    do_desc: row.do_desc,
                    fleet_plate: row.fleet_plate,
                    driver_name: row.driver_name,
                    driver_photo_link: row.driver_photo_link,
                    driver_assistant_name: row.driver_assistant_name,
                    driver_assistant_photo_link: row.driver_assist_photo_link,
                    other_fee: row.other_fee,
                    toll_fee: row.toll_fee,
                    weight_fee: row.weight_fee,
                    total_est_carbon: row.total_est_carbon,
                    total_est_delivery_cost: row.total_est_delivery_cost,
                    do_assigned_date: row.assigned_do_date,
                    lock_audit: row.lock_audit,
                    delivery_order_family_type: row.delivery_order_family_type,
                  },
                }}
              >
                <Button className="btn btn-warning" size="sm">
                  <i className="fa fa-eye"></i> Work Order
                </Button>
              </Link>
              <br />
              <br />
              {/* <Link to={'/transporter/report/delivery/'+row.id}><Button className="btn btn-primary" color="primary" size="sm"><i className="fa fa-file"></i> Report</Button></Link> */}
              {/* <Link 
                                to={{
                                    pathname: '/transporter/report/delivery/'+row.id,
                                    state: {
                                        id: row.id,
                                        fleet_plate: row.fleet_plate,
                                        driver_name: row.driver_name,
                                        driver_assistant_name: row.driver_assistant_name,
                                        do_number: row.do_number,
                                        do_desc: row.do_desc,
                                        do_assigned_date: row.assigned_do_date
                                    }
                                }}> 
                                <Button className="btn btn-primary" color="primary" size="sm"><i className="fa fa-file"></i> Report</Button>
                            </Link> */}
              {/* </ButtonGroup><br/> */}
              <br />
              <br />
              <Link
                to={{
                  pathname: "/transporter/delivery/audit/" + row.id,
                  state: {
                    id: row.id,
                    fleet_plate: row.fleet_plate,
                    driver_name: row.driver_name,
                    driver_photo_link: row.driver_photo_link,
                    driver_assistant_name: row.driver_assistant_name,
                    driver_assistant_photo_link: row.driver_assist_photo_link,
                    do_number: row.do_number,
                    do_desc: row.do_desc,
                    do_assigned_date: row.assigned_do_date,
                    other_fee: row.other_fee,
                    toll_fee: row.toll_fee,
                    weight_fee: row.weight_fee,
                    total_est_carbon: row.total_est_carbon,
                    total_est_delivery_cost: row.total_est_delivery_cost,
                    lock_audit: row.lock_audit,
                    delivery_order_family_type: row.delivery_order_family_type,
                  },
                }}
              >
                <Button
                  className="btn btn-secondary"
                  color="secondary"
                  size="sm"
                >
                  <i className="fa fa-binoculars"></i> Review
                </Button>
                <br />
                <br />
              </Link>
              {/* <ButtonGroup> */}
              <Button
                className="btn btn-success"
                color="success"
                size="sm"
                onClick={() => this.toggleOpenBind(row.id)}
              >
                <i className="fa fa-shield"></i> Security Approval
              </Button>
              <br />
              <br />
              <Button
                className="btn btn-primary"
                color="primary"
                size="sm"
                onClick={() => this.toggleOpenSendPath(row.id, row.do_number)}
              >
                <i className="fa fa-cloud-upload"></i> Send Path
              </Button>
              <br />
              <br />
              {/* </ButtonGroup>
                        <ButtonGroup> */}
              <Button
                className="btn btn-danger"
                color="danger"
                size="sm"
                onClick={() => this.toggleOpenDelete(row.id, row.do_number)}
              >
                <i className="fa fa-trash"></i> Delete
              </Button>
              {/* </ButtonGroup> */}
            </div>
          )}

          {this.context.can(
            "view",
            "action_table_content_14@delivery_orders_datatable"
          ) && (
            <div>
              <ButtonGroup>
                {row.check_sheet_approval === 1 && (
                  <Button
                    className="btn btn-primary"
                    color="success"
                    size="sm"
                    disabled={true}
                    style={{ opacity: "1" }}
                  >
                    <i className="fa fa-check-square-o"></i> Check Sheet
                    Approved
                  </Button>
                )}
                {row.check_sheet_approval === 1 && null}
                {row.check_sheet_approval !== 1 && !row.check_sheet_filled && (
                  <Button
                    className="btn btn-primary"
                    color="success"
                    size="sm"
                    onClick={() =>
                      this.toggleOpenCheckSheetBinding(row.id, row.do_number)
                    }
                  >
                    Add Check Sheet
                  </Button>
                )}
                {row.check_sheet_approval !== 1 && row.check_sheet_filled && (
                  <Button
                    className="btn btn-primary"
                    color="warning"
                    size="sm"
                    onClick={() =>
                      this.toggleOpenCheckSheetBinding(row.id, row.do_number)
                    }
                  >
                    Edit Check Sheet
                  </Button>
                )}
                {row.check_sheet_filled && (
                  <Link
                    to={{
                      pathname:
                        "/transporter/do-check-sheet/approval/" + row.id,
                      state: {
                        id_do: row.id,
                        do_number: row.do_number,
                      },
                    }}
                  >
                    <Button
                      className="btn btn-primary"
                      color="primary"
                      size="sm"
                    >
                      <i className="fa fa-list-alt"></i> Detail
                    </Button>
                  </Link>
                )}
              </ButtonGroup>
            </div>
          )}
        </React.Fragment>
      );
    } else {
      //eslint-disable-next-line
      return (
        <React.Fragment>
          {this.context.can(
            "view",
            "action_table_content_1@delivery_orders_datatable"
          ) && (
            <div>
              {/* <ButtonGroup> */}
              <Link
                to={{
                  pathname: "/transporter/work-order/" + row.id,
                  state: {
                    id: row.id,
                    do_number: row.do_number,
                    do_desc: row.do_desc,
                    fleet_plate: row.fleet_plate,
                    driver_name: row.driver_name,
                    driver_photo_link: row.driver_photo_link,
                    driver_assistant_photo_link: row.driver_assist_photo_link,
                    total_est_carbon: row.total_est_carbon,
                    total_est_delivery_cost: row.total_est_delivery_cost,
                    do_assigned_date: row.assigned_do_date,
                    lock_audit: row.lock_audit,
                    delivery_order_family_type: row.delivery_order_family_type,
                  },
                }}
              >
                <Button className="btn btn-warning" size="sm">
                  <i className="fa fa-eye"></i> Work Order
                </Button>
              </Link>
              <br />
              <br />
              {/* <Link to={'/transporter/report/delivery/'+row.id}><Button className="btn btn-primary" color="primary" size="sm"><i className="fa fa-file"></i> Report</Button></Link> */}
              {/* <Link 
                                to={{
                                    pathname: '/transporter/report/delivery/'+row.id,
                                    state: {
                                        id: row.id,
                                        fleet_plate: row.fleet_plate,
                                        driver_name: row.driver_name,
                                        driver_assistant_name: row.driver_assistant_name,
                                        do_number: row.do_number,
                                        do_desc: row.do_desc,
                                        do_assigned_date: row.assigned_do_date
                                    }
                                }}> 
                                <Button className="btn btn-primary" color="primary" size="sm"><i className="fa fa-file"></i> Report</Button>
                            </Link><br/><br/> */}
              <Link
                to={{
                  pathname: "/transporter/delivery/audit/" + row.id,
                  state: {
                    id: row.id,
                    fleet_plate: row.fleet_plate,
                    driver_name: row.driver_name,
                    driver_photo_link: row.driver_photo_link,
                    driver_assistant_name: row.driver_assistant_name,
                    driver_assistant_photo_link: row.driver_assist_photo_link,
                    do_number: row.do_number,
                    do_desc: row.do_desc,
                    do_assigned_date: row.assigned_do_date,
                    total_est_carbon: row.total_est_carbon,
                    total_est_delivery_cost: row.total_est_delivery_cost,
                    lock_audit: row.lock_audit,
                    delivery_order_family_type: row.delivery_order_family_type,
                  },
                }}
              >
                <Button
                  className="btn btn-secondary"
                  color="secondary"
                  size="sm"
                >
                  <i className="fa fa-binoculars"></i> Review
                </Button>
                <br />
                <br />
              </Link>
              <Button
                className="btn btn-primary"
                color="primary"
                size="sm"
                onClick={() => this.toggleOpenSendPath(row.id, row.do_number)}
              >
                <i className="fa fa-cloud-upload"></i> Send Path
              </Button>
              <br />
              <br />
              <ButtonGroup>
                {row.check_sheet_approval === 1 && (
                  <Button
                    className="btn btn-primary"
                    color="success"
                    size="sm"
                    disabled={true}
                    style={{ opacity: "1" }}
                  >
                    <i className="fa fa-check-square-o"></i> Check Sheet
                    Approved
                  </Button>
                )}
                {row.check_sheet_approval === 1 && null}
                {row.check_sheet_approval !== 1 && !row.check_sheet_filled && (
                  <Button
                    className="btn btn-primary"
                    color="success"
                    size="sm"
                    onClick={() =>
                      this.toggleOpenCheckSheetBinding(row.id, row.do_number)
                    }
                  >
                    Add Check Sheet
                  </Button>
                )}
                {row.check_sheet_approval !== 1 && row.check_sheet_filled && (
                  <Button
                    className="btn btn-primary"
                    color="warning"
                    size="sm"
                    onClick={() =>
                      this.toggleOpenCheckSheetBinding(row.id, row.do_number)
                    }
                  >
                    Edit Check Sheet
                  </Button>
                )}
                {row.check_sheet_filled && (
                  <Link
                    to={{
                      pathname:
                        "/transporter/do-check-sheet/approval/" + row.id,
                      state: {
                        id_do: row.id,
                        do_number: row.do_number,
                      },
                    }}
                  >
                    <Button
                      className="btn btn-primary"
                      color="primary"
                      size="sm"
                    >
                      <i className="fa fa-list-alt"></i> Detail
                    </Button>
                  </Link>
                )}
              </ButtonGroup>
              <br />
              <br />
              <Button
                className="btn btn-primary"
                color="primary"
                size="sm"
                onClick={() =>
                  this.toggleOpenCopyDO(
                    row.id,
                    row.do_number,
                    row.assigned_do_date,
                    row.end_assigned_do_date
                  )
                }
              >
                <i className="fa fa-files-o"></i> Copy DO
              </Button>
            </div>
          )}

          {this.context.can(
            "view",
            "action_table_content_2@delivery_orders_datatable"
          ) && (
            <div>
              {/* <ButtonGroup> */}
              <Link
                to={{
                  pathname: "/transporter/work-order/" + row.id,
                  state: {
                    id: row.id,
                    do_number: row.do_number,
                    do_desc: row.do_desc,
                    fleet_plate: row.fleet_plate,
                    driver_name: row.driver_name,
                    driver_photo_link: row.driver_photo_link,
                    total_est_carbon: row.total_est_carbon,
                    total_est_delivery_cost: row.total_est_delivery_cost,
                    do_assigned_date: row.assigned_do_date,
                    lock_audit: row.lock_audit,
                    delivery_order_family_type: row.delivery_order_family_type,
                  },
                }}
              >
                <Button className="btn btn-warning" size="sm">
                  <i className="fa fa-eye"></i> Work Order
                </Button>
              </Link>
              <br />
              <br />
              {/* <Link to={'/transporter/report/delivery/'+row.id}><Button className="btn btn-primary" color="primary" size="sm"><i className="fa fa-file"></i> Report</Button></Link> */}
              {/* <Link 
                                to={{
                                    pathname: '/transporter/report/delivery/'+row.id,
                                    state: {
                                        id: row.id,
                                        fleet_plate: row.fleet_plate,
                                        driver_name: row.driver_name,
                                        driver_assistant_name: row.driver_assistant_name,
                                        do_number: row.do_number,
                                        do_desc: row.do_desc,
                                        do_assigned_date: row.assigned_do_date
                                    }
                                }}> 
                                <Button className="btn btn-primary" color="primary" size="sm"><i className="fa fa-file"></i> Report</Button>
                            </Link><br /> <br/> */}
              <Link
                to={{
                  pathname: "/transporter/delivery/audit/" + row.id,
                  state: {
                    id: row.id,
                    fleet_plate: row.fleet_plate,
                    driver_name: row.driver_name,
                    driver_assistant_name: row.driver_assistant_name,
                    driver_assistant_photo_link: row.driver_assist_photo_link,
                    do_number: row.do_number,
                    do_desc: row.do_desc,
                    do_assigned_date: row.assigned_do_date,
                    total_est_carbon: row.total_est_carbon,
                    total_est_delivery_cost: row.total_est_delivery_cost,
                    lock_audit: row.lock_audit,
                    delivery_order_family_type: row.delivery_order_family_type,
                  },
                }}
              >
                <Button
                  className="btn btn-secondary"
                  color="secondary"
                  size="sm"
                >
                  <i className="fa fa-binoculars"></i> Review
                </Button>
                <br />
                <br />
              </Link>
              <br />
              <br />
              <ButtonGroup>
                {row.check_sheet_approval === 1 && (
                  <Button
                    className="btn btn-primary"
                    color="success"
                    size="sm"
                    disabled={true}
                    style={{ opacity: "1" }}
                  >
                    <i className="fa fa-check-square-o"></i> Check Sheet
                    Approved
                  </Button>
                )}
                {row.check_sheet_filled && (
                  <Link
                    to={{
                      pathname:
                        "/transporter/do-check-sheet/approval/" + row.id,
                      state: {
                        id_do: row.id,
                        do_number: row.do_number,
                      },
                    }}
                  >
                    <Button
                      className="btn btn-primary"
                      color="primary"
                      size="sm"
                    >
                      <i className="fa fa-list-alt"></i>
                      {row.check_sheet_approval === 1 && " Detail"}
                      {row.check_sheet_approval !== 1 && " Check Sheet Detail"}
                    </Button>
                  </Link>
                )}
              </ButtonGroup>
            </div>
          )}

          {this.context.can(
            "view",
            "action_table_content_3@delivery_orders_datatable"
          ) && (
            <div>
              <ButtonGroup>
                <Link
                  to={{
                    pathname: "/transporter/work-order/" + row.id,
                    state: {
                      id: row.id,
                      do_number: row.do_number,
                      do_desc: row.do_desc,
                      fleet_plate: row.fleet_plate,
                      driver_name: row.driver_name,
                      driver_photo_link: row.driver_photo_link,
                      total_est_carbon: row.total_est_carbon,
                      total_est_delivery_cost: row.total_est_delivery_cost,
                      do_assigned_date: row.assigned_do_date,
                      lock_audit: row.lock_audit,
                      delivery_order_family_type:
                        row.delivery_order_family_type,
                    },
                  }}
                >
                  <Button className="btn btn-warning" size="sm">
                    <i className="fa fa-eye"></i> Work Order
                  </Button>
                </Link>
                <br />
                <br />
                <br />
                {/* <Link to={'/transporter/report/delivery/'+row.id}><Button className="btn btn-primary" color="primary" size="sm"><i className="fa fa-file"></i> Report</Button></Link> */}
                {/* <Link 
                                to={{
                                    pathname: '/transporter/report/delivery/'+row.id,
                                    state: {
                                        id: row.id,
                                        fleet_plate: row.fleet_plate,
                                        driver_name: row.driver_name,
                                        driver_assistant_name: row.driver_assistant_name,
                                        do_number: row.do_number,
                                        do_desc: row.do_desc,
                                        do_assigned_date: row.assigned_do_date
                                    }
                                }}> 
                            </Link> */}
              </ButtonGroup>
              <br />
              <br />
              {/* {row.check_sheet_approval === 1?
                                 <><Badge color="success">  <i className="fa fa-check-square-o"></i> Check Sheet Approved</Badge>&nbsp;</> : null
                            } */}
              <ButtonGroup>
                {row.check_sheet_approval === 1 && (
                  <Button
                    className="btn btn-primary"
                    color="success"
                    size="sm"
                    disabled={true}
                    style={{ opacity: "1" }}
                  >
                    <i className="fa fa-check-square-o"></i> Check Sheet
                    Approved
                  </Button>
                )}
                {row.check_sheet_filled && (
                  <Link
                    to={{
                      pathname:
                        "/transporter/do-check-sheet/approval/" + row.id,
                      state: {
                        id_do: row.id,
                        do_number: row.do_number,
                      },
                    }}
                  >
                    <Button
                      className="btn btn-primary"
                      color="primary"
                      size="sm"
                    >
                      <i className="fa fa-list-alt"></i>
                      {row.check_sheet_approval === 1 && " Detail"}
                      {row.check_sheet_approval !== 1 && " Check Sheet Detail"}
                    </Button>
                  </Link>
                )}
              </ButtonGroup>
            </div>
          )}

          {this.context.can(
            "view",
            "action_table_content_4@delivery_orders_datatable"
          ) && (
            <div>
              {/* <ButtonGroup> */}
              <Link
                to={{
                  pathname: "/transporter/work-order/" + row.id,
                  state: {
                    id: row.id,
                    do_number: row.do_number,
                    do_desc: row.do_desc,
                    fleet_plate: row.fleet_plate,
                    driver_name: row.driver_name,
                    driver_photo_link: row.driver_photo_link,
                    total_est_carbon: row.total_est_carbon,
                    total_est_delivery_cost: row.total_est_delivery_cost,
                    do_assigned_date: row.assigned_do_date,
                    lock_audit: row.lock_audit,
                    delivery_order_family_type: row.delivery_order_family_type,
                  },
                }}
              >
                <Button className="btn btn-warning" size="sm">
                  <i className="fa fa-eye"></i> Work Order
                </Button>
              </Link>
              <br />
              <br />
              {/* <Link to={'/transporter/report/delivery/'+row.id}><Button className="btn btn-primary" color="primary" size="sm"><i className="fa fa-file"></i> Report</Button></Link> */}
              {/* <Link 
                                to={{
                                    pathname: '/transporter/report/delivery/'+row.id,
                                    state: {
                                        id: row.id,
                                        fleet_plate: row.fleet_plate,
                                        driver_name: row.driver_name,
                                        driver_assistant_name: row.driver_assistant_name,
                                        do_number: row.do_number,
                                        do_desc: row.do_desc,
                                        do_assigned_date: row.assigned_do_date
                                    }
                                }}> 
                                <Button className="btn btn-primary" color="primary" size="sm"><i className="fa fa-file"></i> Report</Button>
                            </Link> */}
              {/* </ButtonGroup> */}
              <Link
                to={{
                  pathname: "/transporter/delivery/audit/" + row.id,
                  state: {
                    id: row.id,
                    fleet_plate: row.fleet_plate,
                    driver_name: row.driver_name,
                    driver_assistant_name: row.driver_assistant_name,
                    driver_assistant_photo_link: row.driver_assist_photo_link,
                    do_number: row.do_number,
                    do_desc: row.do_desc,
                    do_assigned_date: row.assigned_do_date,
                    total_est_carbon: row.total_est_carbon,
                    total_est_delivery_cost: row.total_est_delivery_cost,
                    lock_audit: row.lock_audit,
                    delivery_order_family_type: row.delivery_order_family_type,
                  },
                }}
              >
                <Button
                  className="btn btn-secondary"
                  color="secondary"
                  size="sm"
                >
                  <i className="fa fa-binoculars"></i> Review
                </Button>
              </Link>
              <br />
              <br />
              <Button
                className="btn btn-primary"
                color="primary"
                size="sm"
                onClick={() => this.toggleOpenSendPath(row.id, row.do_number)}
              >
                <i className="fa fa-cloud-upload"></i> Send Path
              </Button>
              <br />
              <br />
              <ButtonGroup>
                {row.check_sheet_approval === 1 && (
                  <Button
                    className="btn btn-primary"
                    color="success"
                    size="sm"
                    disabled={true}
                    style={{ opacity: "1" }}
                  >
                    <i className="fa fa-check-square-o"></i> Check Sheet
                    Approved
                  </Button>
                )}
                {row.check_sheet_approval === 1 && null}
                {row.check_sheet_approval !== 1 && !row.check_sheet_filled && (
                  <Button
                    className="btn btn-primary"
                    color="success"
                    size="sm"
                    onClick={() =>
                      this.toggleOpenCheckSheetBinding(row.id, row.do_number)
                    }
                  >
                    Add Check Sheet
                  </Button>
                )}
                {row.check_sheet_approval !== 1 && row.check_sheet_filled && (
                  <Button
                    className="btn btn-primary"
                    color="warning"
                    size="sm"
                    onClick={() =>
                      this.toggleOpenCheckSheetBinding(row.id, row.do_number)
                    }
                  >
                    Edit Check Sheet
                  </Button>
                )}
                {row.check_sheet_filled && (
                  <Link
                    to={{
                      pathname:
                        "/transporter/do-check-sheet/approval/" + row.id,
                      state: {
                        id_do: row.id,
                        do_number: row.do_number,
                      },
                    }}
                  >
                    <Button
                      className="btn btn-primary"
                      color="primary"
                      size="sm"
                    >
                      <i className="fa fa-list-alt"></i> Detail
                    </Button>
                  </Link>
                )}
              </ButtonGroup>{" "}
              <br />
              <br />
              {row.draft_value === 0 && (
                <React.Fragment>
                  <Button
                    className="btn btn-warning"
                    color="warning"
                    size="sm"
                    onClick={() =>
                      this.toggleOpenEditDO(row.id, row.do_number, row)
                    }
                  >
                    <i className="fa fa-edit"></i>{" "}
                    {row.draft_value === 0 ? "Edit DO" : "Edit End DO Date"}
                  </Button>
                  <br />
                  <br />
                </React.Fragment>
              )}
              <Button
                className="btn btn-primary"
                color="primary"
                size="sm"
                onClick={() =>
                  this.toggleOpenCopyDO(
                    row.id,
                    row.do_number,
                    row.assigned_do_date,
                    row.end_assigned_do_date
                  )
                }
              >
                <i className="fa fa-files-o"></i> Copy DO
              </Button>
              <br />
              <br />
              {/* <ButtonGroup> */}
              <Button
                className="btn btn-danger"
                color="danger"
                size="sm"
                onClick={() => this.toggleOpenDelete(row.id, row.do_number)}
              >
                <i className="fa fa-trash"></i> Delete
              </Button>
              {/* </ButtonGroup> */}
              {row.id_delivery_sub_category === null ? (
                <>
                  <br />
                  <Button
                    className="btn btn-warning"
                    size="sm"
                    onClick={() => this.setState({ modalBindCategory: row })}
                  >
                    <i className="fa fa-edit"></i> Bind with subcategory
                  </Button>
                </>
              ) : (
                <>
                  <br />
                  <Button
                    className="btn btn-warning"
                    size="sm"
                    onClick={() => this.setState({ modalDeleteCategory: row })}
                  >
                    <i className="fa fa-edit"></i> Delete subcategory
                  </Button>
                </>
              )}
            </div>
          )}

          {this.context.can(
            "view",
            "action_table_content_5@delivery_orders_datatable"
          ) && (
            <div>
              <ButtonGroup>
                <Link
                  to={{
                    pathname: "/transporter/work-order/" + row.id,
                    state: {
                      id: row.id,
                      do_number: row.do_number,
                      do_desc: row.do_desc,
                      fleet_plate: row.fleet_plate,
                      driver_name: row.driver_name,
                      driver_photo_link: row.driver_photo_link,
                      total_est_carbon: row.total_est_carbon,
                      total_est_delivery_cost: row.total_est_delivery_cost,
                      do_assigned_date: row.assigned_do_date,
                      lock_audit: row.lock_audit,
                      delivery_order_family_type:
                        row.delivery_order_family_type,
                    },
                  }}
                >
                  <Button className="btn btn-warning" size="sm">
                    <i className="fa fa-eye"></i> Work Order
                  </Button>
                </Link>
                <br />
                <br />
                {/* <Link to={'/transporter/report/delivery/'+row.id}><Button className="btn btn-primary" color="primary" size="sm"><i className="fa fa-file"></i> Report</Button></Link> */}
                {/* <Link 
                                to={{
                                    pathname: '/transporter/report/delivery/'+row.id,
                                    state: {
                                        id: row.id,
                                        fleet_plate: row.fleet_plate,
                                        driver_name: row.driver_name,
                                        driver_assistant_name: row.driver_assistant_name,
                                        do_number: row.do_number,
                                        do_desc: row.do_desc,
                                        do_assigned_date: row.assigned_do_date
                                    }
                                }}> 
                                <Button className="btn btn-primary" color="primary" size="sm"><i className="fa fa-file"></i> Report</Button>
                            </Link> */}
              </ButtonGroup>
              <br />
              <br />
              <Link
                to={{
                  pathname: "/transporter/delivery/audit/" + row.id,
                  state: {
                    id: row.id,
                    fleet_plate: row.fleet_plate,
                    driver_name: row.driver_name,
                    driver_assistant_name: row.driver_assistant_name,
                    driver_assistant_photo_link: row.driver_assist_photo_link,
                    do_number: row.do_number,
                    do_desc: row.do_desc,
                    do_assigned_date: row.assigned_do_date,
                    total_est_carbon: row.total_est_carbon,
                    total_est_delivery_cost: row.total_est_delivery_cost,
                    lock_audit: row.lock_audit,
                    delivery_order_family_type: row.delivery_order_family_type,
                  },
                }}
              >
                <Button
                  className="btn btn-secondary"
                  color="secondary"
                  size="sm"
                >
                  <i className="fa fa-binoculars"></i> Review
                </Button>
              </Link>
              <br />
              <br />
              <Button
                className="btn btn-primary"
                color="primary"
                size="sm"
                onClick={() => this.toggleOpenSendPath(row.id, row.do_number)}
              >
                <i className="fa fa-cloud-upload"></i> Send Path
              </Button>
              <br />
              <br />
              <ButtonGroup>
                <Button
                  className="btn btn-danger"
                  color="danger"
                  size="sm"
                  onClick={() => this.toggleOpenDelete(row.id, row.do_number)}
                >
                  <i className="fa fa-trash"></i> Delete
                </Button>
              </ButtonGroup>
            </div>
          )}
        </React.Fragment>
      );
    }
  }

  viewBindSecurity = () => {
    const { deliveryStatusListData } = this.props;
    return (
      <Formik
        initialValues={this.state.initialApprovalSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          var self = this;
          var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
          var auth = loginCreds.token;
          setSubmitting(true);

          axios({
            method: "post",
            url: API_LINKS.DELIVERY_ORDER_ROUTE_SENT_PATH_DRIVER_URL,
            headers: {
              Authorization: auth,
            },
            data: {
              id_delivery_order: self.state.selectedId,
              status_delivery: values.status_delivery.value,
              notes: values.notes,
            },
          })
            .then((response) => {
              if (response.status === 200) {
                if (response.data.status === "success") {
                  setSubmitting(false);
                  toast.success("DO Delivery status updated succesfully!");
                  self.toggleCloseBind();
                  resetForm();
                  self.props.fetchDeliveryOrdersTable(
                    this.props.day,
                    this.props.by_assign_date,
                    this.props.flag
                  );
                }
              }
            })
            .catch(function (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  setSubmitting(false);
                  return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 403) {
                  setSubmitting(false);
                  return toast.error(
                    "Unauthorized Access! Please try to logout.."
                  );
                } else if (error.response.status === 400) {
                  setSubmitting(false);
                  return toast.error(error.response.data.message);
                } else if (
                  error.response.status === 404 ||
                  error.response.status === 500
                ) {
                  setSubmitting(false);
                  return toast.error(
                    "Server cannot be contacted! Please ask your system administrator!"
                  );
                } else {
                  setSubmitting(false);
                  return toast.error(
                    "Something went wrong... Please try again later..."
                  );
                }
              } else if (error.request) {
                setSubmitting(false);
                return toast.error("Request error! Please try again later...");
              } else {
                setSubmitting(false);
                return toast.error(
                  "Something went wrong... Please try again later..."
                );
              }
            });
        }}
        validationSchema={validationBindSchema}
        render={({
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          handleSubmit,
          handleBlur,
          handleChange,
          isValid,
          isSubmitting,
        }) => (
          <form>
            <h5>Update status for {this.state.selectedDONumber}</h5>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">Status Delivery</Label>
              </Col>
              <Col xs="12" md="9">
                <SelectField
                  id="status_delivery"
                  name="status_delivery"
                  label="status_delivery"
                  placeholder="Select status"
                  options={deliveryStatusListData.result}
                  value={values.status_delivery}
                  isMulti={false}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  touched={touched.status_delivery}
                  error={errors.status_delivery}
                  isClearable={true}
                  backspaceRemovesValue={true}
                  disabled={isSubmitting}
                />
              </Col>
              {/* <FormFeedback>{errors.location}</FormFeedback> */}
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">Notes</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="textarea"
                  name="notes"
                  id="notes"
                  placeholder="Notes"
                  valid={!errors.notes}
                  invalid={touched.notes && !!errors.notes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.notes}
                  disabled={isSubmitting}
                />
                <FormFeedback>{errors.notes}</FormFeedback>
              </Col>
            </FormGroup>

            <FormGroup>
              <Button
                type="button"
                onClick={handleSubmit}
                color="primary"
                className="mr-1"
                disabled={isSubmitting || !isValid}
              >
                {isSubmitting ? "Wait..." : "Update Status"}
              </Button>
              <Button
                type="button"
                color="warning"
                className="mr-1"
                disabled={isSubmitting}
                onClick={this.toggleCloseBind}
              >
                Close
              </Button>
              {/* <Button type="reset" color="danger" className="mr-1" disabled={isSubmitting} onClick={handleReset}>Reset</Button> */}
            </FormGroup>
          </form>
        )}
      />
    );
  };

  getFleetsList = () => {
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    var defaultFleetsList = [];
    var self = this;

    axios({
      method: "post",
      url: API_LINKS.FLEET_LIST_URL,
      headers: {
        Authorization: auth,
      },
      data: {
        // date:
        // For Parameters
      },
    }).then(function (response) {
      if (response.data.result !== null) {
        defaultFleetsList = response.data.result.map((fleet) => {
          return fleet;
        });
        // console.log(defaultFleetsList);
        self.setState({
          fleets: defaultFleetsList,
          defaultFleetsList,
        });
      }
    });
  };

  getDriversList = () => {
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    var defaultDriversList = [];
    var self = this;

    axios({
      method: "post",
      url: API_LINKS.DRIVER_LIST_URL,
      headers: {
        Authorization: auth,
      },
      data: {
        // date:
        // For Parameters
      },
    }).then(function (response) {
      if (response.data.result !== null) {
        defaultDriversList = response.data.result.map((driver) => {
          return driver;
        });
        // console.log(defaultDriversList);
        self.setState({
          drivers: defaultDriversList,
        });
      }
    });
  };

  getDriverAssistantsList = () => {
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    var defaultDriverAssistantsList = [];
    var self = this;

    axios({
      method: "post",
      url: API_LINKS.DRIVER_ASSISTANT_LIST_URL,
      headers: {
        Authorization: auth,
      },
      data: {
        // date:
        // For Parameters
      },
    }).then(function (response) {
      if (response.data.result !== null) {
        defaultDriverAssistantsList = response.data.result.map(
          (driverAssistant) => {
            return driverAssistant;
          }
        );
        // console.log(defaultDriverAssistantsList);
        self.setState({
          driverAssistants: defaultDriverAssistantsList,
        });
      }
    });
  };

  isNormalDO = () => {
    const { currentDOData } = this.state;
    let filterFleet = this.state.fleets.filter(
      (f) => f.id === currentDOData.id_fleet
    );
    if (filterFleet.length > 0) return true;
    return false;
  };

  viewEditDO = () => {
    var self = this;
    var last10Day = moment().subtract(10, "day");
    var disabledDays = function (current) {
      return current.isAfter(last10Day);
    };
    const { selectedNumber, currEndDODate, currStartDODate, currentDOData } =
      this.state;
    return (
      <div className="container">
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">DO Number: </Label>
          </Col>
          <Col xs="12" md="9">
            <Label htmlFor="text-input">{selectedNumber}</Label>
          </Col>
        </FormGroup>
        {!this.isNormalDO() && (
          <>
            <FormGroup row>
              <Col xs="12" md="12">
                <b>Invalid DO type to edit! can only edit normal type DO.</b>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Button
                onClick={() => this.toggleCloseEditDO()}
                size="sm"
                color="success"
                disabled={this.state.iseditEndDoDate}
              >
                <i className="fa fa-window-close"></i> Close
              </Button>
            </FormGroup>
          </>
        )}
        {this.isNormalDO() && (
          <>
            {currentDOData.draft_value === 0 && (
              <>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">Driver</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <DriversList
                      isEdit={true}
                      state={{
                        drivers: this.state.drivers,
                        value: currentDOData.id_driver,
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">Driver Assistant</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <DriverAssistantsList
                      isEdit={true}
                      state={{
                        driverAssistants: this.state.driverAssistants,
                        value: currentDOData.id_driver_asst,
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">Fleet</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <FleetsList
                      isEdit={true}
                      state={{
                        fleets: this.state.fleets,
                        value: currentDOData.id_fleet,
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">
                      <b>Current DO Start Date:</b>
                    </Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Label htmlFor="text-input">
                      <b>{currentDOData.assigned_do_date}</b>
                    </Label>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">
                      Change DO Start Date into
                    </Label>
                  </Col>
                  <Col xs="12" md="9">
                    <DateTime
                      id="editStartDo"
                      input="true"
                      dateFormat="YYYY-MM-DD"
                      timeFormat="HH:mm:ss"
                      value={currStartDODate}
                      onChange={this.handleStartDateChange}
                      isValidDate={disabledDays}
                      disabled={this.state.iseditEndDoDate}
                    />
                  </Col>
                </FormGroup>
              </>
            )}
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">
                  <b>Current DO End Date:</b>
                </Label>
              </Col>
              <Col xs="12" md="9">
                <Label htmlFor="text-input">
                  <b>{currentDOData.end_assigned_do_date}</b>
                </Label>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">Change DO End Date into</Label>
              </Col>
              <Col xs="12" md="9">
                <DateTime
                  id="editEndDo"
                  input="true"
                  dateFormat="YYYY-MM-DD"
                  timeFormat="HH:mm:ss"
                  value={currEndDODate}
                  onChange={this.handleEndDateChange}
                  isValidDate={disabledDays}
                  disabled={this.state.iseditEndDoDate}
                />
              </Col>
            </FormGroup>
            {this.state.iseditEndDoDate ? (
              <center>
                <BarLoader
                  color={"#123abc"}
                  loading={this.state.iseditEndDoDate}
                />{" "}
                <br /> Loading.... Please wait...
              </center>
            ) : (
              <div>
                <Button
                  onClick={() => self.handleEditDO()}
                  size="sm"
                  color="warning"
                  disabled={this.state.iseditEndDoDate}
                >
                  <i className="fa fa-edit"></i> Edit
                </Button>
                <Button
                  onClick={() => this.toggleCloseEditDO()}
                  size="sm"
                  color="success"
                  disabled={this.state.iseditEndDoDate}
                >
                  <i className="fa fa-window-close"></i> Close
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  handleEndDateChange = (_date) => {
    var convertedDate = moment(_date).format("MM-DD-YYYY HH:mm:ss");
    const { currStartDODate } = this.state;
    if (new Date(_date) < new Date(currStartDODate)) {
      toast.error("Start DO Date Cannot Passing End DO Date!");
      return;
    }
    this.setState({
      currEndDODate: _date,
      editedEndDate: convertedDate,
    });
  };

  handleStartDateChange = (_date) => {
    const { currEndDODate } = this.state;
    if (new Date(_date) > new Date(currEndDODate)) {
      toast.error("Start DO Date Cannot Passing End DO Date!");
      return;
    }
    var convertedDate = moment(_date).format("MM-DD-YYYY HH:mm:ss");
    this.setState({
      currStartDODate: _date,
      editedStartDate: convertedDate,
    });
  };

  viewDeleteDO = () => {
    var self = this;
    const { selectedNumber, selectedDO } = this.state;
    return (
      <div className="container">
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">DO Number: </Label>
          </Col>
          <Col xs="12" md="9">
            <Label htmlFor="text-input">{selectedNumber}</Label>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs="12" md="12">
            <Label htmlFor="text-input">
              Do you want to delete this Delivery Order?{" "}
            </Label>
          </Col>
        </FormGroup>
        {this.props.inProgressDelete ? (
          <center>
            <BarLoader
              color={"#123abc"}
              loading={this.props.inProgressDelete}
            />{" "}
            <br /> Loading.... Please wait...
          </center>
        ) : (
          <div>
            <Button
              onClick={() => self.handleDelete(selectedDO)}
              size="sm"
              color="danger"
              disabled={self.props.inProgressDelete}
            >
              <i className="fa fa-trash"></i> Submit
            </Button>
            <Button
              onClick={() => this.toggleCloseDelete()}
              size="sm"
              color="success"
              disabled={self.props.inProgressDelete}
            >
              <i className="fa fa-window-close"></i> Close
            </Button>
          </div>
        )}
      </div>
    );
  };

  viewSendPath = () => {
    var self = this;
    const { selectedDONumber, selectedId } = this.state;
    return (
      <div className="container">
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">DO Number: </Label>
          </Col>
          <Col xs="12" md="9">
            <Label htmlFor="text-input">{selectedDONumber}</Label>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs="12" md="12">
            <Label htmlFor="text-input">
              Do you want to send route to the driver?{" "}
            </Label>
          </Col>
        </FormGroup>
        {this.state.isSending ? (
          <center>
            <BarLoader color={"#123abc"} loading={this.state.isSending} />{" "}
            <br /> Loading.... Please wait...
          </center>
        ) : (
          <div>
            <Button
              onClick={() => self.handleSend(selectedId)}
              size="sm"
              color="success"
              disabled={this.state.isSending}
            >
              <i className="fa fa-mail"></i> Send
            </Button>
            <Button
              onClick={() => this.toggleCloseSendPath()}
              size="sm"
              color="warning"
              disabled={this.state.isSending}
            >
              <i className="fa fa-window-close"></i> Close
            </Button>
          </div>
        )}
      </div>
    );
  };

  bindDOToSecurity = () => {
    this.setState({
      isApproving: true,
    });
    var self = this;
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    //Variables for text input
    var _selectedDO = this.state.selectedId;

    if (_selectedDO === "" || _selectedDO === null || _selectedDO === 0) {
      self.setState({ isApproving: false });
      toast.error("Selected DO is Invalid!");
    } else {
      axios({
        method: "post",
        url: API_LINKS.DELIVERY_APPROVE_URL, //Please change when it's ready
        headers: {
          Authorization: auth,
        },
        data: {
          id_delivery_order: _selectedDO,
          // id_security: _selectedSecurity
        },
      })
        .then((response) => {
          // console.log(response)
          if (response.status === 200) {
            if (response.data.status === "success") {
              self.setState({
                isApproving: false,
              });
              toast.success(
                "DO " + self.state.selectedDONumber + " has been approved!"
              );
              self.toggleCloseBind();
              self.props.fetchDeliveryOrdersTable(
                this.props.day,
                this.props.by_assign_date,
                this.props.flag
              );
            }
          }
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 401) {
              self.setState({
                isApproving: false,
              });
              return toast.error(error.response.data.message);
            } else if (error.response.status === 403) {
              self.setState({
                isApproving: false,
              });
              return toast.error(error.response.data.message);
            } else if (error.response.status === 400) {
              self.setState({
                isApproving: false,
              });
              return toast.error(error.response.data.message);
            } else if (
              error.response.status === 404 ||
              error.response.status === 500
            ) {
              self.setState({
                isApproving: false,
              });
              return toast.error(
                "Server cannot be contacted! Please ask your system administrator!"
              );
            } else {
              self.setState({
                isApproving: false,
              });
              return toast.error(
                "Something went wrong... Please try again later..."
              );
            }
          } else if (error.request) {
            self.setState({
              isApproving: false,
            });
            return toast.error("Request error! Please try again later...");
          } else {
            self.setState({
              isApproving: false,
            });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        });
    }
  };

  handleSend = () => {
    this.setState({
      isSending: true,
    });
    var self = this;
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    //Variables for text input
    var _selectedDO = this.state.selectedId;

    if (_selectedDO === "" || _selectedDO === null || _selectedDO === 0) {
      self.setState({ isSending: false });
      toast.error("Selected DO is Invalid!");
    } else {
      axios({
        method: "post",
        url: API_LINKS.DELIVERY_ORDER_ROUTE_SENT_PATH_DRIVER_URL, //Please change when it's ready
        headers: {
          Authorization: auth,
        },
        data: {
          id_delivery_order: _selectedDO,
          // id_security: _selectedSecurity
        },
      })
        .then((response) => {
          // console.log(response)
          if (response.status === 200) {
            if (response.data.status === "success") {
              self.setState({
                isSending: false,
              });
              toast.success(
                "DO " +
                  self.state.selectedDONumber +
                  " path has been sent to the driver! Please wait for a while..."
              );
              self.toggleCloseSendPath();
              self.props.fetchDeliveryOrdersTable(
                this.props.day,
                this.props.by_assign_date,
                this.props.flag
              );
            }
          }
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 401) {
              self.setState({
                isSending: false,
              });
              return toast.error(error.response.data.message);
            } else if (error.response.status === 403) {
              self.setState({
                isSending: false,
              });
              return toast.error(error.response.data.message);
            } else if (error.response.status === 400) {
              self.setState({
                isSending: false,
              });
              return toast.error(error.response.data.message);
            } else if (
              error.response.status === 404 ||
              error.response.status === 500
            ) {
              self.setState({
                isSending: false,
              });
              return toast.error(
                "Server cannot be contacted! Please ask your system administrator!"
              );
            } else {
              self.setState({
                isSending: false,
              });
              return toast.error(
                "Something went wrong... Please try again later..."
              );
            }
          } else if (error.request) {
            self.setState({
              isSending: false,
            });
            return toast.error("Request error! Please try again later...");
          } else {
            self.setState({
              isSending: false,
            });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        });
    }
  };

  handleEditDO = () => {
    this.setState({ iseditEndDoDate: true });
    const { currentDOData } = this.state;
    var self = this;
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    var _id = self.state.selectedDO;
    var _startDate = self.state.editedStartDate;
    var _endDate = self.state.editedEndDate;
    var _fleetSelect =
      currentDOData.draft_value === 0
        ? document.querySelector("#addFleetSelect").value
        : null;
    var _driverSelect =
      currentDOData.draft_value === 0
        ? document.querySelector("#addDriverSelect").value
        : null;
    var _driverAssistantSelect =
      currentDOData.draft_value === 0
        ? document.querySelector("#addDriverAssistantSelect").value
        : null;

    if (_id === null) {
      self.setState({ iseditEndDoDate: false });
      return toast.error("Invalid ID! Please re-open this window!");
    } else if (_startDate === "" || _startDate === null) {
      self.setState({ iseditEndDoDate: false });
      toast.error("DO Start Date is invalid!");
    } else if (_endDate === "" || _endDate === null) {
      self.setState({ iseditEndDoDate: false });
      toast.error("DO End Date is invalid!");
    } else {
      axios({
        method: "post",
        url: API_LINKS.DELIVERY_ORDERS_EDIT_URL,
        headers: {
          Authorization: auth,
        },
        data: {
          id: _id,
          id_fleet: _fleetSelect,
          id_driver: _driverSelect,
          id_driver_asst: _driverAssistantSelect,
          assigned_do_date: _startDate,
          end_assigned_do_date: _endDate,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === "success") {
              toast.success("Do edited!");
              self.toggleCloseEditDO();
              self.props.fetchDeliveryOrdersTable(
                this.props.day,
                this.props.by_assign_date,
                this.props.flag
              );
            }
          }
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 401) {
              self.setState({ iseditEndDoDate: false });
              return toast.error("Invalid credentials! Please try again!");
            } else if (
              error.response.status === 404 ||
              error.response.status === 500
            ) {
              self.setState({ iseditEndDoDate: false });
              return toast.error(
                "Server cannot be contacted! Please ask your system administrator!"
              );
            } else if (error.response.status === 400) {
              self.setState({ iseditEndDoDate: false });
              return toast.error(error.response.data.message);
            } else {
              self.setState({ iseditEndDoDate: false });
              return toast.error(
                "Something went wrong... Please try again later..."
              );
            }
          } else if (error.request) {
            self.setState({ iseditEndDoDate: false });
            return toast.error("Request error! Please try again later...");
          } else {
            self.setState({ iseditEndDoDate: false });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        });
    }
  };

  handleDelete = (_id) => {
    this.props.fetchDeleteItem("DO", _id);
    this.toggleCloseDelete();
    sleep(500).then(() => {
      // Do something after the sleep!
      this.props.fetchDeliveryOrdersTable(
        this.props.day,
        this.props.by_assign_date,
        this.props.flag
      );
    });
  };

  avoidTollFormat = (cell, row) => {
    if (row.avoid_toll === 1) {
      return "Yes";
    } else {
      return "No";
    }
  };

  isExpandableRow = () => {
    return true;
  };

  expandComponent = (row) => {
    return (
      <div>
        <DeliveryOrdersDetail
          company={row.company}
          do_number={row.do_number}
          do_desc={row.do_desc}
          fleet_plate={row.fleet_plate}
          max_tonnage={row.max_tonnage}
          driver_name={row.driver_name}
          driver_phone={row.driver_phone}
          security_name={row.security_name}
          driver_assistant_name={row.driver_assistant_name}
          assigned_do_date={row.assigned_do_date}
          end_assigned_do_date={row.end_assigned_do_date}
          avoid_toll={row.avoid_toll}
          status_delivery_detail={row.status_delivery_detail}
          notes={row.notes}
          total_est_delivery_cost={row.total_est_delivery_cost}
          total_est_carbon={row.total_est_carbon}
          created_on={row.created_on}
          other_fee={row.other_fee}
          toll_fee={row.toll_fee}
          weight_fee={row.weight_fee}
          link_lapor={row.link_lapor}
          each_item_weight={row.each_item_weight}
        />
        {/* </div> */}
      </div>
    );
  };

  idFormatter = (cell) => {
    return <div style={{ whiteSpace: "pre-line" }}>{cell}</div>;
  };

  imageFormatter = (cell) => {
    let imageLink = cell;
    if (!imageLink)
      imageLink = process.env.PUBLIC_URL + "/assets/img/photo_not_found.png";
    return <img width="72" height="70" src={imageLink} />;
  };

  lockAuditFormatter = (cell, row) => {
    let badge = <Badge color="warning">Draft</Badge>;
    let tripBadge = <Badge color="primary">On Going</Badge>;
    if (row.draft_value === 0) {
      badge = <Badge color="primary">On Going</Badge>;
      if (cell === 1) badge = <Badge color="danger">Ready to Audit</Badge>;
      if (cell === 2) badge = <Badge color="success">Audited</Badge>;
      if (cell === 3) badge = <Badge color="success">Finished</Badge>;
    }
    if (row.close_status === 1) {
      tripBadge = <Badge color="success">Finished</Badge>;
    }
    return (
      <div style={{ textAlign: "center" }}>
        <h6>Audit: {badge}</h6>
        <h6>Trip: {tripBadge}</h6>
      </div>
    );
  };

  showDataTable = () => {
    return (
      <>
        <TableV2
          option={{
            defaultSort:{
              by: "assigned_do_date",
              order: "desc"
            }
          }}
          name={"Delivery_Order"}
          data={this.props.deliveryOrdersTableData.result}
          renderExpendRow={this.expandComponent}
          columns={[
            {
              field: "id",
              name: "ID",
              sortable: true,
              searchable: true,
              renderFormat: this.idFormatter.bind(this),
            },
            {
              name: "Company",
              field: "company",
              sortable: true,
              searchable: true,
              hidden: this.context.can(
                "view",
                "company_field@delivery_orders_datatable"
              )
                ? false
                : true,
            },
            {
              field: "id_fleet",
              name: "ID Fleet",
              sortable: true,
              searchable: true,
              hidden: true,
            },
            {
              field: "do_number",
              name: "DO Number",
              sortable: true,
              searchable: true,
            },
            {
              field: "lock_audit",
              name: "Status",
              sortable: true,
              searchable: true,
              renderFormat: this.lockAuditFormatter.bind(this),
            },
            {
              field: "do_desc",
              name: "DO Desc",
              sortable: true,
              searchable: true,
            },
            {
              field: "fleet_plate",
              name: "Fleet Plate",
              sortable: true,
              searchable: true,
            },
            {
              field: "id_driver",
              name: "ID Driver",
              sortable: true,
              searchable: true,
              hidden: true,
            },
            {
              field: "driver_name",
              name: "Driver Name",
              sortable: true,
              searchable: true,
            },
            {
              field: "driver_photo_link",
              name: "Driver Photo",
              sortable: true,
              renderFormat: this.imageFormatter.bind(this),
            },
            {
              field: "id_driver_assistant",
              name: "ID Driver Assistant",
              sortable: true,
              searchable: true,
              hidden: true,
            },
            {
              field: "driver_assistant_name",
              name: "Driver Assistant Name",
              sortable: true,
              searchable: true,
              hidden: true,
            },
            {
              field: "driver_assistant_link",
              name: "Driver Assistant Photo",
              renderFormat: this.imageFormatter.bind(this),
              hidden: true,
            },
            {
              field: "assigned_do_date",
              name: "DO Start Date",
              sortable: true,
              searchable: true,
            },
            {
              field: "end_assigned_do_date",
              name: "DO End Date",
              sortable: true,
              searchable: true,
            },
            {
              field: "security_name",
              name: "Security Name",
              sortable: true,
              searchable: true,
            },

            {
              name: "avoid_toll",
              field: "Avoid Toll",
              renderFormat: this.avoidTollFormat.bind(this),
              hidden: true,
            },

            {
              field: "created_on",
              name: "DO Issued On",
              sortable: true,
              searchable: true,
            },

            {
              field: "work_order_count",
              name: "WO Total",
              sortable: true,
              searchable: true,
            },

            {
              field: "work_order_real_distance",
              name: "KM DO Realization",
              sortable: true,
              searchable: true,
              renderFormat: (cell) => cell / 1000,
            },
            {
              name: "Category",
              field: "categorys",
              searchable: true,
              renderFormat: (cell) => (
                <>
                  {cell ? (
                    <div style={{ "white-space": "pre-line" }}>
                      {cell.name} &raquo; {cell.subcategory.name}
                    </div>
                  ) : (
                    <>-</>
                  )}
                </>
              ),
            },
            {
              name: "Action",
              renderFormat: this.ActionFormatter.bind(this),
            },
          ]}
        />
      </>
    );
  };

  render() {
    return (
      <div className="animated fadeIn">
        {this.props.inProgress ? (
          <center>
            <BarLoader color={"#123abc"} loading={this.props.inProgress} />{" "}
            <br /> Loading.... Please wait...
          </center>
        ) : this.props.successDeliveryOrdersFetch ? (
          this.showDataTable()
        ) : (
          <center>
            Error fetching your data... <br />
            <br />
            <Button
              className="btn btn-primary"
              color="primary"
              size="md"
              disabled={this.props.inProgress}
              onClick={() =>
                this.props.fetchDeliveryOrdersTable(
                  this.props.day,
                  this.props.by_assign_date,
                  this.props.flag
                )
              }
            >
              <i className="fa fa-refresh"></i>
              Try again
            </Button>
          </center>
        )}

        {/* Modal for Adding Cards */}
        <Modal
          isOpen={this.state.modalBind}
          toggle={this.toggleOpenBind}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleCloseBind}>
            DO Approval for : {this.state.selectedDONumber}
          </ModalHeader>
          <ModalBody>{this.viewBindSecurity()}</ModalBody>
        </Modal>

        {/* Modal for Edit DO End Date */}
        <Modal
          isOpen={this.state.modalEditDO}
          toggle={this.toggleOpenEditDO}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleCloseEditDO}>
            Edit DO <b>{this.state.selectedDO}</b>
          </ModalHeader>
          <ModalBody>{this.viewEditDO()}</ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.modalDoCopy}
          toggle={this.toggleOpenCopyDO}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleCloseCopyDO}>
            Copy DO {this.state.selectedDO}
          </ModalHeader>
          <ModalBody>
            <DOCopyForm
              fetchRefresh={() =>
                this.props.fetchDeliveryOrdersTable(
                  this.props.day,
                  this.props.by_assign_date,
                  this.props.flag
                )
              }
              initialValues={this.state.initialValueCOpyDO}
              closeForm={this.toggleCloseCopyDO}
            />
          </ModalBody>
        </Modal>

        {/* Modal for Delete DO */}
        <Modal
          isOpen={this.state.modalDelete}
          toggle={this.toggleOpenDelete}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleCloseDelete}>
            Delete DO: {this.state.selectedDO}
          </ModalHeader>
          <ModalBody>{this.viewDeleteDO()}</ModalBody>
        </Modal>

        {/* Modal for Sent DO */}
        <Modal
          isOpen={this.state.modalSendPath}
          toggle={this.toggleOpenSendPath}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleCloseSendPath}>
            Sent DO {this.state.selectedDONumber} path to driver?
          </ModalHeader>
          <ModalBody>{this.viewSendPath()}</ModalBody>
        </Modal>

        <Modal
          isOpen={!!this.state.modalBindCategory}
          toggle={() => this.setState({ modalBindCategory: null })}
          className={this.props.className}
        >
          <ModalHeader
            toggle={() =>
              this.setState({
                modalBindCategory: null,
              })
            }
          >
            Bind with subcategory
          </ModalHeader>
          <ModalBody>
            {!!this.state.modalBindCategory ? (
              <BindWithSubcategory
                dataDo={this.state.modalBindCategory}
                onClose={() =>
                  this.setState({
                    modalBindCategory: null,
                  })
                }
                refetch={() =>
                  this.props.fetchDeliveryOrdersTable(
                    this.props.day,
                    this.props.by_assign_date,
                    this.props.flag
                  )
                }
              />
            ) : null}
          </ModalBody>
        </Modal>

        <Modal
          isOpen={!!this.state.modalDeleteCategory}
          toggle={() => this.setState({ modalDeleteCategory: null })}
          className={this.props.className}
        >
          <ModalHeader
            toggle={() =>
              this.setState({
                modalDeleteCategory: null,
              })
            }
          >
            Unbind subcategory
          </ModalHeader>
          <ModalBody>
            {!!this.state.modalDeleteCategory ? (
              <UnbindSubcategory
                dataDo={this.state.modalDeleteCategory}
                onClose={() =>
                  this.setState({
                    modalDeleteCategory: null,
                  })
                }
                refetch={() =>
                  this.props.fetchDeliveryOrdersTable(
                    this.props.day,
                    this.props.by_assign_date,
                    this.props.flag
                  )
                }
              />
            ) : null}
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.modalCheckSheetBinding}
          toggle={this.toggleOpenCheckSheetBinding}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleCloseCheckSheetBinding}>
            Check Sheet Binding to DO {this.state.selectedDONumber}
          </ModalHeader>
          <ModalBody>
            <DOCheckSheetBinding
              initialValues={{ id_do: this.state.id_do }}
              closeForm={this.toggleCloseCheckSheetBinding}
              refresh={this.refreshDOTable}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deliveryOrdersTableData:
      state.deliveryOrdersTableStore.deliveryOrdersTableData,
    inProgress: state.deliveryOrdersTableStore.inProgress,
    successDeliveryOrdersFetch: state.deliveryOrdersTableStore.success,

    // Delivery Status
    deliveryStatusListData:
      state.deliveryStatusListStore.deliveryStatusListData,
    inProgressDelivery: state.deliveryStatusListStore.inProgress,
    successDeliveryStatus: state.deliveryStatusListStore.success,

    securityTableData: state.securityTableStore.securityTableData,
    deleteItemData: state.deleteStore.deleteItemData,
    inProgressDelete: state.deleteStore.inProgress,
  };
};

DeliveryOrdersDatatable.propTypes = propTypes;
DeliveryOrdersDatatable.defaultProps = defaultProps;
DeliveryOrdersDatatable.contextType = AbilityContext;

export default connect(mapStateToProps, {
  fetchDeliveryOrdersTable,
  fetchSecurityTable,
  fetchDeleteItem,
  fetchDeliveryStatusList,
})(DeliveryOrdersDatatable);
