import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Alert, Button, Col, FormGroup, Input, Label } from "reactstrap";
import * as API_LINKS from "../../../redux/config/links";
import { toast } from "react-toastify";
import isValidCoordinates from "is-valid-coordinates";
import { AbilityContext } from "../../../casl/Can";
import { BarLoader } from "react-spinners";
import MapWithAMarkerB from "./MapWithAMarkerB";
import axios from "axios";
import styles from "./SavedPlaces.module.scss";

const SavedPlacesAddForm = ({ toggleCloseAdd, refetch, ...props }) => {
  const { mainCustomerSelect, kml, statKML } = props;
  const [countDown, setCountDown] = useState(0);
  const [isFetchingAddress, setIsFetchingAddress] = useState(false);
  const [images, setImages] = useState([]);

  const [form, setForm] = useState({
    selectedCustomer: null,
    name_id: "",
    lat: "0",
    lng: "0",
    radius: 0,
    type_map: 1,
    address: "",
    addPlaceName: "",
  });

  const [isAdding, setIsAdding] = useState(false);

  const inputFileRef = useRef(null);

  const handleChangeCustomer = (value) => {
    setForm({ ...form, selectedCustomer: value });
  };

  const handleChangeLat = (e) => {
    setCountDown(3);
    setForm({ ...form, lat: e.target.value });
  };

  const handleChangeLng = (e) => {
    setCountDown(3);
    setForm({ ...form, lng: e.target.value });
  };

  const uploadMultipleImage = (allimages, curIndex, id, auth, cb) => {
    const fd = new FormData();
    fd.append("file", allimages[curIndex].file);
    fd.append("image_desc", "");
    fd.append("id", id);
    const newIndex = curIndex + 1;

    axios({
      method: "post",
      url: API_LINKS.SAVED_PLACES_UPLOAD_IMAGE, //Please change when it's ready
      headers: {
        Authorization: auth,
      },
      data: fd,
    })
      .then(() => {
        if (allimages[newIndex]) {
          return uploadMultipleImage(allimages, newIndex, id, auth, cb);
        } else {
          return cb();
        }
      })
      .catch(() => {
        if (allimages[newIndex]) {
          return uploadMultipleImage(allimages, newIndex, id, auth, cb);
        } else {
          return cb();
        }
      });
  };

  const handleAdd = () => {
    setIsAdding(true);
    const loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    const auth = loginCreds.token;

    //Variables for text input
    const _placesName = form.addPlaceName;
    const _nameID = form.name_id;
    const _radius = form.radius;
    const _typeMap = form.type_map;
    const _address = form.address;
    const _lat = form.lat;
    const _lng = form.lng;
    let _idCustomer;

    // eslint-disable-next-line
    if (
      form.selectedCustomer == null ||
      form.selectedCustomer === undefined ||
      form.selectedCustomer === ""
    ) {
      setIsAdding(false);
      return toast.error("Main Customer should be selected!");
    } else {
      _idCustomer = form.selectedCustomer.value;
    }

    if (_placesName === "" || _placesName === null) {
      setIsAdding(false);
      toast.error("Place name is invalid!");
    } else if (_nameID === "" || _nameID === null) {
      setIsAdding(false);
      toast.error("Name ID is invalid!");
    } else if (_lat === "" || _lat === null) {
      setIsAdding(false);
      toast.error("Invalid latitude coordinate!");
    } else if (_lng === "" || _lng === null) {
      setIsAdding(false);
      toast.error("Invalid longitude coordinate!");
    } else if (isValidCoordinates(_lng, _lat)) {
      setIsAdding(false);
      toast.error("Invalid map coordinate!");
    } else if (_radius === "" || _radius === null) {
      setIsAdding(false);
      toast.error("Radius is invalid!");
    } else if (_typeMap === "" || _typeMap === null) {
      setIsAdding(false);
      toast.error("Type Map is invalid!");
    } else {
      axios({
        method: "post",
        url: API_LINKS.SAVED_PLACES_ADD_URL, //Please change when it's ready
        headers: {
          Authorization: auth,
        },
        data: {
          name: _placesName,
          name_id: _nameID,
          lat: _lat,
          lng: _lng,
          radius: _radius,
          type_map: _typeMap,
          id_customer: _idCustomer,
          address: _address,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            setIsAdding(false);
            if (response.data.status === "success") {
              if (images.length !== 0) {
                uploadMultipleImage(
                  images,
                  0,
                  response.data.result.id,
                  auth,
                  () => {
                    toast.success("Data saved!");
                    toggleCloseAdd();
                    refetch();
                  }
                );
              } else {
                toast.success("Data saved!");
                toggleCloseAdd();
                refetch();
              }
            }
          }
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 401) {
              setIsAdding(false);
              return toast.error("Invalid credentials! Please try again!");
            } else if (
              error.response.status === 404 ||
              error.response.status === 500
            ) {
              setIsAdding(false);
              return toast.error(
                "Server cannot be contacted! Please ask your system administrator!"
              );
            } else if (error.response.status === 400) {
              setIsAdding(false);
              return toast.error(error.response.data.message);
            } else {
              setIsAdding(false);
              return toast.error(
                "Something went wrong... Please try again later..."
              );
            }
          } else if (error.request) {
            setIsAdding(false);
            return toast.error("Request error! Please try again later...");
          } else {
            setIsAdding(false);
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        });
    }
  };

  useEffect(() => {
    setInterval(() => {
      setCountDown((prev) => prev - 1);
    }, 1000);
  }, []);

  useEffect(() => {
    if (countDown === 0) {
      setIsFetchingAddress(true);
    }
  }, [countDown]);

  useEffect(() => {
    if (isFetchingAddress) {
      const loginCreds = JSON.parse(window.atob(localStorage.Automalt));
      const auth = loginCreds.token;
      axios({
        method: "post",
        url: API_LINKS.SAVED_PLACES_GET_ADDRESS, //Please change when it's ready
        headers: {
          Authorization: auth,
        },
        data: {
          lat: form.lat,
          lng: form.lng,
        },
      })
        .then((res) => {
          if (res.data.result.length !== 0) {
            setForm({ ...form, address: res.data.result[0].formatted_address });
          }
          setIsFetchingAddress(false);
        })
        .catch(() => {
          setIsFetchingAddress(false);
        });
    }
  }, [isFetchingAddress, form, API_LINKS]);

  return (
    <>
      <div className="container animated fadeIn">
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Main Customer</Label>
          </Col>
          <Col xs="12" md="9">
            <Select
              value={form.selectedCustomer}
              onChange={handleChangeCustomer}
              options={mainCustomerSelect}
              disabled={isAdding}
              required
            />
          </Col>
        </FormGroup>
        {form.selectedCustomer ? (
          <div>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">Customer Main address</Label>
              </Col>
              <Col xs="12" md="9">
                <Label htmlFor="text-input">
                  {form.selectedCustomer.address}
                </Label>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">Customer Phone Number</Label>
              </Col>
              <Col xs="12" md="9">
                <Label htmlFor="text-input">
                  {form.selectedCustomer.handphone}
                </Label>
              </Col>
            </FormGroup>
          </div>
        ) : null}
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Name ID</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="text-input"
              id="name_id"
              placeholder="Enter Name ID"
              disabled={isAdding}
              value={form.name_id}
              onChange={(e) => setForm({ ...form, name_id: e.target.value })}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Places Name</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="text-input"
              id="addPlaceName"
              placeholder="ex: Warehouse 1"
              disabled={isAdding}
              value={form.addPlaceName}
              onChange={(e) =>
                setForm({ ...form, addPlaceName: e.target.value })
              }
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Latitude</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="number"
              name="text-input"
              step="0.00001"
              placeholder="ex: -4.127346"
              disabled={isAdding}
              value={`${form.lat}`}
              onChange={handleChangeLat}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Longitude</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="number"
              name="text-input"
              step="0.00001"
              placeholder="ex: 38.575078"
              disabled={isAdding}
              value={`${form.lng}`}
              onChange={handleChangeLng}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="type_map">Type</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="select"
              name="type_map"
              id="type_map"
              onChange={(e) => setForm({ ...form, type_map: e.target.value })}
              value={form.type_map}
              disabled={isAdding}
            >
              <option value="1">Circle</option>
              <option value="2">Polygon</option>
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="radius">Radius</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="number"
              name="text-input"
              step="0.00001"
              placeholder="200"
              disabled={isAdding}
              value={`${form.radius}`}
              onChange={(e) => setForm({ ...form, radius: e.target.value })}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Address</Label>
          </Col>
          <Col xs="12" md="9">
            {isFetchingAddress ? (
              <center>
                <BarLoader color={"#123abc"} loading={isFetchingAddress} />
              </center>
            ) : (
              <Input
                type="text"
                name="address"
                disabled={true}
                value={form.address}
              />
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Image</Label>
          </Col>
          <Col xs="12" md="9">
            <input
              type="file"
              hidden
              ref={inputFileRef}
              accept="image/*"
              onChange={(e) => {
                console.log(e.currentTarget.files);
                const [file] = e.currentTarget.files;
                if (file) {
                  setImages([
                    ...images,
                    { file, url: URL.createObjectURL(file) },
                  ]);
                }
              }}
            />
            <div className={styles["image-container"]}>
              {images.map((item, key) => (
                <div className={styles["image-item-container"]} key={key}>
                  <img className={styles["image-item"]} src={item.url} alt="" />
                  <div
                    className={styles["image-close"]}
                    onClick={() =>
                      setImages([...images.filter((_, index) => index !== key)])
                    }
                  >
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </div>
                </div>
              ))}
              <div
                className={styles["add-image"]}
                onClick={() => inputFileRef.current.click()}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
              </div>
            </div>
          </Col>
        </FormGroup>
        <br />
        <Alert color="primary">
          <strong>Maps Plot</strong>
        </Alert>
        <FormGroup row>
          <Col xs="12" md="12">
            <MapWithAMarkerB
              lat={Number(form.lat)}
              lng={Number(form.lng)}
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCmIATqobqbCEU90nYtwd-PzCcj8EVOlDg&v=3.exp&libraries=geometry,drawing,places"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `200px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              kmlLink={statKML ? kml : ""}
            />
          </Col>
        </FormGroup>
        {isAdding ? (
          <center>
            <BarLoader color={"#123abc"} loading={isAdding} /> <br />{" "}
            Loading.... Please wait...
          </center>
        ) : (
          <div>
            <Button
              onClick={() => handleAdd()}
              size="sm"
              color="primary"
              disabled={isAdding || isFetchingAddress}
            >
              <i className="fa fa-dot-circle-o"></i> Submit
            </Button>
            <Button
              onClick={() => toggleCloseAdd()}
              size="sm"
              color="success"
              disabled={isAdding}
            >
              <i className="fa fa-window-close"></i> Close
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    kml: state.companyStore.companyKML,
    statKML: state.settingStore.kmlSet,
    mainCustomerSelect: state.mainCustomerStore.mainCustomerSelect,
  };
};

SavedPlacesAddForm.contextType = AbilityContext;
export default connect(mapStateToProps, {})(SavedPlacesAddForm);
