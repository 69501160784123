// import {Button, ButtonGroup} from 'reactstrap';
import { useState } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
// import { AbilityContext } from "../../../../casl/Can";
// import moment from "moment";
// import { fileNameFormatWithType } from "../../../../helpers/helper";
// import ExcelJS from "exceljs";
// import { saveAs } from "file-saver";
// import { FaDownload } from "react-icons/fa";
import { Button, ButtonGroup, Modal, ModalBody, ModalHeader } from "reactstrap";
import { bootstrapTableOption, fileNameFormat } from "src/helpers/helper";
import LoadingContent from "src/components/LoadingContent/LoadingContent";
import AssetTrackingAddEdit from "./AssetsTrackingAddEdit";
import { FaCircle } from "react-icons/fa";

export default function AssetsTrackingDatatable({ fleetData, loadingFleetList, onReload }) {
  const [modalOpenCreate, setModalOpenCreate] = useState(false);
  const [modalOpenEdit, setModalOpenEdit] = useState(null);
  let counter = 1;

  const assetNameFormat = () => {
    return `Asset-${counter++}`;
  };

  return (
    <>
      <LoadingContent
        loading={loadingFleetList}
        onReload={() => onReload(true)}
      >
        <BootstrapTable
          data={fleetData}
          version="4"
          bordered={false}
          hover
          pagination
          search
          edit
          options={bootstrapTableOption({
            defaultSortName: "id",
            defaultSortOrder: "asc",
            btnGroup: (props) => (
              <div>
                {props.exportCSVBtn}{" "}
                <Button color={"secondary"} onClick={() => {}}>
                  <i className="fa fa-refresh"></i> Reload
                </Button>{" "}
                <Button
                  color={"primary"}
                  onClick={() => {
                    setModalOpenCreate(true);
                  }}
                >
                  <i className="fa fa-plus"></i> Add Asset
                </Button>
              </div>
            ),
          })}
          exportCSV={true}
          csvFileName={fileNameFormat("Live Monitoring - Assets Tracking")}
          multiColumnSearch
        >
          <TableHeaderColumn
            dataField="id"
            isKey
            dataSort
            csvHeader="ID"
            hidden
          >
            ID
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="asset_name"
            dataSort
            csvHeader="Asset Name"
            dataFormat={assetNameFormat}
          >
            Asset Name
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="car_type"
            dataSort
            csvHeader="Asset Type"
          >
            Asset Type
          </TableHeaderColumn>
          <TableHeaderColumn dataField="car_brands" dataSort csvHeader="Brand">
            Brand
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="position"
            dataSort
            csvHeader="Position"
            tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          >
            Position
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="obd_code"
            dataSort
            csvHeader="IMEI Code"
          >
            IMEI Code
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="reg_number"
            dataSort
            csvHeader="Registration Number"
            width="15%"
          >
            Registration Number
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="notes"
            dataSort
            csvHeader="Notes"
            width="15%"
          >
            Notes
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="action"
            export={false}
            dataFormat={(cell, row) => (
              <>
                <FaCircle style={{ color: "green", fontSize: "14px" }} />
                <ButtonGroup>
                  <Button
                    className="btn btn-info me-1 ms-2"
                    color="info"
                    onClick={() => setModalOpenEdit({ ...row })}
                  >
                    <i className="fa fa-pencil"></i> Edit
                  </Button>
                </ButtonGroup>
              </>
            )}
          >
            Action
          </TableHeaderColumn>
        </BootstrapTable>
      </LoadingContent>
      {/* Modal for Edit Asset */}
      <Modal
        size="md"
        isOpen={!!modalOpenEdit}
        toggle={() => setModalOpenEdit(null)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalOpenEdit(null)}>
          Edit Asset
        </ModalHeader>
        <ModalBody>
          <AssetTrackingAddEdit
            isEdit
            initialValues={modalOpenEdit}
            onClose={() => setModalOpenEdit(null)}
            onReload={() => onReload(true)}
          />
        </ModalBody>
      </Modal>

      {/* Modal Create */}
      <Modal
        size="md"
        isOpen={!!modalOpenCreate}
        toggle={() => setModalOpenCreate(false)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalOpenCreate(false)}>
          Add Asset
        </ModalHeader>
        <ModalBody>
          <AssetTrackingAddEdit
            onClose={() => setModalOpenCreate(false)}
            onReload={() => onReload(true)}
          />
        </ModalBody>
      </Modal>
    </>
  );
}