import {
  menuWithoutIcon as carbonMenu,
  menuOnlyParentsWithIcon as carbonMenuWithIcon,
} from "./constants/carbon-constants";
import { activeFeature } from "./constants/constants";

export default {
  items: process.env.IS_PLATFORM
    ? [
        {
          name: "Home",
          url: "/welcome",
          icon: "home icon",
        },
        // {
        //   name: 'Enviroma Devices',
        //   url: '/enviroma/devices',
        //   icon: 'fa fa-microchip',
        // },
        // {
        //   name: 'Temperature',
        //   url: '/enviroma/temperature',
        //   icon: 'fa fa-thermometer-three-quarters',
        // },
        // {
        //   name: 'Air Pressure',
        //   url: '/airpressure',
        //   icon: 'fa fa-cloud',
        // },
        // {
        //   name: 'Humidity',
        //   url: '/humidity',
        //   icon: 'fa fa-tint',
        // },
        // {
        //   name: 'Alerts',
        //   url: '/alerts',
        //   icon: 'fa fa-bell',
        // },
        {
          name: "ConnectX",
          url: "/stocker",
          icon: "box icon",
          badge: {
            variant: "warning",
            text: "beta",
          },
          children: [
            {
              name: "My Stock",
              url: "/stocker/mystock",
              // icon: "fa fa-cubes",
              badge: {
                variant: "info",
                text: "▼",
              },
              children: [
                {
                  name: "Administrative Offices",
                  url: "/stocker/administrative-offices",
                  // icon: "fa fa-university",
                },
                {
                  name: "Batchs",
                  url: "/stocker/batchs",
                  // icon: "fa fa-sitemap",
                },
                {
                  name: "Brands",
                  url: "/stocker/brands",
                  // icon: "fa fa-bold",
                },
                {
                  name: "Company Bank Accounts",
                  url: "/stocker/company-bank-accounts",
                  // icon: "fa fa-id-card",
                },
                {
                  name: "Divisions",
                  url: "/stocker/divisions",
                  // icon: "fa fa-id-card",
                },
                {
                  name: "Operating Areas",
                  url: "/stocker/operating-areas",
                  // icon: "fa fa-map",
                },
                {
                  name: "Repackagings",
                  url: "/stocker/repacakaging",
                  // icon: "fa fa-archive",
                },
                {
                  name: "Items",
                  url: "/stocker/items",
                  // icon: "boxes icon",
                },
                {
                  name: "Item Settings",
                  url: "/stocker/item-settings",
                  // icon: "cog icon",
                },
                {
                  name: "Vendors",
                  url: "/stocker/vendors",
                  // icon: "fa fa-handshake-o",
                },
                {
                  name: "Opname",
                  url: "/stocker/opname/",
                  // icon: "fa fa-sort-numeric-asc",
                },
                {
                  name: "PIC Position",
                  url: "/stocker/pic-positions",
                  // icon: "fa fa-arrow-down",
                },
                {
                  name: "Warehouse",
                  url: "/stocker/warehouse",
                  // icon: "fa fa-industry",
                },
                // {
                //   name: 'Warehouse Transfer',
                //   url: '/stocker/warehouse-transfer',
                //   icon: 'fa fa-exchange',
                // },
                {
                  name: "Rack Management",
                  url: "/stocker/rack-management",
                  // icon: "fa fa-table",
                },
              ],
            },
            {
              name: "My Bound",
              url: "/stocker/mybound/",
              // icon: "fa fa-arrows-alt",
              badge: {
                variant: "info",
                text: "▼",
              },
              children: [
                //Inbounds
                {
                  name: "Inbounds",
                  url: "/stocker/inbounds",
                  // icon: "fa fa-level-down",
                },

                {
                  name: "Outbounds",
                  url: "/stocker/outbounds",
                  // icon: "fa fa-level-up",
                },
              ],
            },
            // {
            //   name: 'My Payment',
            //   url: '/',
            //   icon: 'fa fa-credit-card-alt',
            //   badge: {
            //     variant: 'info',
            //     text: '▼',
            //   },
            //   children: [
            //     {
            //       name: 'Payment Term',
            //       url: '/stocker/payment-term',
            //       icon: 'fa fa-money',
            //     },
            //   ]
            // },
            {
              name: "My Order",
              url: "/stocker/myorder/",
              // icon: "fa fa-shopping-basket",
              badge: {
                variant: "info",
                text: "▼",
              },
              children: [
                //Sales
                {
                  name: "Purchase Order",
                  url: "/stocker/purchase-order",
                  // icon: "fa fa-mail-reply",
                },
                // {
                //   name: 'Return Order',
                //   url: '/stocker/return-order',
                //   icon: 'fa fa-undo',
                // },
                {
                  name: "Sales Order",
                  url: "/stocker/sales-order",
                  // icon: "fa fa-mail-forward",
                },
              ],
            },
            {
              name: "IOT Binding",
              url: "/stocker/iotbinding/",
              // icon: "fa fa-table",
              badge: {
                variant: "info",
                text: "▼",
              },
              children: [
                {
                  name: "User & Device",
                  url: "/stocker/user-and-device",
                  // icon: "fa fa-users",
                },
                {
                  name: "Item Box",
                  url: "/stocker/item-box",
                  // icon: "box icon",
                },
                // {
                //   name: "Tagging",
                //   url: "/stocker/tagging",
                //   icon: "fa fa-tags",
                // },
              ],
            },
          ],
        },
        {
          name: "TrackX",
          url: "/transporter",
          icon: "truck icon",
          children: [
            {
              name: "Main Dashboard",
              url: "/transporter/dashboard",
              // icon: "icon-speedometer",
            },
            {
              name: "3PL Request",
              url: "/transporter/3pl/request",
              // icon: "fa fa-bullhorn",
            },
            {
              name: "3PL Administrative",
              url: "/transporter/3pl/administrative",
              // icon: "fa fa-magic",
            },
            {
              name: "Divisions",
              url: "/transporter/divisions",
              // icon: "fa fa-id-card",
            },
            {
              name: "Check Sheet",
              url: "/transporter/check-sheet",
              // icon: "fa fa-check-square-o",
            },
            {
              name: "Delivery Orders",
              url: "/transporter/delivery-order",
              // icon: "fa fa-file-text",
            },
            {
              name: "Drivers",
              url: "/transporter/drivers",
              // icon: "fa fa-id-badge",
            },
            {
              name: "Drivers Assistant",
              url: "/transporter/drivers-assistant",
              // icon: "fa fa-male",
            },
            {
              name: "Fleets",
              url: "/transporter/fleets",
              // icon: "fa fa-car",
            },
            // ...(activeFeature.ASSET_TRACKING
            //   ? [
            //       {
            //         name: "Assets",
            //         url: "/transporter/assets-fleet",
            //         // icon: "fa fa-car",
            //       },
            //     ]
            //   : []),
            {
              name: "Fleet Operations",
              url: "/transporter/fleet-operations",
              // icon: "map signs icon",
            },
            {
              name: "Mechanics",
              url: "/transporter/mechanics",
              // icon: "fa fa-user",
            },
            {
              name: "DO Category",
              url: "/transporter/delivery/category",
              // icon: "fa fa-tree",
            },
            {
              name: "Fleet Maintenance",
              url: "/transporter/fleet-maintenance",
              // icon: "fa fa-wrench",
            },
            // {
            //   name: 'Maintenance Settings',
            //   url: '/transporter/fleet-maintenance-settings',
            //   icon: 'fa fa-cogs',
            // },
            {
              name: "Live Monitoring",
              url: "/transporter/live-monitoring",
              // icon: "eye icon",
            },
            {
              name: "Main Customers",
              url: "/transporter/main-customers",
              // icon: "fa fa-users",
            },
            {
              name: "Saved Places",
              url: "/transporter/saved-places",
              // icon: "bookmark icon",
            },
            // {
            //   name: 'Public Facility',
            //   url: '/transporter/public-facility',
            //   icon: 'fa fa-globe',
            // },
            {
              name: "Security",
              url: "/transporter/security",
              // icon: "shield alternate icon",
            },
            // {
            //   name: 'Workshop',
            //   url: '/transporter/workshop',
            //   icon: 'fa fa-file',
            // },
            ...(activeFeature.TIRE_MANAGEMENT || activeFeature.STORING
              ? [
                  {
                    name: "Accessories and Parts",
                    url: "/transporter/accessories-and-parts",
                    // icon: "fa fa-shield",
                    children: [
                      {
                        name: "Accessories and Parts",
                        url: "/transporter/accessories-and-parts",
                        // icon: "fa fa-shield",
                      },
                      {
                        name: "Brand Parts",
                        url: "/transporter/brand-parts",
                        // icon: "fa fa-shield",
                      },
                      {
                        name: "Category Parts",
                        url: "/transporter/category-parts",
                        // icon: "fa fa-shield",
                      },
                      {
                        name: "Distributor Parts",
                        url: "/transporter/distributor-parts",
                        // icon: "fa fa-shield",
                      },
                      {
                        name: "Quality Parts",
                        url: "/transporter/quality-parts",
                        // icon: "fa fa-shield",
                      },
                      {
                        name: "Storage Location Parts",
                        url: "/transporter/storage-location-parts",
                        // icon: "fa fa-shield",
                      },
                      {
                        name: "Sparepart Request",
                        url: "/transporter/sparepart-request",
                        // icon: "fa fa-shield",
                      },
                    ],
                  },
                ]
              : []),

            ...(activeFeature.STORING
              ? [
                  {
                    name: "Storing",
                    url: "/storing",
                    // icon: "fa fa-shield",
                    children: [
                      {
                        name: "Fleet Trip",
                        url: "/storing/fleet/trip",
                        // icon: "fa fa-shield",
                      },
                      {
                        name: "Maintenance Mechanic",
                        url: "/storing/maintenance/mechanic",
                        // icon: "fa fa-shield",
                      },
                      {
                        name: "Maintenance Proof",
                        url: "/storing/maintenance/Proof",
                        // icon: "fa fa-shield",
                      },
                      {
                        name: "Maintenance Approval Type Setting",
                        url: "/storing/maintenance/approval/type/setting",
                        // icon: "fa fa-shield",
                      },
                    ],
                  },
                ]
              : []),
            ...(activeFeature.STORING
              ? [
                  {
                    name: "Incident",
                    url: "/incident",
                    // icon: "fa fa-shield",
                    children: [
                      {
                        name: "Storing Ticket",
                        url: "/incident/storing-ticket",
                        // icon: "fa fa-shield",
                      },
                    ],
                  },
                ]
              : []),
            ...(activeFeature.STORING
              ? [
                  {
                    name: "Scan",
                    url: "/scan",
                    // icon: "fa fa-shield",
                    children: [
                      {
                        name: "Activity Type",
                        url: "/scan/activity-type",
                        // icon: "fa fa-shield",
                      },
                      {
                        name: "Driver",
                        url: "/scan/driver",
                        // icon: "fa fa-shield",
                      },
                      {
                        name: "Mechanic",
                        url: "/scan/mechanic",
                        // icon: "fa fa-shield",
                      },
                    ],
                  },
                ]
              : []),
          ],
        },
        // {
        //   name: 'Land Lord',
        //   url: '/landlord',
        //   icon: 'fa fa-industry',
        //   children: [
        //     {
        //       name: 'LL Monitoring',
        //       url: '/landlord/dashboard',
        //       icon: 'fa fa-stethoscope',
        //     },
        //     {
        //       name: 'Location Type',
        //       url: '/landlord/locationtype',
        //       icon: 'fa fa-bars',
        //     },
        //     {
        //       name: 'Location',
        //       url: '/landlord/locations',
        //       icon: 'fa fa-map-pin',
        //     },
        //     {
        //       name: 'Sensors',
        //       url: '/landlord/sensors',
        //       icon: 'fa fa-microchip',
        //     },
        //     {
        //       name: 'Utilities',
        //       url: '/landlord/utilities',
        //       icon: 'fa fa-shower',
        //     },
        //   ]
        // },
        ...(activeFeature.ASSET_TRACKING
          ? [
              {
                name: "Asset Tracking",
                url: "/asset-tracking",
                icon: "map icon",
                children: [
                  {
                    name: "Asset Category",
                    url: "/asset-tracking/category",
                    // icon: "fa fa-users",
                  },
                  {
                    name: "Asset List",
                    url: "/asset-tracking/list",
                    // icon: "fa fa-users",
                  },
                  {
                    name: "Asset Tracking Map",
                    url: "/asset-tracking/map",
                    // icon: "fa fa-user-circle",
                  },
                ],
              },
            ]
          : []),
        {
          name: "DockX",
          url: "/point-hub",
          icon: "share alternate icon",
          children: [
            {
              name: "Customer",
              url: "/point-hub/customers",
              // icon: "fa fa-users",
            },
            {
              name: "Customer PIC",
              url: "/point-hub/customer-pic",
              // icon: "fa fa-user-circle",
            },
            {
              name: "Customer Bank Accounts",
              url: "/point-hub/customer-bank-accounts",
              // icon: "fa fa-id-card",
            },
            {
              name: "3PL Group",
              url: "/transporter/3pl/grouping",
              // icon: "users icon",
            },
            {
              name: "Rentee",
              url: "/point-hub/rentee",
              // icon: "fa fa-truck",
              badge: {
                variant: "info",
                text: "NEW",
              },
            },
            {
              name: "Maintenance Workshop",
              url: "/point-hub/maintenance-workshop",
              // icon: "fa fa-wrench",
            },
            // {
            //   name: 'Customer Approvals',
            //   url: '/point-hub/customer-approvals',
            //   icon: 'fa fa-check',
            // },
            // {
            //   name: 'PIC Positions',
            //   url: '/point-hub/pic-positions',
            //   icon: 'fa fa-arrow-down',
            // },
          ],
        },
        {
          name: "CarbonX",
          url: "/carbonx",
          icon: "leaf icon",
          children: [
            ...(activeFeature.MENUBAR_CARBONX_V2
              ? carbonMenu
              : [
                  {
                    name: "Main Dashboard",
                    url: "/carbonx/dashboard/",
                    // icon: "icon-speedometer",
                  },
                  {
                    name: "Logger",
                    url: "/carbonx/logger/",
                    // icon: "fa fa-terminal",
                    badge: {
                      variant: "info",
                      text: "▼",
                    },
                    children: [
                      //Inbounds
                      {
                        name: "Scope 1",
                        url: "/carbonx/logger/scope1",
                        // icon: "fa fa-truck",
                      },
                      {
                        name: "Scope 2",
                        url: "/carbonx/logger/scope2",
                        // icon: "fa fa-bolt",
                      },
                      {
                        name: "Scope 3",
                        url: "/carbonx/logger/scope3",
                        // icon: "fa fa-share",
                      },
                    ],
                  },
                  {
                    name: "Carbon Invoice",
                    url: "/carbonx/invoice",
                    // icon: "fa fa-file-text-o",
                  },
                  {
                    name: "Data Market",
                    url: "/carbonx/data-market",
                    // icon: "fa fa-database",
                  },
                  // {
                  //   name: 'Settings',
                  //   url: '/enermo/settings',
                  //   icon: 'fa fa-cogs',
                  // },
                ]),
          ],
        },
        // {
        //   name: "CarbonX",
        //   url: "/point-hub",
        //   icon: "leaf icon",
        //   children: [
        //     ...(activeFeature.MENUBAR_CARBONX_V2
        //       ? carbonMenu
        //       : [
        //           {
        //             name: "Main Dashboard",
        //             url: "/carbonx/dashboard/",
        //             // icon: "icon-speedometer",
        //           },
        //           {
        //             name: "Logger",
        //             url: "/carbonx/logger/",
        //             // icon: "fa fa-terminal",
        //             badge: {
        //               variant: "info",
        //               text: "▼",
        //             },
        //             children: [
        //               //Inbounds
        //               {
        //                 name: "Scope 1",
        //                 url: "/carbonx/logger/scope1",
        //                 // icon: "fa fa-truck",
        //               },
        //               {
        //                 name: "Scope 2",
        //                 url: "/carbonx/logger/scope2",
        //                 // icon: "fa fa-bolt",
        //               },
        //               {
        //                 name: "Scope 3",
        //                 url: "/carbonx/logger/scope3",
        //                 // icon: "fa fa-share",
        //               },
        //             ],
        //           },
        //           {
        //             name: "Carbon Invoice",
        //             url: "/carbonx/invoice",
        //             // icon: "fa fa-file-text-o",
        //           },
        //           {
        //             name: "Data Market",
        //             url: "/carbonx/data-market",
        //             // icon: "fa fa-database",
        //           },
        //           // {
        //           //   name: 'Settings',
        //           //   url: '/enermo/settings',
        //           //   icon: 'fa fa-cogs',
        //           // },
        //         ]),
        //   ],
        // },
        {
          name: "Tools",
          url: "/tools",
          icon: "wrench  icon",
          children: [
            {
              name: "Geocode Converter",
              url: "/tools/geocode-converter",
              // icon: "map icon",
              badge: {
                variant: "info",
                text: "NEW",
              },
            },
            ...(activeFeature.MY_PERTAMINA_COMPARE_REPORT_FUEL
              ? [
                  {
                    name: "MyPertamina Converter",
                    url: "/tools/mypertamina-converter",
                    // icon: "map icon",
                    badge: {
                      variant: "info",
                      text: "NEW",
                    },
                  },
                ]
              : []),
          ],
        },
        // {
        //   name: 'Fleets',
        //   url: '/transporter/fleets',
        //   icon: 'fa fa-car',
        // },
        // {
        //   name: 'Fleet Monitoring',
        //   url: '/transporter/monitoring',
        //   icon: 'fa fa-map',
        // },
        // {
        //   name: 'Drivers',
        //   url: '/transporter/drivers',
        //   icon: 'fa fa-id-badge',
        // },
        // {
        //   name: 'Drivers Assistant',
        //   url: '/transporter/drivers-assistant',
        //   icon: 'fa fa-male',
        // },
        // {
        //   name: 'Delivery Orders',
        //   url: '/transporter/delivery-order',
        //   icon: 'fa fa-file-text',
        // },
        {
          name: "Subscriptions",
          url: "/subscriptions",
          icon: "certificate icon",
        },
        {
          name: "Settings",
          url: "/transporter",
          icon: "cog icon",
          children: [
            {
              name: "Company Settings",
              url: "/settings",
              // icon: "cogs icon",
            },
            {
              name: "Maintenance Settings",
              url: "/transporter/fleet-maintenance-settings",
              // icon: "cogs icon",
            },
          ],
        },
      ]
    : process.env.IS_CARBON_ETHICS
    ? carbonMenuWithIcon
    : process.env.IS_STARK_INDUSTRIES
    ? carbonMenuWithIcon
    : [],
};
