import React, { useCallback, useState, useEffect } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Circle,
  Marker,
} from "@react-google-maps/api";

const apiKey = "AIzaSyCmIATqobqbCEU90nYtwd-PzCcj8EVOlDg";

const mapContainerStyle = {
  width: "100%",
  height: "500px",
};

const GeofencingMap = (props) => {
  const [mapCopy, setMapCopy] = useState(null);
  const { setFieldValue, values } = props;
  const [mapCenter, setMapCenter] = useState({ lat: -2.5489, lng: 118.0149 });
  const defaultRadius = 100;

  // Fungsi untuk menangani klik pada peta
  const handleMapClick = (event) => {
    const clickedLat = event.latLng.lat();
    const clickedLng = event.latLng.lng();

    if (values.type_map === 1) {
      setFieldValue("lat", String(clickedLat));
      setFieldValue("lng", String(clickedLng));
      setFieldValue("radius", String(defaultRadius));
      setMapCenter({
        lat: parseFloat(clickedLat),
        lng: parseFloat(clickedLng),
      });
    } else {
      // Menambahkan koordinat baru ke savedPolygons
      const newCoordinateWithSeq = {
        lat: String(clickedLat),
        lon: String(clickedLng),
        seq: values.savedPolygons.length + 1,
      };

      const cleanedSavedPolygons = values.savedPolygons.filter(
        (item) => item.lat && item.lon
      );

      const updatedPolygons = [...cleanedSavedPolygons, newCoordinateWithSeq];
      setFieldValue("savedPolygons", updatedPolygons);
      setMapCenter({
        lat: parseFloat(clickedLat),
        lng: parseFloat(clickedLng),
      });
    }
  };

  // Fungsi untuk menangani dragging marker
  const handleMarkerDrag = (event, index) => {
    const draggedLat = event.latLng.lat();
    const draggedLng = event.latLng.lng();

    if (values.type_map === 1) {
      setFieldValue("lat", String(draggedLat));
      setFieldValue("lng", String(draggedLng));
      setFieldValue("radius", String(defaultRadius));
      setMapCenter({
        lat: parseFloat(draggedLat),
        lng: parseFloat(draggedLng),
      });
    } else if (values.type_map === 2) {
      // Update koordinat marker di savedPolygons
      const updatedPolygons = values.savedPolygons.map((coord, i) =>
        i === index
          ? { ...coord, lat: String(draggedLat), lon: String(draggedLng) }
          : coord
      );
      setFieldValue("savedPolygons", updatedPolygons);
      // setSavedPolygons(updatedPolygons);
      setMapCenter({
        lat: parseFloat(draggedLat),
        lng: parseFloat(draggedLng),
      });
    }
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: apiKey,
  });

  const onLoad = useCallback((mapInstance) => {
    mapInstance.data.setStyle({
      fillColor: "#ff8080",
      fillOpacity: 0.35,
      strokeColor: "#ff0000",
      strokeWeight: 2,
    });
    setMapCopy(mapInstance);
  }, []);


  useEffect(() => {
    if (!!mapCopy) {
      mapCopy.data.forEach((feature) => mapCopy.data.remove(feature));

      if (values.type_map === 1) {
        if(values.lat && values.lng){
          setMapCenter({
            lat: Number(values.lat),
            lng: Number(values.lng),
          });
        }
      } else {
        const newCoord = [
          ...values.savedPolygons.map((item) => [
            Number(item.lon),
            Number(item.lat),
          ]),
          ...values.savedPolygons
            .filter((_, index) => index === 0)
            .map((item) => [Number(item.lon), Number(item.lat)]),
        ];

        if (newCoord.length >= 3) {
          mapCopy.data.addGeoJson({
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "Polygon",
                  coordinates: [newCoord],
                },
              },
            ],
          });

          setMapCenter({
            lat: Number(newCoord[0][1]),
            lng: Number(newCoord[0][0]),
          });
        }
      }
    }
  }, [values.savedPolygons, mapCopy, values.type_map, values.lat, values.lng]);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      defaultCenter={{ lat: -2.5489, lng: 118.0149 }}
      defaultZoom={5}
      center={mapCenter}
      zoom={
        (values.lat && values.lng && values.radius && values.type_map === 1) ||
        (values.savedPolygons.length > 0 &&
          values.savedPolygons[0]?.lat &&
          values.savedPolygons[0]?.lon &&
          values.type_map === 2)
          ? 16
          : 5
      }
      onLoad={onLoad}
      onClick={handleMapClick}
    >
      {/* Render Circle dan Marker ketika props coordinates dan radius dari form valid */}
      {values.type_map === 1 && values.lat && values.lng && values.radius && (
        <>
          <Circle
            center={{
              lat: parseFloat(values.lat),
              lng: parseFloat(values.lng),
            }}
            radius={parseInt(values.radius)}
            options={{
              fillColor: "#ff8080",
              fillOpacity: 0.35,
              strokeColor: "#ff0000",
              strokeOpacity: 0.8,
              strokeWeight: 2,
            }}
          />
          <Marker
            key={1}
            draggable
            onDragEnd={(event) => handleMarkerDrag(event)}
            position={{
              lat: parseFloat(values.lat),
              lng: parseFloat(values.lng),
            }}
            icon={{
              path: window.google.maps.SymbolPath.CIRCLE,
              scale: 10,
              fillColor: "#ff0000",
              fillOpacity: 1,
              strokeWeight: 2,
              strokeColor: "white",
            }}
          />
        </>
      )}
      {/* Render markers untuk setiap koordinat polygon */}
      {values.type_map === 2 &&
        values.savedPolygons.length > 0 &&
        values.savedPolygons.map((coord, index) =>
          coord.lat && coord.lon ? (
            <Marker
              key={index}
              draggable
              onDragEnd={(event) => handleMarkerDrag(event, index)}
              position={{
                lat: parseFloat(coord.lat),
                lng: parseFloat(coord.lon),
              }}
              icon={{
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: 10,
                fillColor: "#ff0000",
                fillOpacity: 1,
                strokeWeight: 2,
                strokeColor: "white",
              }}
            />
          ) : null
        )}
    </GoogleMap>
  ) : null;
};

export default GeofencingMap;
