import React, { Component } from "react";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import LiveMonitoring from "./LiveMonitoring";
import TriPLLiveMonitoring from "./TriPLLiveMap/TriPLLiveMonitoring";
import RenteeLiveMap from "./RenteeLiveMap/LiveMonitoring";
import AssetsTracking from "./AssetsTracking/AssetsTracking";
import ToggleSwitchCommandCenter from "../../../components/ToggleSwitchCommandCenter/ToggleSwitchCommandCenter";
import { activeFeature } from "../../../constants/constants";
import styles from "./LiveMonitoringTabs.module.scss";

const TRIPL_OWN_FLEET_TAB = 1;
const RENTEE_LIVE_MAP_TAB = 2;
const TRIPL_3PL_FLEET_TAB = 3;
const ASSETS_TRACKING_TAB = 4;
class LiveMonitoringTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: TRIPL_OWN_FLEET_TAB,
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    return (
      <div>
        <div className={styles["floating-button"]}>
          {activeFeature.LIVE_MONITORING_V2 && <ToggleSwitchCommandCenter />}
        </div>
        <div className="fw-bold fs-3 mb-3">Live Monitoring</div>
        <Row>
          <Col xs="12" md="12" className="mb-4">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === TRIPL_OWN_FLEET_TAB,
                  })}
                  onClick={() => {
                    this.toggle(TRIPL_OWN_FLEET_TAB);
                  }}
                >
                  <i className="fa fa-truck"></i>
                  <span> Own Fleet</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === RENTEE_LIVE_MAP_TAB,
                  })}
                  onClick={() => {
                    this.toggle(RENTEE_LIVE_MAP_TAB);
                  }}
                >
                  <i className="fa fa-truck"></i>
                  <span> Rental Fleets</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === TRIPL_3PL_FLEET_TAB,
                  })}
                  onClick={() => {
                    this.toggle(TRIPL_3PL_FLEET_TAB);
                  }}
                >
                  <i className="fa fa-truck"></i>
                  <span> 3PL Fleet</span>
                </NavLink>
              </NavItem>
              {activeFeature.ASSET_TRACKING?<NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === ASSETS_TRACKING_TAB,
                  })}
                  onClick={() => {
                    this.toggle(ASSETS_TRACKING_TAB);
                  }}
                >
                  <i className="fa fa-cubes"></i>
                  <span> Asset Tracking</span>
                </NavLink>
              </NavItem>:null}
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId={TRIPL_OWN_FLEET_TAB}>
                <LiveMonitoring history={this.props.history} />
              </TabPane>
              <TabPane tabId={RENTEE_LIVE_MAP_TAB}>
                <RenteeLiveMap />
              </TabPane>
              <TabPane tabId={TRIPL_3PL_FLEET_TAB}>
                <TriPLLiveMonitoring />
              </TabPane>
              <TabPane tabId={ASSETS_TRACKING_TAB}>
                <AssetsTracking />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </div>
    );
  }
}

export default LiveMonitoringTabs;
