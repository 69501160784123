import { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
// import axios from "axios";
// import * as API_LINKS from "../../../../redux/config/links.jsx";
// import { toast } from "react-toastify";
import AssetsTrackingDatatable from "./AssetsTracking-Datatable";
import SummaryWidgetsNew from "src/views/Widgets/SummaryWidgetsNew";
// import { compose } from "recompose";
// import customMarkerIdle from "../../../../assets/img/truckidle.png";
// import customMarkerMoving from "../../../../assets/img/truckmoving.png";
// import home from "../../../../assets/img/home.png";
import { MdHomeWork } from "react-icons/md";
import { FaWifi, FaMapMarkerAlt, FaExclamationTriangle } from "react-icons/fa";
import useLazyFetch from "src/hooks/useLazyFetch";
import { LIVE_MONITORING_URL } from "src/redux/config/links";
import AssetsTrackingMap from "./AssetsTrackingMap"

export default function AssetsTracking() {
    const [isGettingFleetList, setIsGettingFleetList] = useState(true);
    const [getFleetList, { loading: loadingFleetList, data: dataFleet }] =
      useLazyFetch({
        url: LIVE_MONITORING_URL,
        method: "POST",
      });

    const dataDummy = [
      {
        id: 1,
        asset_name: "Rak Gudang 1",
        car_type: "Rak",
        car_brands: "Olympic",
        obd_code: "R33412",
        reg_number: "RK1",
        notes: "Memiliki barcode",
        position:
          "Jalan Satya Darma, Parangjoro, Sukoharjo, Jawa Tengah, Jawa, 57551, Indonesia",
      },
      {
        id: 2,
        asset_name: "Rak Gudang 2",
        car_type: "Rak",
        car_brands: "Olympic",
        obd_code: "R33351",
        reg_number: "RK2",
        notes: "Belum memiliki barcode",
        position:
          "Jalan Satya Darma, Parangjoro, Sukoharjo, Jawa Tengah, Jawa, 57551, Indonesia",
      },
      {
        id: 3,
        asset_name: "Rak Gudang 3",
        car_type: "Rak",
        car_brands: "Olympic",
        obd_code: "R33674",
        reg_number: "RK3",
        notes: "Belum memiliki barcode",
        position:
          "Jalan Satya Darma, Parangjoro, Sukoharjo, Jawa Tengah, Jawa, 57551, Indonesia",
      },
    ];

    const combinedData = dataFleet?.result
      ? [...dataFleet.result, ...dataDummy]
      : dataDummy;

    useEffect(() => {
      if (isGettingFleetList) {
        setIsGettingFleetList(false);
        getFleetList();
      }
    }, [isGettingFleetList, getFleetList]);

    return (
      <div className="animated fadeIn">
        <Card>
          <CardBody className="m-0 p-0">
            <AssetsTrackingMap data={dataFleet ? dataFleet.result : null} />
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <b>Asset Tracking</b>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <SummaryWidgetsNew
                  header={
                    dataFleet && dataFleet.result ? combinedData.length : 0
                  }
                  mainText="Total Assets"
                  icon={<MdHomeWork size={25} />}
                  color="primary"
                />
              </Col>
              <Col>
                <SummaryWidgetsNew
                  header={
                    dataFleet && dataFleet.result ? combinedData.length : 0
                  }
                  mainText="Online"
                  icon={<FaWifi size={25} />}
                  color="success"
                />
              </Col>
              <Col>
                <SummaryWidgetsNew
                  header={0}
                  mainText="Offline"
                  icon={<FaWifi size={25} />}
                  color="warning"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <SummaryWidgetsNew
                  header={15}
                  mainText="Location A"
                  icon={<FaMapMarkerAlt size={25} />}
                  color="info"
                />
              </Col>
              <Col>
                <SummaryWidgetsNew
                  header={17}
                  mainText="Location B"
                  icon={<FaMapMarkerAlt size={25} />}
                  color="info"
                />
              </Col>
              <Col>
                <SummaryWidgetsNew
                  header={0}
                  mainText="Out of Bonds"
                  icon={<FaExclamationTriangle size={25} />}
                  color="danger"
                />
              </Col>
            </Row>
            <AssetsTrackingDatatable
              fleetData={combinedData ? combinedData : []}
              loadingFleetList={loadingFleetList}
              onReload={setIsGettingFleetList}
            />
          </CardBody>
        </Card>
      </div>
    );
}