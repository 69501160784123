import { Button, Col, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import * as yup from "yup";
import Select from "react-select";
import useForm from "src/hooks/useForm";

const AssetTrackingAddEdit = ({
  onClose = () => null,
  onReload = () => null,
  initialValues = null,
  isEdit = false,
}) => {
  const validation = () => {
    let selectYupNumber = yup.object().shape({
      label: yup.string().required("Required"),
      value: yup.string().required("Required"),
    });
    return yup.object().shape({
      asset_type: yup.string().required("Asset Type is required"),
      brand: selectYupNumber.required("Brand is required"),
      Rentable: selectYupNumber.required("Rentable Type is required"),
      imei_code: yup.string().required("IMEI Code is required"),
      registration_number: yup.string().required("Registration Number is required"),
    });
  };

  const dummyBrands = [
    { value: "brand1", label: "Brand 1" },
    { value: "brand2", label: "Brand 2" },
    { value: "brand3", label: "Brand 3" },
  ];

  const dummyAssetTypes = [
    { value: "rak", label: "Rak" },
    { value: "pallet", label: "Pallet" },
    { value: "lainnya", label: "Lainnya" },
  ];

  const dummyRentable = [
    { value: "yes", label: "YES" },
    { value: "no", label: "NO" },
  ];

  const {
    handleSubmit,
    errors,
    values,
    handleReset,
    setFieldValue,
    isSubmitting,
    isValid,
  } = useForm({
    initialValues:
      initialValues !== null
        ? {
            asset_name: initialValues.asset_name,
            car_type:
              !!initialValues && initialValues.car_type
                ? {
                    value: initialValues.car_type,
                    label: initialValues.car_type,
                  }
                : null,
            car_brands:
              !!initialValues && initialValues.car_brands
                ? {
                    value: initialValues.car_brands,
                    label: initialValues.car_brands,
                  }
                : null,
            position: initialValues.position,
            rentable:
              !!initialValues && initialValues.rentable
                ? {
                    value: initialValues.rentable,
                    label: initialValues.rentable,
                  }
                : null,
            imei_code: initialValues.obd_code,
            registration_number: initialValues.reg_number,
            notes: initialValues.notes,
          }
        : {
            asset_name: "",
            car_type: null,
            car_brands: null,
            position: "",
            rentable: null,
            obd_code: "",
            reg_number: "",
            notes: "",
          },
    validation: validation(),
  });

  const handleChange = (e) => {
    setFieldValue(e.currentTarget.name, e.currentTarget.value);
  };

  const onSubmit = (value, { setSubmitting }) => {
    setSubmitting(false);
    onClose();
    onReload();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="asset_name">Asset Name</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="asset_name"
              id="asset_name"
              placeholder="Asset Name"
              autoComplete="off"
              valid={!errors.asset_name}
              invalid={!!errors.asset_name}
              onChange={handleChange}
              value={values.asset_name}
              disabled={isSubmitting}
            />
            <FormFeedback>{errors.asset_name}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="asset_type">Asset Type</Label>
          </Col>
          <Col xs="12" md="9">
            <div className={`${!!errors.asset_type ? "is-invalid" : ""}`}>
              <Select
                value={values.asset_type}
                onChange={(selected) => {
                  setFieldValue("asset_type", selected);
                }}
                options={dummyAssetTypes}
                disabled={isSubmitting}
                placeholder={"Select Asset Type"}
              />
            </div>
            <FormFeedback>{errors.asset_type}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="brands">Brand</Label>
          </Col>
          <Col xs="12" md="9">
            <div className={`${!!errors.brand ? "is-invalid" : ""}`}>
              <Select
                value={values.brand}
                onChange={(selected) => {
                  setFieldValue("brands", selected);
                }}
                options={dummyBrands}
                disabled={isSubmitting}
                placeholder={"Select Brand"}
              />
            </div>
            <FormFeedback>{errors.brand}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="position">Position</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="position"
              id="position"
              placeholder="Adress"
              autoComplete="off"
              valid={!errors.position}
              invalid={!!errors.position}
              onChange={handleChange}
              value={values.position}
              disabled={isSubmitting}
            />
            <FormFeedback>{errors.position}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="rentable">Rentable</Label>
          </Col>
          <Col xs="12" md="9">
            <div className={`${!!errors.rentable ? "is-invalid" : ""}`}>
              <Select
                value={values.rentable}
                onChange={(selected) => {
                  setFieldValue("brands", selected);
                }}
                options={dummyRentable}
                disabled={isSubmitting}
                placeholder={"Select Rentable"}
              />
            </div>
            <FormFeedback>{errors.rentable}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="imei_code">IMEI Code</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="imei_code"
              id="imei_code"
              placeholder="123xxxxxx"
              autoComplete="off"
              valid={!errors.imei_code}
              invalid={!!errors.imei_code}
              onChange={handleChange}
              value={values.imei_code}
              disabled={isSubmitting}
            />
            <FormFeedback>{errors.imei_code}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="registration_number">Registration Number</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="registration_number"
              id="registration_number"
              placeholder="Registration Number"
              autoComplete="off"
              valid={!errors.registration_number}
              invalid={!!errors.registration_number}
              onChange={handleChange}
              value={values.registration_number}
              disabled={isSubmitting}
            />
            <FormFeedback>{errors.registration_number}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="notes">Notes</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="notes"
              id="notes"
              placeholder="Notes..."
              autoComplete="off"
              valid={!errors.notes}
              invalid={!!errors.notes}
              onChange={handleChange}
              value={values.notes}
              disabled={isSubmitting}
            />
            <FormFeedback>{errors.notes}</FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup>
          <div className="d-flex justify-content-end w-100">
            <Button
              type="reset"
              color="danger"
              className="mr-1 w-25"
              disabled={isSubmitting}
              onClick={handleReset}
            >
              Reset
            </Button>
            <Button
              type="button"
              color="warning"
              className="mr-1 w-25"
              disabled={isSubmitting}
              onClick={() => onClose()}
            >
              Close
            </Button>

            <Button
              type="submit"
              color="primary"
              className="mr-1 w-50"
              disabled={isSubmitting || !isValid}
            >
              {isSubmitting ? "Wait..." : "Submit"}
            </Button>
          </div>
        </FormGroup>
      </form>
    </>
  );
};

export default AssetTrackingAddEdit;
