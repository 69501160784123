import React, { useState } from "react";
import { IProps } from "./TableV2.type";
import { fileNameFormatWithType, saveAs } from "src/helpers/helper";
import ExcelJS from "exceljs";

const TableV2: React.FC<IProps> = ({
  name,
  columns = [],
  data = [],
  dataFiltered = [],
  loading = false,
  totalData = 0,
  page = { of: 1, size: 10 },
  onPagePrevious = () => null,
  onPageNext = () => null,
  onPage = () => null,
  sort = [],
  onSort = () => null,
  filter = {},
  onFilter = () => null,
  renderExpendRow,
  search = "",
  onSearch = () => null,
}) => {
  const exportToCSV = async () => {
    const fileName = fileNameFormatWithType(name, "csv");
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(name);

    worksheet.columns = columns
      .filter((col) => !!!col.hidden && !!col.field && !!data[0][col.field])
      .map((col) => ({
        header: col.name,
        key: col.field,
      }));

    // Tambahkan data ke worksheet
    if (data.length === 0) {
      let obj = {};
      for (const col in columns.filter(
        (col) => !!!col.hidden && !!col.field && !!data[0][col.field]
      )) {
        if (!!columns[col] && !!columns[col].field) {
          obj = { ...obj, [columns[col].field]: "" };
        }
      }
      // Jika tidak ada data, tambahkan baris pemberitahuan
      worksheet.addRow(obj);
    } else {
      data.forEach((row, index) => {
        let obj = {};

        for (const col in columns.filter(
          (col) => !!!col.hidden && !!col.field && !!row[col.field]
        )) {
          if (!!columns[col] && !!columns[col].field) {
            obj = {
              ...obj,
              [columns[col].field]: !!columns[col].renderCSVFormat
                ? columns[col].renderCSVFormat(
                    !!columns[col].field ? row[columns[col].field] : "",
                    row,
                    index
                  )
                : columns[col].field
                ? row[columns[col].field]
                : "",
            };
          }
        }
        worksheet.addRow(row);
      });
    }

    // Nyimpen workbook ke file
    const buffer = await workbook.csv.writeBuffer({
      formatterOptions: {
        delimiter: ';',
      },
    });
    const blob = new Blob([buffer], { type: "text/csv;charset=utf-8" });
    saveAs(blob, fileName);
  };

  const totalPage = Number(Math.ceil(totalData / page.size));
  const listPage = (total: number) => {
    let list: number[] = [];
    for (let i = 1; i <= total; i++) {
      list = [...list, i];
    }
    return list;
  };
  const [localFilter, setLocalFilter] = useState({ ...filter });
  const [openedExpandRow, setOpenedExpandRow] = useState<number[]>([]);

  const onHandleSort = (
    field?: string,
    exist?: { order: "asc" | "desc"; by: string }
  ) => {
    if (exist) {
      if (exist.order === "asc") {
        onSort([
          ...sort.map((item) =>
            item.by === field
              ? { ...item, order: "desc" as const }
              : { ...item }
          ),
        ]);
      } else {
        onSort([...sort.filter((item) => item.by !== field)]);
      }
    } else {
      onSort([
        ...sort,
        {
          by: field ?? "",
          order: "asc",
        },
      ]);
    }
  };
  const onHandleToggleExpandRow = (value: number) => {
    if (!!openedExpandRow.find((item) => item === value)) {
      setOpenedExpandRow([...openedExpandRow.filter((i) => i !== value)]);
    } else {
      setOpenedExpandRow([...openedExpandRow, value]);
    }
  };
  return (
    <div>
      <div className="row">
        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-8">
          <div className="btn-group btn-group-sm" role="group">
            <button
              type="button"
              className="btn btn-success btn-sm"
              onClick={exportToCSV}
            >
              <span>
                <i className="fa glyphicon glyphicon-export fa-download"></i>{" "}
                Export to CSV
              </span>
            </button>
          </div>
        </div>
        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4">
          <div className="form-group form-group-sm">
            <input
              className="form-control "
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e) => onSearch(e.currentTarget.value)}
            />
            <span className="input-group-btn"></span>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
            {!!renderExpendRow ? <th></th> : null}
            {columns
              .filter((col) => !!!col.hidden)
              .map((col, key) => {
                const exist = sort.find((item) => item.by === col.field);
                return (
                  <th key={key}>
                    {col.sortable ? (
                      <span
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          onHandleSort(col.field, exist);
                        }}
                      >
                        {col.name}{" "}
                        <span
                          style={{
                            ...(!!!exist
                              ? { color: "rgb(204, 204, 204)" }
                              : {}),
                          }}
                        >
                          <i
                            className={`fa ${
                              !!exist
                                ? exist.order === "asc"
                                  ? "fa-sort-up"
                                  : "fa-sort-down"
                                : "fa-sort"
                            }`}
                          ></i>
                        </span>
                      </span>
                    ) : (
                      <>{col.name}</>
                    )}

                    {col.searchable ? (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          onFilter({ ...localFilter });
                        }}
                      >
                        <input
                          style={{ minWidth: 70 }}
                          className="form-control"
                          autoComplete="off"
                          type="text"
                          name={col.field}
                          placeholder={`Enter ${col.name}`}
                          value={localFilter?.[col.field ?? ""] ?? ""}
                          onChange={(e) => {
                            setLocalFilter({
                              ...localFilter,
                              [col.field ?? ""]: e.currentTarget.value,
                            });
                          }}
                        />
                      </form>
                    ) : null}
                  </th>
                );
              })}
              </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={columns.length} style={{ textAlign: "center" }}>
                  Loading ...
                </td>
              </tr>
            ) : (
              <>
                {dataFiltered.length === 0 ? (
                  <tr>
                    <td
                      colSpan={columns.length}
                      style={{ textAlign: "center" }}
                    >
                      No data
                    </td>
                  </tr>
                ) : (
                  <>
                    {dataFiltered.map((item, key) => {
                      const rowNumber =
                        page.size * page.of - page.size + key + 1;
                      return (
                        <React.Fragment key={key}>
                          <tr>
                            {!!renderExpendRow ? (
                              <td>
                                <span
                                  role="button"
                                  onClick={() =>
                                    onHandleToggleExpandRow(rowNumber)
                                  }
                                >
                                  {!!openedExpandRow.find(
                                    (j) => j === rowNumber
                                  ) ? (
                                    <i className="fa fa-minus"></i>
                                  ) : (
                                    <i className="fa fa-plus"></i>
                                  )}
                                </span>
                              </td>
                            ) : null}
                            {columns
                              .filter((col) => !!!col.hidden)
                              .map((col, colKey) => (
                                <React.Fragment key={colKey}>
                                  <td>
                                    {!!col.renderFormat
                                      ? col.renderFormat(
                                          !!col.field ? item[col.field] : null,
                                          item,
                                          key
                                        )
                                      : col.field
                                      ? item[col.field]
                                      : null}
                                  </td>
                                </React.Fragment>
                              ))}
                          </tr>
                          {!!renderExpendRow &&
                          !!openedExpandRow.find(
                            (item) => item === rowNumber
                          ) ? (
                            <tr>
                              <td colSpan={columns.length + 1}>
                                {renderExpendRow(item, key)}
                              </td>
                            </tr>
                          ) : null}
                        </React.Fragment>
                      );
                    })}
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      <select
        name="size"
        value={page.size}
        onChange={(e) =>
          onPage({ ...page, size: Number(e.currentTarget.value) })
        }
      >
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </select>
      {dataFiltered.length > 0 ? (
        <nav className="pull-right">
          <ul className="pagination">
            {page.of != 1 ? (
              <li className={`page-item`}>
                <a
                  className="page-link"
                  role="button"
                  onClick={() => {
                    return onPage({ ...page, of: 1 });
                  }}
                >
                  &laquo;
                </a>
              </li>
            ) : null}
            <li className={`page-item ${page.of > 1 ? "" : "disabled"}`}>
              <a
                className="page-link"
                role="button"
                onClick={() => {
                  if (page.of > 1) {
                    return onPagePrevious();
                  } else {
                    return null;
                  }
                }}
              >
                &lt;
              </a>
            </li>
            {listPage(totalData === 0 ? 1 : totalPage)
              .filter(
                (item) =>
                  page.of -
                    (totalPage - page.of <= 2 ? 5 - (totalPage - page.of) : 3) <
                    item && item <= page.of + (page.of <= 2 ? 5 - page.of : 2)
              )
              .map((item, key) => {
                return (
                  <li
                    className={`page-item  ${page.of === item ? "active" : ""}`}
                    key={key}
                  >
                    <a
                      className={`page-link`}
                      role="button"
                      onClick={() => onPage({ ...page, of: item })}
                    >
                      {item}
                    </a>
                  </li>
                );
              })}
            <li
              className={`page-item ${page.of >= totalPage ? "disabled" : ""}`}
            >
              <a
                className="page-link"
                role="button"
                onClick={() => {
                  if (page.of >= totalPage) {
                    return null;
                  } else {
                    return onPageNext();
                  }
                }}
              >
                &gt;
              </a>
            </li>
            {page.of != totalPage ? (
              <li className={`page-item`}>
                <a
                  className="page-link"
                  role="button"
                  onClick={() => {
                    return onPage({ ...page, of: totalPage });
                  }}
                >
                  &raquo;
                </a>
              </li>
            ) : null}
          </ul>
        </nav>
      ) : null}
    </div>
  );
};

export default TableV2;
