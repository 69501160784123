import axios from "axios";
import * as API_LINKS from "../../config/links.jsx";
// import {toast} from 'react-toastify'

export const FETCH_UNPLANNED_STOP_LOCK = "FETCH_UNPLANNED_STOP_LOCK";
export const RECEIVE_UNPLANNED_STOP_LOCK = "RECEIVE_UNPLANNED_STOP_LOCK";
export const FAILED_UNPLANNED_STOP_LOCK = "FAILED_UNPLANNED_STOP_LOCK";

export function fetchUnplannedStopLocked(id_delivery_order) {
  var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
  var auth = loginCreds.token;

  return (dispatch) => {
    dispatch({ type: FETCH_UNPLANNED_STOP_LOCK });
    axios({
      method: "post",
      url: API_LINKS.UNPLANNED_STOP_LOCK_LIST,
      headers: {
        Authorization: auth,
      },
      data: {
        id_delivery_order,
      },
    })
      .then((response) => {
        if (response.data.result.length === 0) {
          dispatch({
            type: RECEIVE_UNPLANNED_STOP_LOCK,
            payload: response.data,
          });
        }
        const zeroPad = (num, places) => String(num).padStart(places, "0");
        const { data } = response;
        const resultLength = data.result.length;
        const lastTripNumber = data.result[resultLength - 1].trip_number;
        const copyData = { ...data };
        data.result = [];
        for (let i = 0; i <= lastTripNumber; i++) {
          const filterByTripNumber = copyData.result.filter(
            (cd) => cd.trip_number === i
          );
          if (filterByTripNumber.length === 0) {
            continue;
          }
          const nulled_place = [];
          const saved_place_near = [];
          const public_place_near = [];
          filterByTripNumber.forEach((d, index) => {
            if (!d.place_catagory) {
              nulled_place.push({
                id: d.id,
                id_saved_place: d.id_saved_place,
                name: d.name_saved_place,
                lat: d.saved_place_lat,
                lng: d.saved_place_lng,
                distance: d.distance,
                approval: d.approval,
                notes: d.notes,
                intented_stop: filterByTripNumber[index].intended_stop,
                id_work_order: filterByTripNumber[index].id_work_order,
                place_catagory: d.place_catagory,
              });
            }
            if (d.place_catagory === 1) {
              saved_place_near.push({
                id: d.id,
                id_saved_place: d.id_saved_place,
                name: d.name_saved_place,
                lat: d.saved_place_lat,
                lng: d.saved_place_lng,
                distance: d.distance,
                approval: d.approval,
                notes: d.notes,
                intented_stop: filterByTripNumber[index].intended_stop,
                id_work_order: filterByTripNumber[index].id_work_order,
                place_catagory: d.place_catagory,
              });
            }
            if (d.place_catagory === 2) {
              public_place_near.push({
                id: d.id,
                id_saved_place: d.id_saved_place,
                name: d.name_saved_place,
                lat: d.saved_place_lat,
                lng: d.saved_place_lng,
                distance: d.distance,
                approval: d.approval,
                notes: d.notes,
                intented_stop: filterByTripNumber[index].intended_stop,
                id_work_order: filterByTripNumber[index].id_work_order,
                place_catagory: d.place_catagory,
              });
            }
          });
          data.result.push({
            id: i + 1,
            _id: id_delivery_order,
            trip_number: zeroPad(filterByTripNumber[0].trip_number, 3),
            lat: filterByTripNumber[0].average_stop_lat,
            lng: filterByTripNumber[0].average_stop_lon,
            start_date: filterByTripNumber[0].start_date,
            saved_place_near: saved_place_near,
            public_place_near: public_place_near,
            nulled_place: nulled_place,
            end_date: filterByTripNumber[0].end_date,
            stop_duration: (filterByTripNumber[0].duration / 60).toFixed(2),
            address: null,
            intented_stop: filterByTripNumber[0].intended_stop,
          });
        }

        if (response.data.result.length > 0) {
          Promise.all(
            response.data.result.map((item) => {
              return axios({
                method: "post",
                url: API_LINKS.GOOGLE_GEOCODING_v2,
                headers: {
                  Authorization: auth,
                  "Content-Type": "application/json",
                },
                data: {
                  lat: item.lat,
                  lng: item.lng,
                },
              });
            })
          )
            .then((dtRes) => {
              response.data.result = response.data.result.map((item, i) => {
                if (dtRes[i]) {
                  return {
                    ...item,
                    address: dtRes[i].data.result.display_name,
                  };
                }
                return { ...item };
              });

              dispatch({
                type: RECEIVE_UNPLANNED_STOP_LOCK,
                payload: response.data,
              });
            })
            .catch(() => {
              dispatch({
                type: RECEIVE_UNPLANNED_STOP_LOCK,
                payload: response.data,
              });
            });
        } else {
          dispatch({
            type: RECEIVE_UNPLANNED_STOP_LOCK,
            payload: response.data,
          });
        }
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: FAILED_UNPLANNED_STOP_LOCK,
          });
        }
      });
  };
}
