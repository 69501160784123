import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { USER_NOTIFICATION_TOKEN } from "../redux/config/links";

const FB_CONFIG = {
  apiKey: "AIzaSyCIDnYdDyDFunhIR9gp-lyhmCOMzG5ikl4",
  authDomain: "automa-platform-webapp.firebaseapp.com",
  projectId: "automa-platform-webapp",
  storageBucket: "automa-platform-webapp.appspot.com",
  messagingSenderId: "311972577331",
  appId: "1:311972577331:web:393cefdad8fec875bde346",
  measurementId: "G-Q8N1L9G3WP",
};

const VAPIDKEY =
  "BGQ9oKDbWaRl_tBnZjDtBTWEfjVwwE-VJhzUfelTIOkMl7SS4nh6JCgu-wAJXmCjJi2RqWJPoJR1r2-K-odT9xg";

const initFB = () => {
  try {
    return initializeApp(FB_CONFIG, "AutomaPlatform");
  } catch (err) {
    return null;
  }
};

const initMessage = () => {
  const init = initFB();
  if (!!init) {
    try {
      return getMessaging(init);
    } catch (err) {
      return null;
    }
  }
  return null;
};

const messaging = initMessage();

export const requestForToken = ({ onRequest, onError }) => {
  if(!!messaging){
    return Notification.requestPermission((payload) => {
      if (payload === "default") {
        onRequest();
      }
      if (payload === "granted") {
        getToken(messaging, {
          vapidKey: VAPIDKEY,
        })
          .then((currentToken) => {
            if (currentToken) {
              const loginCreds = JSON.parse(window.atob(localStorage.Automalt));
              const auth = loginCreds.token;

              axios({
                url: USER_NOTIFICATION_TOKEN,
                method: "POST",
                headers: { Authorization: auth },
                data: {
                  token: currentToken,
                  type: 1,
                },
              });

              // console.log("current token for client: ", currentToken);
              // Perform any other neccessary action with the token
            } else {
              // Show permission request UI
              console.log(
                "No registration token available. Request permission to generate one."
              );
            }
          })
          .catch(() => {
            // window.location.reload();
            onError();
          });
      }
    });
  }
  else{
    return onError();
  }
};

export const onMessageListener = () =>
  new Promise((resolve, reject) => {
    if(!!messaging){
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    }
    else{
      reject("Error messaging null")
    }
  });
