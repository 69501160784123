import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row, Input, Label, Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";
import styles from "./DriversDetails.module.scss";
import { FaCog } from "react-icons/fa";
import * as API_LINKS from "../../../redux/config/links";
import useLazyFetch from "../../../hooks/useLazyFetch";
import CustomToast from "../../../components/CustomToast/CustomToast";
import { handleRequestError } from "../../../helpers/helper";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import { BarLoader } from "react-spinners";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

const propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  ktp: PropTypes.string,
  link_image: PropTypes.string,
  telp: PropTypes.string,
  birth_date: PropTypes.string,
  lic_type: PropTypes.string,
  lic_number: PropTypes.string,
  exp_date: PropTypes.string,
  email: PropTypes.string,
  // New PropTypes
  training_status: PropTypes.string,
  training_date: PropTypes.string,
  certification_expiry_date: PropTypes.string,
  certificate_number: PropTypes.string,
  organizer: PropTypes.string,
  next_training_schedule: PropTypes.string,
};

const defaultProps = {
  id: 0,
  name: "",
  ktp: "",
  link_image: "",
  telp: "",
  birth_date: "",
  lic_type: "",
  lic_number: "",
  exp_date: "",
  email: "",
  // New DefaultProps
  training_status: "",
  training_date: "",
  certification_expiry_date: "",
  certificate_number: "",
  organizer: "",
  next_training_schedule: "",
};

const DriversDetails = ({
  id,
  name,
  ktp,
  link_image,
  telp,
  birth_date,
  lic_type,
  lic_number,
  exp_date,
  email,
  // Parameter Baru
  training_status,
  training_date,
  certification_expiry_date,
  certificate_number,
  organizer,
  next_training_schedule,
}) => {
  const [isModalFilterOpen, setIsModalFilterOpen] = useState(false);
  const [oneTime, setOneTime] = useState(true);
  const [isDriverFiltering, setIsDriverFiltering] = useState(false);
  const [driverDataFiltered, setDriverDataFiltered] = useState(null);
  const [driverDataWo, setDriverDataWo] = useState(null);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [filterStatus, setFilterStatus] = useState("All Time");
  const [totable, setToTable] = useState(false);

  const [fetchDriverListV2] = useLazyFetch({
    url: API_LINKS.DRIVER_LIST_URL_V2,
    method: "post",
    type: "json",
  });

  const handleApplyFilter = () => {
    setIsDriverFiltering(true);
    const startTimeUTC = moment(startDate).startOf("day").utc().format();
    const endTimeUTC = moment(endDate).endOf("day").utc().format();

    fetchDriverListV2(
      {
        body: {
          id: id,
          start_time: startTimeUTC,
          end_time: endTimeUTC,
        },
      },
      (response, err) => {
        if (!!response) {
          const result = response.data.result;
          const woResult = response.data.wo;
          if (result.length === 0 && woResult.length === 0) {
            toast.warning(
              <CustomToast
                text={"Data is empty"}
                icon={<RiErrorWarningFill size={24} />}
              />,
              {
                className: styles["custom-toast-warning"],
              }
            );
            setDriverDataFiltered(null);
            setDriverDataWo(null);
            setFilterStatus("All Time");
          } else {
            setDriverDataFiltered(result[0]); // Gunakan item pertama dalam array
            setDriverDataWo(woResult);
            setFilterStatus(
              `${moment(startDate).format("YYYY-MM-DD")} to ${moment(
                endDate
              ).format("YYYY-MM-DD")}`
            );
          }
          setIsDriverFiltering(false);
          setIsModalFilterOpen(false);
        } else {
          setDriverDataFiltered(null);
          setDriverDataWo(null);
          setIsDriverFiltering(false);
          setIsModalFilterOpen(false);
          handleRequestError(err);
        }
      }
    );
  };

  const handleDataAllTime = () => {
    setStartDate(moment().format("YYYY-MM-DD")); // Reset startDate
    setEndDate(moment().format("YYYY-MM-DD")); // Reset endDate
    setIsDriverFiltering(true);
    fetchDriverListV2(
      {
        body: {
          id: id,
        },
      },
      (response, err) => {
        if (!!response) {
          const result = response.data.result;
          const woResult = response.data.wo;
          if (result.length === 0 && woResult.length === 0) {
            toast.warning(
              <CustomToast
                text={"Data is empty"}
                icon={<RiErrorWarningFill size={24} />}
              />,
              {
                className: styles["custom-toast-warning"],
              }
            );
            setDriverDataFiltered(null);
            setDriverDataWo(null);
            setFilterStatus("All Time");
          } else {
            setDriverDataFiltered(result[0]); // Gunakan item pertama dalam array
            setDriverDataWo(woResult);
            setFilterStatus("All Time");
          }
          setIsDriverFiltering(false);
          setIsModalFilterOpen(false);
        } else {
          setDriverDataFiltered(null);
          setDriverDataWo(null);
          setIsDriverFiltering(false);
          setIsModalFilterOpen(false);
          handleRequestError(err);
        }
      }
    );
  };

  useEffect(() => {
    // Memuat data default saat komponen pertama kali dirender
    if (oneTime) {
      setOneTime(false);
      fetchDriverListV2(
        {
          body: {
            id: id,
          },
        },
        (response, err) => {
          if (!!response) {
            const result = response.data.result;
            const woResult = response.data.wo;
            if (result.length === 0 && woResult.length === 0) {
              toast.warning(
                <CustomToast
                  text={"Data is empty"}
                  icon={<RiErrorWarningFill size={24} />}
                />,
                {
                  className: styles["custom-toast-warning"],
                }
              );
              setDriverDataFiltered(null);
              setDriverDataWo(null);
            } else {
              setDriverDataFiltered(result[0]); // Gunakan item pertama dalam array
              setDriverDataWo(woResult);

            }
          } else {
            setDriverDataFiltered(null);
            setDriverDataWo(null);
            handleRequestError(err);
          }
        }
      );
    }
  }, [fetchDriverListV2, id, oneTime]);

  const getStatusColor = (type) => {
    switch (type) {
      case "normal":
        return "#76DB77"; // Green for Normal Time
      case "under":
        return "#fff000"; // Yellow for Undertime
      case "over":
        return "#FFA500"; // Orange for Overtime
      case "incomplete":
        return "#d24d57"; // Red for Incomplete
      case "nodetail":
        return "#8B8B8B"; // Grey for No Detail/Incomplete
      default:
        return "#000"; // Default to black
    }
  };

  const handleCloseFilterModal = () => {
    setIsModalFilterOpen(!isModalFilterOpen);
  };

  const svgWithText = (cell, row, formatExtraData) => {
    const { statusColor, label } = formatExtraData;
    return (
      <div className="d-flex align-items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          className="me-2"
        >
          <circle cx="7" cy="7" r="7" fill={statusColor} />
        </svg>
        {`${cell || 0} ${label}`}
      </div>
    );
  };

  const defaultData = [
    {
      date: "N/A",
      total_wo: "-",
      total_do: "-",
      normal: "-",
      undertime: "-",
      overtime: "-",
      incomplete_orders: "-",
      speedAVG: "-",
    },
  ];

  return (
    <>
      <Card style={{ width: "-webkit-fill-available" }}>
        <CardBody>
          <Row>
            {link_image && (
              <Col md="12" className="text-center mb-3">
                <img
                  src={link_image}
                  alt="Driver"
                  className="img-fluid rounded shadow"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "300px",
                    objectFit: "cover",
                    border: "1px solid #ddd",
                    padding: "10px",
                    marginBottom: "20px",
                  }}
                />
              </Col>
            )}
            <Col md="12">
              <h5 className="mb-3">Driver Basic Information</h5>
              <dl>
                <dt>Driver Name</dt>
                <dd>{name ? name : "-"}</dd>
                <dt>Identification Card Number</dt>
                <dd>{ktp ? ktp : "-"}</dd>
                <dt>Phone Number</dt>
                <dd>{telp ? telp : "-"}</dd>
                <dt>Email</dt>
                <dd>{email ? email : "-"}</dd>
                <dt>Birth Date</dt>
                <dd>
                  {birth_date ? moment(birth_date).format("YYYY-MM-DD") : "-"}
                </dd>
                <dt>License Type</dt>
                <dd>{lic_type ? lic_type : "-"} </dd>
                <dt>License Number</dt>
                <dd>{lic_number ? lic_number : "-"} </dd>
                <dt>Expired Date</dt>
                <dd>
                  {exp_date ? moment(exp_date).format("YYYY-MM-DD") : "-"}
                </dd>
              </dl>{" "}
              <br />
              <h5 className="mb-3">Training & Certification</h5>
              <dl>
                <dt>Training Status</dt>
                <dd>{training_status ? training_status : "-"}</dd>
                <dt>Training Date</dt>
                <dd>
                  {training_date
                    ? moment(training_date).format("YYYY-MM-DD")
                    : "-"}
                </dd>
                <dt>Certification Expiry Date</dt>
                <dd>
                  {certification_expiry_date
                    ? moment(certification_expiry_date).format("YYYY-MM-DD")
                    : "-"}
                </dd>
                <dt>Certificate Number</dt>
                <dd>{certificate_number ? certificate_number : "-"}</dd>
                <dt>Organizer</dt>
                <dd>{organizer ? organizer : "-"}</dd>
                <dt>Next Training Schedule Date</dt>
                <dd>
                  {next_training_schedule
                    ? moment(next_training_schedule).format("YYYY-MM-DD")
                    : "-"}
                </dd>
              </dl>{" "}
              <br />
              <h5 className="mb-3">Task Details</h5>
              <dl>
                <div>
                  <em>
                    <span>
                      ** Choose a date range by clicking the gear icon. **
                    </span>
                  </em>
                  <div className={styles["wo-do-details-wrapper"]}>
                    <dt>WO/DO Details</dt>
                    <span className={styles["filter-status"]}>
                      ({filterStatus})
                    </span>
                    <div
                      className={styles["filter-button"]}
                      onClick={() => setIsModalFilterOpen(!isModalFilterOpen)}
                      style={{ cursor: "pointer" }}
                    >
                      <FaCog />
                    </div>
                  </div>
                  {driverDataFiltered && (
                    <>
                      {isDriverFiltering ? (
                        <div className="w-100 text-center">
                          <BarLoader
                            color={"#123abc"}
                            loading={isDriverFiltering}
                          />
                        </div>
                      ) : (
                        <div>
                          <ul>
                            <li>
                              <span>Driver Timeliness: </span><strong className={styles['switch']} onClick={() => setToTable(!totable)}>{totable ? "(view log)" : "(view summary)"}</strong>
                              <>{totable ? <>
                                {driverDataWo && driverDataWo.length > 0 ? (
                                  <BootstrapTable
                                    data={driverDataWo}
                                    bordered={false}
                                    hover
                                    pagination
                                  >
                                    <TableHeaderColumn
                                      dataField="date"
                                      isKey
                                      dataSort
                                      csvHeader="Date"
                                      thStyle={{ whiteSpace: "normal" }}
                                      tdStyle={{ whiteSpace: "normal" }}
                                    >
                                      Date
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="total_wo"
                                      dataSort
                                      csvHeader="Total WO"
                                      thStyle={{ whiteSpace: "normal" }}
                                      tdStyle={{ whiteSpace: "normal" }}
                                      width="8%"
                                    >
                                      Total WO
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="total_do"
                                      dataSort
                                      csvHeader="Total DO"
                                      thStyle={{ whiteSpace: "normal" }}
                                      tdStyle={{ whiteSpace: "normal" }}
                                      width="8%"
                                    >
                                      Total DO
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="normal"
                                      dataSort
                                      csvHeader="Checked In"
                                      thStyle={{ whiteSpace: "normal" }}
                                      tdStyle={{ whiteSpace: "normal" }}
                                      formatExtraData={{
                                        statusColor: getStatusColor("normal"),
                                        label: "orders",
                                      }}
                                      dataFormat={svgWithText}
                                    >
                                      Checked In
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="undertime"
                                      dataSort
                                      csvHeader="Undertime"
                                      thStyle={{ whiteSpace: "normal" }}
                                      tdStyle={{ whiteSpace: "normal" }}
                                      formatExtraData={{
                                        statusColor: getStatusColor("under"),
                                        label: "orders",
                                      }}
                                      dataFormat={svgWithText}
                                    >
                                      Undertime
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="overtime"
                                      dataSort
                                      csvHeader="Overtime"
                                      thStyle={{ whiteSpace: "normal" }}
                                      tdStyle={{ whiteSpace: "normal" }}
                                      formatExtraData={{
                                        statusColor: getStatusColor("over"),
                                        label: "orders",
                                      }}
                                      dataFormat={svgWithText}
                                    >
                                      Overtime
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="incomplete_orders"
                                      dataSort
                                      csvHeader="No Checked In"
                                      thStyle={{ whiteSpace: "normal" }}
                                      tdStyle={{ whiteSpace: "normal" }}
                                      formatExtraData={{
                                        statusColor: getStatusColor("incomplete"),
                                        label: "orders",
                                      }}
                                      dataFormat={svgWithText}
                                    >
                                      No Checked In
                                    </TableHeaderColumn>
                                  </BootstrapTable>
                                ) : (
                                  <BootstrapTable
                                    data={defaultData}
                                    bordered={false}
                                    hover
                                    pagination
                                  >
                                    <TableHeaderColumn
                                      dataField="date"
                                      isKey
                                      dataSort
                                      csvHeader="Date"
                                      thStyle={{ whiteSpace: "normal" }}
                                      tdStyle={{ whiteSpace: "normal" }}
                                    >
                                      Date
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="total_wo"
                                      dataSort
                                      csvHeader="Total WO"
                                      thStyle={{ whiteSpace: "normal" }}
                                      tdStyle={{ whiteSpace: "normal" }}
                                      width="8%"
                                    >
                                      Total WO
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="total_do"
                                      dataSort
                                      csvHeader="Total DO"
                                      thStyle={{ whiteSpace: "normal" }}
                                      tdStyle={{ whiteSpace: "normal" }}
                                      width="8%"
                                    >
                                      Total DO
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="normal"
                                      dataSort
                                      csvHeader="Checked In"
                                      thStyle={{ whiteSpace: "normal" }}
                                      tdStyle={{ whiteSpace: "normal" }}
                                    >
                                      Checked In
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="undertime"
                                      dataSort
                                      csvHeader="Undertime"
                                      thStyle={{ whiteSpace: "normal" }}
                                      tdStyle={{ whiteSpace: "normal" }}
                                    >
                                      Undertime
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="overtime"
                                      dataSort
                                      csvHeader="Overtime"
                                      thStyle={{ whiteSpace: "normal" }}
                                      tdStyle={{ whiteSpace: "normal" }}
                                    >
                                      Overtime
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="incomplete_orders"
                                      dataSort
                                      csvHeader="No Checked In"
                                      thStyle={{ whiteSpace: "normal" }}
                                      tdStyle={{ whiteSpace: "normal" }}
                                    >
                                      No Checked In
                                    </TableHeaderColumn>
                                  </BootstrapTable>
                                )}</> : <>
                                <dd>
                                  <ul>
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                      >
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="7"
                                          fill={getStatusColor("normal")}
                                        />
                                      </svg>
                                      {` Checked In: ${driverDataWo && driverDataWo.length > 0 ?
                                        (([0, 0, 0, ...driverDataWo.map((item) => item.normal)].reduce((i, a) => i + a, 0) / [0, 0, 0, ...driverDataWo.map((item) => item.total_wo)].reduce((i, a) => i + a, 0)) * 100).toFixed(2)
                                        : 0
                                        }% (${driverDataWo && driverDataWo.length > 0 ?
                                          [0, 0, 0, ...driverDataWo.map((item) => item.normal)].reduce((i, a) => i + a, 0)
                                          : 0
                                        } orders)`}
                                    </li>
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                      >
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="7"
                                          fill={getStatusColor("under")}
                                        />
                                      </svg>
                                      {` Undertime: ${driverDataWo && driverDataWo.length > 0 ?
                                        (([0, 0, 0, ...driverDataWo.map((item) => item.undertime)].reduce((i, a) => i + a, 0) / [0, 0, 0, ...driverDataWo.map((item) => item.total_wo)].reduce((i, a) => i + a, 0)) * 100).toFixed(2)
                                        : 0
                                        }% (${driverDataWo && driverDataWo.length > 0 ?
                                          [0, 0, 0, ...driverDataWo.map((item) => item.undertime)].reduce((i, a) => i + a, 0)
                                          : 0
                                        } orders)`}
                                    </li>
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                      >
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="7"
                                          fill={getStatusColor("over")}
                                        />
                                      </svg>
                                      {` Overtime: ${driverDataWo && driverDataWo.length > 0 ?
                                        (([0, 0, 0, ...driverDataWo.map((item) => item.overtime)].reduce((i, a) => i + a, 0) / [0, 0, 0, ...driverDataWo.map((item) => item.total_wo)].reduce((i, a) => i + a, 0)) * 100).toFixed(2)
                                        : 0
                                        }% (${driverDataWo && driverDataWo.length > 0 ?
                                          [0, 0, 0, ...driverDataWo.map((item) => item.overtime)].reduce((i, a) => i + a, 0)
                                          : 0
                                        } orders)`}
                                    </li>
                                    <li>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                      >
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="7"
                                          fill={getStatusColor("incomplete")}
                                        />
                                      </svg>
                                      {` No Checked In: ${driverDataWo && driverDataWo.length > 0 ?
                                        (([0, 0, 0, ...driverDataWo.map((item) => item.incomplete_orders)].reduce((i, a) => i + a, 0) / [0, 0, 0, ...driverDataWo.map((item) => item.total_wo)].reduce((i, a) => i + a, 0)) * 100).toFixed(2)
                                        : 0
                                        }% (${driverDataWo && driverDataWo.length > 0 ?
                                          [0, 0, 0, ...driverDataWo.map((item) => item.incomplete_orders)].reduce((i, a) => i + a, 0)
                                          : 0
                                        } orders)`}
                                    </li>
                                  </ul>
                                </dd>
                              </>}
                              </>
                            </li>
                          </ul>
                          <dt>Checklist Logs</dt>
                          <ul>
                            <li>
                              {`Completed Checklist: ${driverDataFiltered.check_sheet_completed || 0
                                } completed`}
                            </li>
                            <li>
                              {`Missing Checklist: ${driverDataFiltered.check_sheet_missing || 0
                                } missing`}
                            </li>
                          </ul>
                          <dt>Others</dt>
                          <ul>
                            <li>
                              {` Departure Orders: (${driverDataFiltered.departure_orders || 0
                                } orders)`}
                            </li>
                            <li>
                              {` Return Home Orders: (${driverDataFiltered.return_home_orders || 0
                                } orders)`}
                            </li>
                            <li>
                              {` No Arrival Time Orders: (${driverDataFiltered.no_arrival_time_orders || 0
                                } orders)`}
                            </li>
                            <li>
                              {` No Checkout Time Orders: (${driverDataFiltered.no_checkout_time_orders || 0
                                } orders)`}
                            </li>
                          </ul>
                          <dt>Proof of Delivery</dt>
                          <dd>
                            {driverDataFiltered.verified_work_orders
                              ? `${driverDataFiltered.verified_work_orders} out of ${driverDataFiltered.total_work_orders}`
                              : "-"}
                          </dd>
                          <dt>Driver Average Speed</dt>
                          <dd>
                            {driverDataWo && driverDataWo.length > 0
                              ? (() => {
                                const allSpeedAVG = driverDataWo
                                  .filter((item) => item.speedAVG !== null && item.date != "Incomplete") // Ambil data speedAVG yang valid
                                  .map((item) => item.speedAVG); // Ambil nilai speedAVG saja

                                if (allSpeedAVG.length === 0) return "-"; // Jika tidak ada data valid

                                // Total dari semua speedAVG
                                const totalSpeed = allSpeedAVG.reduce(
                                  (sum, speed) => sum + speed,
                                  0
                                );

                                {/* console.log('Filtered driverDataWo:', driverDataWo);
                                console.log('Valid speedAVG values:', allSpeedAVG);
                                console.log('Total Speed:', totalSpeed); */}

                                return isNaN(totalSpeed) ? "-" : totalSpeed.toFixed(3); // Tangani NaN dan format ke 2 desimal
                              })()
                              : "-"}
                          </dd>
                          <dt>Driver Warning</dt>
                          <dd>
                            {driverDataFiltered.driver_warning ? `${driverDataFiltered.driver_warning} times` : "-"}
                          </dd>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </dl>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Modal
        isOpen={isModalFilterOpen}
        toggle={handleCloseFilterModal}
        size="md"
        centered
      >
        <ModalHeader toggle={handleCloseFilterModal}>
          <strong>Select Range Date</strong>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex align-items-center">
            <div className="me-2 flex-fill">
              <Label htmlFor="startDate" style={{ fontWeight: "500" }}>
                Start Date
              </Label>
              <Input
                id="startDate"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                placeholder="Start Date"
                style={{ width: "100%" }}
                disabled={isDriverFiltering}
              />
            </div>
            <div className="me-2 flex-fill">
              <Label htmlFor="endDate" style={{ fontWeight: "500" }}>
                End Date
              </Label>
              <Input
                id="endDate"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                placeholder="End Date"
                style={{ width: "100%" }}
                disabled={isDriverFiltering}
              />
            </div>
            <div className={styles["button-container"]}>
              <Button
                onClick={handleApplyFilter}
                style={{ marginTop: "25px" }}
                disabled={isDriverFiltering}
              >
                Apply
              </Button>
              <span className={styles["or-text"]}>or</span>
              <Button
                onClick={handleDataAllTime}
                style={{ marginTop: "25px" }}
                disabled={isDriverFiltering}
              >
                All Time
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

DriversDetails.propTypes = propTypes;
DriversDetails.defaultProps = defaultProps;

export default DriversDetails;
