import React from "react";
import { Button, Col, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import GeofencingMap from "./GeofencingMap";
import useForm from "src/hooks/useForm";
import useLazyFetch from "src/hooks/useLazyFetch";
import {
  SAVED_PLACES_ADD_POLYGON,
  SAVED_PLACES_ADD_URL,
  SAVED_PLACES_DELETE_POLYGON,
  SAVED_PLACES_EDIT_URL,
} from "src/redux/config/links";
import { toast } from "react-toastify";
import CustomToast from "src/components/CustomToast/CustomToast";
import { BiSolidError } from "react-icons/bi";
import { FaCheckCircle } from "react-icons/fa";
import * as Yup from "yup";
import { onFetchArrayAsync } from "src/helpers/helper";
import { useSelector } from "react-redux";
import Select from "react-select";

const GeofencingAddform = ({ onClose, onReload, initialValues = null }) => {
  function calculateNewPosition(latitude, longitude, distance, bearing) {
    const earthRadius = 6371e3;
    const lat1 = latitude * (Math.PI / 180);
    const lon1 = longitude * (Math.PI / 180);
    const bearingRad = bearing * (Math.PI / 180);
    const distanceRad = distance / earthRadius; 
    const lat2 = Math.asin(
        Math.sin(lat1) * Math.cos(distanceRad) +
        Math.cos(lat1) * Math.sin(distanceRad) * Math.cos(bearingRad)
    );
    const lon2 = lon1 + Math.atan2(
        Math.sin(bearingRad) * Math.sin(distanceRad) * Math.cos(lat1),
        Math.cos(distanceRad) - Math.sin(lat1) * Math.sin(lat2)
    );

    const newLatitude = lat2 * (180 / Math.PI);
    const newLongitude = lon2 * (180 / Math.PI);

    return { lat:newLatitude, lng: newLongitude };
  }

  const removeDuplicatePolygon = (arr) => {
    return arr.filter((item, index, self) => {
      return (
        self.findIndex((sub) => item.lat == sub.lat && item.lon == sub.lon) ===
        index
      );
    });
  };

  // remove duplicate lat lon
  const savedPolygonsAltered =
    (initialValues?.type_map === 2
      ? removeDuplicatePolygon(initialValues?.savedPolygons)
      : initialValues?.savedPolygons) ||
    (initialValues?.type_map === 2 ? [{ lat: "", lon: "" }] : []);

  const initialFormValues = {
    id: initialValues?.id || "",
    name_id: initialValues?.name_id || "",
    type_map: initialValues?.type_map || 1,
    savedPolygons: savedPolygonsAltered || [],
    lat: initialValues?.lat || 0,
    lng: initialValues?.lng || 0,
    radius:
      (isNaN(initialValues?.radius) ? 0 : Number(initialValues?.radius)) || 0,
  };

  const validationSchema = () =>
    Yup.object().shape({
      name_id: Yup.string().required("Name ID is required!"),
      type_map: Yup.number(),
      savedPolygons: Yup.array()
        .of(
          Yup.object().shape({
            lat: Yup.number().required("Latitude is required"),
            lon: Yup.number().required("Longitude is required"),
          })
        )
        // jika type_map = 2 (polygon)
        .when("type_map", {
          is: (plan) => {
            return plan === 2;
          },
          then: (schema) => {
            return schema
              .min(4, "A polygon must have at least 4 coordinates")
              .required("At least 4 coordinates are required for polygon");
          },
          otherwise: (schema) => schema.notRequired().optional(),
        }),
      lat: Yup.number().when("type_map", {
        // jika type_map = 1 (circle)
        is: (plan) => {
          return plan === 1;
        },
        then: (schema) => {
          return schema
            .required("Latitude is required")
            .typeError("Latitude must be a number");
        },
        otherwise: (schema) => schema.notRequired().optional(),
      }),
      lng: Yup.number().when("type_map", {
        // jika type_map = 1 (circle)
        is: (plan) => {
          return plan === 1;
        },
        then: (schema) => {
          return schema
            .required("Longitude is required")
            .typeError("Longitude must be a number");
        },
        otherwise: (schema) => schema.notRequired().optional(),
      }),
      radius: Yup.number().when("type_map", {
        // jika type_map = 1 (circle)
        is: (plan) => {
          return plan === 1;
        },
        then: (schema) => {
          return schema
            .required("Radius is required")
            .positive("Radius must be a positive number")
            .typeError("Radius must be a number");
        },
        otherwise: (schema) => schema.notRequired().optional(),
      }),
  });

  const {
    handleSubmit,
    errors,
    values,
    setFieldValue,
    isSubmitting,
    isValid,
  } = useForm({
    initialValues: initialFormValues,
    validation: validationSchema(),
  });

  const { authStore } = useSelector((state) => ({
    authStore: state.authStore,
  }));
  
  const [inputGeofencing] = useLazyFetch({
    url: SAVED_PLACES_ADD_URL,
    method: "POST",
    type: "json",
  });

  const [editGeofencing] = useLazyFetch({
    url: SAVED_PLACES_EDIT_URL,
    method: "POST",
    type: "json",
  });

  const handleTypeChange = (event) => {
    const newType = Number(event.value);
    setFieldValue("type_map", newType);

    if (newType === 1) {
      // Jika tipe Circle (1)
      if (initialValues) {
        // Jika dalam mode edit, gunakan data dari initialValues
        setFieldValue(
          "radius",
          (isNaN(initialValues.radius) ? 0 : Number(initialValues.radius)) ||
            ""
        );
        setFieldValue("lat", initialValues.lat || "");
        setFieldValue("lng", initialValues.lng || "");
        setFieldValue("savedPolygons", []);
      } else {
        // Jika dalam mode tambah, kosongkan semua form
        setFieldValue("radius", "");
        setFieldValue("lat", "");
        setFieldValue("lng", "");
        setFieldValue("savedPolygons", []);
      }
    } else if (newType === 2) {
      setFieldValue("radius", 0);
      // Jika tipe Polygon (2)
      if (initialValues?.savedPolygons?.length > 1) {
        // Jika dalam mode edit, gunakan data dari initialValues
        setFieldValue(
          "savedPolygons",
          initialValues.savedPolygons || [{ lat: null, lon: null }]
        );
      } else {
        // Jika dalam mode tambah, ambil lat lon nya dari saved place
        setFieldValue("savedPolygons", [
          {
            lat: initialValues.lat,
            lon: initialValues.lng,
          },
        ]);
      }
    }
  };

  const handleResetField = () => {
    if (initialValues) {
      const newType = initialValues.type_map || 1; // Ambil type_map dari initialValues
      setFieldValue("type_map", newType); // Atur value di form

      if (newType === 1) {
        // Jika tipe Circle (1)
        setFieldValue("radius", initialValues.radius || 0);
        setFieldValue("lat", initialValues.lat || 0);
        setFieldValue("lng", initialValues.lng || 0);
        setFieldValue("savedPolygons", []);
      } else if (newType === 2) {
        // Jika tipe Polygon (2)
        setFieldValue(
          "savedPolygons",
          initialValues.savedPolygons || [{ lat: 0, lon: 0 }]
        );
        setFieldValue("radius", 0);
      }
    } else {
      // Jika tidak ada initialValues, reset semua form ke default
      setFieldValue("type_map", 1);
      setFieldValue("radius", 0);
      setFieldValue("lat", 0);
      setFieldValue("lng", 0);
      setFieldValue("savedPolygons", []);
    }
  };

  const handleAddCoordinate = () => {
    const lastArr = !!values.savedPolygons[values.savedPolygons.length - 1]
      ? values.savedPolygons[values.savedPolygons.length - 1]
      : { seq: 0, lat: "", lon: "" };

    const newCoord = calculateNewPosition(Number(lastArr.lat), Number(lastArr.lon), 50, 135)
    setFieldValue("savedPolygons", [...values.savedPolygons, { ...lastArr, lat: newCoord.lat, lon: newCoord.lng}]);
  };

  const handleRemoveCoordinate = (index) => {
    setFieldValue(
      "savedPolygons",
      values.savedPolygons.filter((_, idx) => idx !== index)
    );
  };

  const handleCoordinateChange = (index, field, value, setFieldValue) => {
    const updatedCoordinates = [
      ...values.savedPolygons.map((item, idx) =>
        idx === index ? { ...item, [field]: value } : { ...item }
      ),
    ];

    setFieldValue("savedPolygons", updatedCoordinates);
  };

  const onSubmitAddEdit = (values, { setSubmitting }) => {
    // console.log(values);
    let dataValues = {};
    if (values.type_map === 1) {
      dataValues = {
        name_id: values.name_id,
        type_map: values.type_map,
        lat: values.lat,
        lng: values.lng,
        radius: values.radius,
      };
    } else if (values.type_map === 2) {
      dataValues = {
        name_id: values.name_id,
        type_map: values.type_map,
        savedPolygons: values.savedPolygons,
      };
    }

    if (!initialValues) {
      inputGeofencing(
        {
          body: dataValues,
        },
        (success) => {
          setSubmitting(false);
          if (success) {
            toast.success(
              <CustomToast text="Geofencing added!" icon={<FaCheckCircle />} />,
              {
                autoClose: 3000,
              }
            );
            onReload();
            onClose();
          } else {
            toast.error(
              <CustomToast
                text="Failed to add geofencing!"
                icon={<BiSolidError />}
              />,
              {
                autoClose: 3000,
              }
            );
          }
        }
      );
    } else {
      editGeofencing(
        {
          body: {
            ...dataValues,
            id: initialValues.id,
          },
        },
        (success) => {
          setSubmitting(false);
          if (success) {
            toast.success(
              <CustomToast
                text="Geofencing updated!"
                icon={<FaCheckCircle />}
              />,
              {
                autoClose: 3000,
              }
            );
            onReload();
            onClose();
          } else {
            toast.error(
              <CustomToast
                text="Failed to update geofencing!"
                icon={<BiSolidError />}
              />,
              {
                autoClose: 3000,
              }
            );
          }
        }
      );
    }
  };

  const onSubmit = (values, { setSubmitting }) => {
    if (values.type_map === 2) {
      // Periksa apakah koordinat diubah
      const isCoordinatesChanged = !(
        initialValues &&
        JSON.stringify(initialValues.savedPolygons) ===
          JSON.stringify(values.savedPolygons)
      );

      if (isCoordinatesChanged) {
        // hapus semua coordinate
        onFetchArrayAsync(authStore.authData.result.token)(
          initialValues.savedPolygons.map((item) => ({
            url: SAVED_PLACES_DELETE_POLYGON,
            method: "POST",
            data: {
              id: item.id,
            },
            type: "json",
          })),
          {
            success: () => {
              // input ulang polygon lama dan polygon baru
              onFetchArrayAsync(authStore.authData.result.token)(
                [...removeDuplicatePolygon(values.savedPolygons), removeDuplicatePolygon(values.savedPolygons)[0]].map(
                  (item, idx) => ({
                    url: SAVED_PLACES_ADD_POLYGON,
                    method: "POST",
                    data: {
                      id_saved_place: initialValues?.id,
                      lat: item.lat,
                      lon: item.lon,
                      seq: idx + 1,
                    },
                    type: "json",
                  })
                ),
                {
                  success: () => {
                    onSubmitAddEdit(values, { setSubmitting });
                  },
                  error: (err) => {
                    setSubmitting(false);
                    const errorMessage =
                      err?.response?.data?.message ||
                      "Failed to submit coordinates!";
                    toast.error(
                      <CustomToast
                        text={errorMessage}
                        icon={<BiSolidError />}
                      />,
                      { autoClose: 3000 }
                    );
                  },
                }
              );
            },
            error: (err) => {
              setSubmitting(false);
              const errorMessage =
                err?.response?.data?.message || "Failed to submit coordinates!";
              toast.error(
                <CustomToast text={errorMessage} icon={<BiSolidError />} />,
                { autoClose: 3000 }
              );
            },
          }
        );
      } else {
        // Jika koordinat tidak diubah, hanya update name_id
        onSubmitAddEdit(values, { setSubmitting });
      }
    } else {
      onSubmitAddEdit(values, { setSubmitting });
    }
  };

  // useEffect(() => {
  //   if (isSetup) {
  //     setFieldValue(
  //       "savedPolygons",
  //       !!initialValues.savedPolygons ? initialValues.savedPolygons : []
  //     );
  //     setIsSetup(false);
  //   }
  // }, [initialValues, isSetup, setFieldValue]);

  return (
    <div className="d-flex">
      <GeofencingMap setFieldValue={setFieldValue} values={values} />
      <form onSubmit={handleSubmit(onSubmit)} className="ms-3">
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="name_id">Name ID</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              invalid={!!errors.name_id}
              type="text"
              name="name_id"
              id="name_id"
              placeholder="Name ID"
              autoComplete="off"
              valid={!errors.name_id}
              onChange={(e) => {
                const value = e.target.value;
                setFieldValue("name_id", value);
              }}
              value={values.name_id}
              disabled={isSubmitting}
            />
            <FormFeedback>{errors.name_id}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="type">Type</Label>
          </Col>
          <Col xs="12" md="9">
            <Select
              className={!!errors.type_map ? "is-invalid" : ""}
              value={values.type_map}
              onChange={handleTypeChange}
              options={[
                { value: 1, label: "Circle" },
                { value: 2, label: "Polygon" },
              ]}
              disabled={isSubmitting}
              placeholder={"Select Type"}
            />
          </Col>
        </FormGroup>

        {values.type_map === 1 && (
          <>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="radius">Radius</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  invalid={!!errors.radius}
                  type="number"
                  name="radius"
                  id="radius"
                  placeholder="Enter radius in meters"
                  autoComplete="off"
                  onChange={(e) => {
                    const value = e.target.value;
                    setFieldValue("radius", value);
                  }}
                  value={values.radius || ""}
                  disabled={isSubmitting}
                />
                <FormFeedback>{errors.radius}</FormFeedback>
              </Col>
            </FormGroup>

            <FormGroup row>
              {/* Latitude */}
              <Col md="6">
                <FormGroup row className="align-items-center">
                  <Col md="3">
                    <Label htmlFor="lat">Latitude</Label>
                  </Col>
                  <div>
                    <Input
                      type="number"
                      name="lat"
                      placeholder="Latitude"
                      autoComplete="off"
                      valid={!errors.lat}
                      step="0.0001"
                      readOnly={!!initialValues}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFieldValue("lat", value);
                      }}
                      value={values.lat || ""}
                      disabled={isSubmitting}
                    />
                    <FormFeedback>{errors.lat}</FormFeedback>
                  </div>
                </FormGroup>
              </Col>

              {/* Longitude */}
              <Col md="6">
                <FormGroup row className="align-items-center">
                  <Col md="3">
                    <Label htmlFor="lng">Longitude</Label>
                  </Col>
                  <div>
                    <Input
                      type="number"
                      name="lng"
                      placeholder="Longitude"
                      autoComplete="off"
                      valid={!errors.lng}
                      step="0.0001"
                      readOnly={!!initialValues}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFieldValue("lng", value);
                      }}
                      value={values.lng || ""}
                      disabled={isSubmitting}
                    />
                    <FormFeedback>{errors.lng}</FormFeedback>
                  </div>
                </FormGroup>
              </Col>
            </FormGroup>
          </>
        )}

        {values.type_map === 2 && (
          <>
            {values.savedPolygons.map((coord, index) => (
              <div key={index}>
                <FormGroup row>
                  <Col md="6">
                    <FormGroup row className="align-items-center">
                      <Col md="3">
                        <Label>Latitude</Label>
                      </Col>
                      <div>
                        <Input
                          type="number"
                          name={`savedPolygons[${index}].lat`}
                          placeholder={`Latitude ${index + 1}`}
                          autoComplete="off"
                          onChange={(e) =>
                            handleCoordinateChange(
                              index,
                              "lat",
                              e.target.value,
                              setFieldValue
                            )
                          }
                          value={coord.lat}
                          step="0.0001"
                          disabled={isSubmitting}
                        />
                        <FormFeedback>
                          {errors.savedPolygons &&
                            errors.savedPolygons[index]?.lat}
                        </FormFeedback>
                      </div>
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup row className="align-items-center">
                      <Col md="3">
                        <Label>Longitude</Label>
                      </Col>
                      <div className="d-flex">
                        <Input
                          type="number"
                          name={`savedPolygons[${index}].lon`}
                          placeholder={`Longitude ${index + 1}`}
                          autoComplete="off"
                          onChange={(e) =>
                            handleCoordinateChange(
                              index,
                              "lon",
                              e.target.value,
                              setFieldValue
                            )
                          }
                          value={coord.lon}
                          step="0.0001"
                          disabled={isSubmitting}
                        />
                        <FormFeedback>
                          {errors.savedPolygons &&
                            errors.savedPolygons[index]?.lon}
                        </FormFeedback>
                        {((initialValues && coord.id) || index > 0) && (
                          <Button
                            color="danger"
                            onClick={() => handleRemoveCoordinate(index)}
                            disabled={isSubmitting}
                            className="me-2 ms-2"
                          >
                            Remove
                          </Button>
                        )}
                      </div>
                    </FormGroup>
                  </Col>
                </FormGroup>
              </div>
            ))}
            <div className="d-flex justify-content-end mb-3">
              <Button
                color="primary"
                onClick={handleAddCoordinate}
                disabled={isSubmitting}
              >
                Add Coordinate
              </Button>
            </div>
          </>
        )}
        {!!errors.savedPolygons ? (
          <div style={{ color: "red" }}>{errors.savedPolygons}</div>
        ) : null}

        <FormGroup>
          <div className="d-flex w-100 justify-content-end">
            <Button
              type="reset"
              color="danger"
              className="mr-2"
              onClick={handleResetField}
              disabled={isSubmitting}
            >
              Reset
            </Button>
            <Button
              type="button"
              color="warning"
              className="mr-2"
              onClick={onClose}
              disabled={isSubmitting}
            >
              Close
            </Button>
            <Button
              type="submit"
              color="primary"
              className="mr-2 w-25"
              disabled={isSubmitting || !isValid}
            >
              {isSubmitting ? "Wait..." : initialValues ? "Update" : "Add"}
            </Button>
          </div>
        </FormGroup>
      </form>
    </div>
  );
};

export default GeofencingAddform;
